// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2lKF0{position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adfox": "_2lKF0"
};
module.exports = ___CSS_LOADER_EXPORT___;
