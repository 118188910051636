import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { ICON_BUTTON_SIZE_MAP } from './';
export default defineComponent({
    name: 'IconButton',
    props: {
        isActive: {
            type: Boolean,
        },
        isDisabled: {
            type: Boolean,
        },
        iconVisible: {
            default: true,
            type: Boolean,
        },
        name: {
            required: true,
            type: String,
        },
        size: {
            default: 'default',
            type: String,
            validator(value) {
                return ['default', 'big'].includes(value);
            },
        },
        tag: {
            default: 'button',
            type: String,
        },
        to: {
            default: null,
            type: [String, null],
        },
        theme: {
            default: 'dark',
            type: String,
            validator(value) {
                return [
                    'a11y',
                    'dark',
                    'light',
                    'transparent',
                    'semitransparent',
                    'dark-alternate',
                    'white',
                    'yellow',
                    'blue',
                    'blue-alternate',
                ].includes(value);
            },
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            ICON_BUTTON_SIZE_MAP,
            css,
        };
    },
});
