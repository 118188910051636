// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/ratings/rbe-footer-mobile.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/ratings/rbe-footer-tablet.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/images/ratings/rbe-footer-desktop.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WfNi7{position:relative;background-color:#f4f4f4}@media screen and (min-width:1024px){.WfNi7{background-color:#fff}}.asLCF{display:flex;min-height:250px;align-items:center;justify-content:center}@media screen and (min-width:768px){.asLCF [id^=adfox]{width:100%;max-width:1250px}}.P3NUl{width:100%;margin:0 auto}@media screen and (min-width:1024px){.P3NUl{max-width:1250px;padding:8px 8px 0}}.Ww1l6{position:sticky;z-index:10;top:0;right:0;left:0;margin-bottom:40px}@media screen and (min-width:768px){.Ww1l6{margin-bottom:64px}}@media screen and (min-width:1024px){.Ww1l6{top:8px;margin-bottom:88px}}.uQH4J{max-width:1000px;height:280px;margin:80px auto 0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0 100%;background-repeat:no-repeat}@media screen and (min-width:768px){.uQH4J{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}@media screen and (min-width:1024px){.uQH4J{margin:120px auto 0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}}.uQH4J span{display:block;margin-left:16px;font-size:18px;font-weight:400;line-height:24px}@media screen and (min-width:768px){.uQH4J span{margin-left:24px}}@media screen and (min-width:1024px){.uQH4J span{margin-left:0}}.kqEjk{position:fixed;top:0;left:0;display:flex;width:100%;height:100vh;height:calc(var(--vh, 1vh)*100);align-items:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "WfNi7",
	"adfox": "asLCF",
	"body": "P3NUl",
	"header": "Ww1l6",
	"footer": "uQH4J",
	"loader": "kqEjk"
};
module.exports = ___CSS_LOADER_EXPORT___;
