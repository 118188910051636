// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oDmAO{position:relative;display:inline-flex;align-items:center;cursor:pointer;transition:margin .3s,opacity .2s;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.oDmAO.udppY{margin-bottom:28px}.oDmAO:after{position:absolute;top:calc(100% + 4px);left:0;color:#f02d37;content:attr(data-error);font-size:13px;line-height:20px}.U2PNr{position:absolute;z-index:-1;opacity:0}.VU4zD{display:flex;align-items:center;justify-content:center;border:1px solid transparent;border-radius:4px;transition:background-color .2s,border-color .2s}.vTstI.oDmAO:active .VU4zD{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:hsla(0,0%,100%,.2)}@media(any-hover:hover){.vTstI.oDmAO:hover .VU4zD{background-color:hsla(0,0%,100%,.2)}}._2qiQ7.oDmAO:active .VU4zD{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#e2e2e2}@media(any-hover:hover){._2qiQ7.oDmAO:hover .VU4zD{background-color:#e2e2e2}}.Bm3bO .VU4zD{width:20px;height:20px}.gMHJy .VU4zD{width:24px;height:24px}.vTstI .VU4zD{background-color:hsla(0,0%,100%,.1)}._2qiQ7 .VU4zD{border-color:#e2e2e2;background-color:#f4f4f4}.oDmAO .U2PNr:checked~.VU4zD{border-color:#0082ff;background-color:#0082ff}.j\\+Wvj{color:#fff;opacity:0;transform:translate(-1px);transition:opacity .2s}.U2PNr:checked~.VU4zD .j\\+Wvj{opacity:1}.EEPJv{margin-left:12px;font-size:15px;transition:color .2s}.vTstI.oDmAO:active .EEPJv{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#fff}@media(any-hover:hover){.vTstI.oDmAO:hover .EEPJv{color:#fff}}.Bm3bO .EEPJv{line-height:22px}.gMHJy .EEPJv{line-height:24px}.vTstI .EEPJv{color:#989898}._2qiQ7 .EEPJv{color:#2d2d2d}.EEPJv.hTw5P{order:-1;margin-right:12px;margin-left:0}.vTstI .U2PNr:checked~.EEPJv{color:#fff}._2qiQ7 .U2PNr:checked~.EEPJv{color:#2d2d2d}.oDmAO.R7O5-{cursor:not-allowed;opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formCheckbox": "oDmAO",
	"hasError": "udppY",
	"input": "U2PNr",
	"fakeCheckbox": "VU4zD",
	"dark": "vTstI",
	"light": "_2qiQ7",
	"small": "Bm3bO",
	"big": "gMHJy",
	"check": "j+Wvj",
	"label": "EEPJv",
	"left": "hTw5P",
	"isDisabled": "R7O5-"
};
module.exports = ___CSS_LOADER_EXPORT___;
