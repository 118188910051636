// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H5m9B{position:relative;overflow:hidden;background-position:50%;background-repeat:no-repeat;background-size:cover}.H5m9B img{position:absolute;top:0;left:0;width:100%;height:100%;border-radius:inherit;color:transparent;-o-object-fit:cover;object-fit:cover;text-indent:-9999px;transition:opacity .3s}.H5m9B:after{display:block;padding-bottom:56.25%;padding-bottom:var(--aspect-ratio,56.25%);content:\"\"}@keyframes nZfu8{0%{opacity:0}to{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseImage": "H5m9B",
	"fade": "nZfu8"
};
module.exports = ___CSS_LOADER_EXPORT___;
