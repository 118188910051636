import ReconnectingWebSocket from 'reconnecting-websocket';
import { reactive, readonly } from '@vue/composition-api';
import { isLightHouse, parseJSON } from '~/utils';
const shouldIgnore = process.server || isLightHouse(navigator.userAgent);
export var WaterfallFetchStatus;
(function (WaterfallFetchStatus) {
    WaterfallFetchStatus["DONE"] = "DONE";
    WaterfallFetchStatus["ERROR"] = "ERROR";
    WaterfallFetchStatus["IDLE"] = "IDLE";
    WaterfallFetchStatus["PENDING"] = "PENDING";
})(WaterfallFetchStatus || (WaterfallFetchStatus = {}));
export var WaterfallStatus;
(function (WaterfallStatus) {
    WaterfallStatus["ERROR"] = "ERROR";
    WaterfallStatus["PENDING"] = "PENDING";
    WaterfallStatus["READY"] = "READY";
})(WaterfallStatus || (WaterfallStatus = {}));
export const WATERFALL_MODES = ['basic', 'education', 'sport'];
let ws;
/**
 * State.
 */
const state = reactive({
    items: [],
    mode: '',
    fetchStatus: WaterfallFetchStatus.PENDING,
    status: WaterfallStatus.PENDING,
});
/**
 * Handles "init" event.
 */
function onInit(message) {
    const { base, fixed } = message.data;
    state.items = base;
    for (const fixedEntry of fixed) {
        state.items.splice(fixedEntry.index, 0, fixedEntry);
    }
    state.fetchStatus = WaterfallFetchStatus.IDLE;
    state.status = WaterfallStatus.READY;
}
/**
 * Handles "more" event.
 */
function onMore(message) {
    const { data = [] } = message;
    if (!data.length) {
        state.fetchStatus = WaterfallFetchStatus.DONE;
        return;
    }
    state.items = [...state.items, ...data];
    state.fetchStatus = WaterfallFetchStatus.IDLE;
    state.status = WaterfallStatus.READY;
}
/**
 * Handles "refresh" event.
 */
function onRefresh(message) {
    const { base, fixed } = message.data;
    for (const addKey in base.add) {
        const addEntry = base.add[addKey];
        state.items.splice(addEntry.index, 0, addEntry.article);
    }
    for (const updateEntry of base.update) {
        const updateIndex = state.items.findIndex((item) => item.id === updateEntry.id);
        if (updateIndex === -1) {
            continue;
        }
        state.items.splice(updateIndex, 1, updateEntry);
    }
    for (const removeEntry of base.remove) {
        const removeIndex = state.items.findIndex((item) => item.id === removeEntry.id);
        if (removeIndex === -1) {
            continue;
        }
        state.items.splice(removeIndex, 1);
    }
    for (const fixedKey in fixed) {
        const fixedEntry = fixed[fixedKey];
        const deleteCount = fixedEntry.index ? 1 : 0;
        state.items.splice(fixedEntry.index, deleteCount, fixedEntry);
    }
}
/**
 * Returns a waterfall service wrapper.
 */
export function useWaterfall() {
    const context = {
        get isError() {
            return state.status === WaterfallStatus.ERROR;
        },
        get isPending() {
            return state.status === WaterfallStatus.PENDING;
        },
        get isReady() {
            return state.status === WaterfallStatus.READY;
        },
        async fetch() {
            if (state.fetchStatus === WaterfallFetchStatus.PENDING || shouldIgnore) {
                return;
            }
            // await waitForConnection()
            state.fetchStatus = WaterfallFetchStatus.PENDING;
            ws.send('more');
        },
        fetchStatus: {
            get isDone() {
                return state.fetchStatus === WaterfallFetchStatus.DONE;
            },
            get isError() {
                return state.fetchStatus === WaterfallFetchStatus.ERROR;
            },
            get isIdle() {
                return state.fetchStatus === WaterfallFetchStatus.IDLE;
            },
            get isPending() {
                return state.fetchStatus === WaterfallFetchStatus.PENDING;
            },
        },
        async setMode(mode) {
            if (shouldIgnore) {
                return;
            }
            // await waitForConnection()
            if (!WATERFALL_MODES.includes(mode) || state.mode === mode) {
                return;
            }
            state.status = WaterfallStatus.PENDING;
            state.items = [];
            state.mode = mode;
            ws.send(mode);
        },
        state: readonly(state),
    };
    if (shouldIgnore) {
        return context;
    }
    if (!ws) {
        ws = new ReconnectingWebSocket(process.env.WATERFALL_URL);
    }
    ws.onerror = () => {
        state.status = WaterfallStatus.ERROR;
    };
    ws.onmessage = (event) => {
        if (!event.data) {
            return;
        }
        const message = parseJSON(event.data);
        switch (message.event) {
            case 'init': {
                return onInit(message);
            }
            case 'more': {
                return onMore(message);
            }
            case 'refresh': {
                return onRefresh(message);
            }
            default: {
                console.info(message);
            }
        }
    };
    ws.onopen = () => {
        state.status = WaterfallStatus.READY;
    };
    return context;
}
