import { reactive, ref } from '@nuxtjs/composition-api';
const mutationObserver = ref();
function defineTests(options) {
    return reactive(options);
}
/**
 * Список тестов, например:
 * abSomeTest: 'a' as 'a' | 'b' | 'c',
 */
const tests = defineTests({
// abEditorialButton: 'a' as 'a' | 'b' | 'c',
});
export function useABTest() {
    return {
        get tests() {
            return tests;
        },
        init(el, route) {
            const testsKeys = Object.keys(this.tests);
            if (!testsKeys.length) {
                return;
            }
            for (const key of testsKeys) {
                el.dataset[key] = this.tests[key];
                if (key in route.query) {
                    this.tests[key] = route.query[key];
                }
            }
            mutationObserver.value = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'attributes') {
                        const target = mutation.target;
                        for (const key of testsKeys) {
                            this.tests[key] = target.dataset[key]?.toLowerCase();
                        }
                    }
                }
            });
            mutationObserver.value.observe(el, {
                attributes: true,
            });
        },
    };
}
