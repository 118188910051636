export const PROJECTS = {
    agenda: {
        title: 'Деловая Повестка',
        url: 'forbes-agenda',
    },
    brandvoice: {
        title: 'Блоги',
        url: 'brandvoice',
    },
    company_news: {
        title: 'Новости компаний',
        url: 'novosti-kompaniy',
    },
    education: {
        title: 'Forbes Education',
        url: {
            basic: 'education',
            education: /tegi\/education.*/g,
        },
    },
    fcongress: {
        title: 'FCongress Блог',
        url: 'brandvoice/fcongress',
    },
    franchises: {
        title: 'Forbes Франшизы',
        url: 'franchises',
    },
    fresearch: {
        title: 'F Research',
        url: 'fresearch',
    },
    life: {
        title: 'Forbes Life',
        url: 'forbeslife',
    },
    ontology: {
        title: 'Образование',
        url: 'ontology',
    },
    partner: {
        title: 'Партнерский материал',
        url: 'partners',
    },
    special: {
        title: 'Спецпроект',
        url: 'spetsproekt',
    },
    sport: {
        title: 'Forbes Sport',
        url: {
            basic: 'sport',
            sport: /sport\/.*/g,
        },
    },
    woman: {
        title: 'Forbes Woman',
        url: 'forbes-woman',
    },
    young: {
        title: 'Forbes Young',
        url: 'young',
    },
    club: {
        title: 'Forbes Club',
        url: 'club',
    },
};
