import { defineStore } from 'pinia';
import { useDuck } from '~/services';
import { parseCookies, parseJSON } from '~/utils';
/**
 * JWT cookie name.
 * @string
 */
export const USER_COOKIE_JWT = 'jwt';
/**
 * JWT cookie expires in days.
 * @const
 */
export const USER_COOKIE_EXPIRES_IN_DAYS = 7;
/**
 * JWT cookie SameSite.
 * @const
 */
export const USER_COOKIE_SAMESITE = 'Lax';
async function createOAuth(url, height = 640, width = 640) {
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    const w = window.open(url, '', `popup=1,width=${width},height=${height},top=${top},left=${left}`);
    if (!w) {
        return {};
    }
    w.focus();
    return new Promise((resolve) => {
        const interval = window.setInterval(() => {
            try {
                if (w.location.origin !== window.location.origin) {
                    return;
                }
                const hash = Object.fromEntries(w.location.hash
                    .slice(1)
                    .split(/& */)
                    .map((v) => v.split('=')));
                if (w.location.search) {
                    Object.assign(hash, Object.fromEntries(new URLSearchParams(w.location.search)));
                }
                w.close();
                window.clearInterval(interval);
                resolve(hash);
            }
            catch (error) {
                //
            }
        }, 200);
    });
}
export const useUser = defineStore('user', {
    actions: {
        create(options) {
            return useDuck().pub.postAuthReg({
                info: {
                    displayName: options.name,
                    email: options.email,
                },
                password: options.password,
                subscribe: Number(options.subscribe),
            });
        },
        changePassword(options) {
            return useDuck().pub.postPubNewPassword({
                code: options.code,
                email: options.email,
                password1: options.newPassword,
                password2: options.newPassword,
            });
        },
        createConfirm(options) {
            return useDuck().pub.postAuthRegConfirm(options);
        },
        emailConfirm(options) {
            return useDuck().pub.postPubEmailConfirm(options);
        },
        emailRecovery(options) {
            return useDuck().pub.postPubEmailRecovery(options);
        },
        async getCurrentInfo(refresh = false) {
            if (this.info !== null && !refresh) {
                return this.info;
            }
            try {
                const duck = useDuck();
                const response = await duck.users.getCurrentUser();
                if (this.info) {
                    Object.assign(this.info, response.data);
                }
                else {
                    this.info = response.data;
                }
            }
            catch {
                //
            }
            return this.info;
        },
        async linkGoogle() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile&response_type=token&redirect_uri=${redirectURL}&client_id=1054955595734-t3uqi2uj26g2mnpf3776mmffesvdiunh.apps.googleusercontent.com`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            await duck.users.postPubLinkGoogle(hash);
        },
        async linkMailRu() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://oauth.mail.ru/login?client_id=a2a00fe5b258418694733c38d413dd6e&response_type=code&scope=userinfo&redirect_uri=${redirectURL}&state=some_state`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            await duck.users.postPubLinkMailru(hash);
        },
        async linkVK() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://oauth.vk.com/authorize?client_id=8133880&redirect_uri=${redirectURL}&scope=email,offline&response_type=token&v=5.131`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            await duck.users.postPubLinkVk(hash);
        },
        async linkYandex() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://oauth.yandex.ru/authorize?response_type=token&client_id=9b0e0bd6f31b4ec18a489464d8132c03&redirect_uri=${redirectURL}`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            await duck.users.postPubLinkYandex(hash);
        },
        async loginWithGoogle() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile&response_type=token&redirect_uri=${redirectURL}&client_id=1054955595734-t3uqi2uj26g2mnpf3776mmffesvdiunh.apps.googleusercontent.com`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            const response = await duck.pub.postPubLoginGoogle(hash);
            const { accessToken = '', refreshToken = '' } = response.data;
            this.jwt = {
                accessToken,
                refreshToken,
            };
            this.info = await this.getCurrentInfo();
            this.syncCookies();
        },
        async loginWithMailRu() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://oauth.mail.ru/login?client_id=a2a00fe5b258418694733c38d413dd6e&response_type=code&scope=userinfo&redirect_uri=${redirectURL}&state=some_state`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            const response = await duck.pub.postPubLoginMailru(hash);
            const { accessToken = '', refreshToken = '' } = response.data;
            this.jwt = {
                accessToken,
                refreshToken,
            };
            this.info = await this.getCurrentInfo();
            this.syncCookies();
        },
        async loginWithVK() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://oauth.vk.com/authorize?client_id=8133880&redirect_uri=${redirectURL}&scope=email,offline&response_type=token&v=5.131`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            const response = await duck.pub.postPubLoginVk(hash);
            const { accessToken = '', refreshToken = '' } = response.data;
            this.jwt = {
                accessToken,
                refreshToken,
            };
            this.info = await this.getCurrentInfo();
            this.syncCookies();
        },
        async loginWithYandex() {
            const redirectURL = new URL('/oauth', location.origin);
            const hash = await createOAuth(`https://oauth.yandex.ru/authorize?response_type=token&client_id=9b0e0bd6f31b4ec18a489464d8132c03&redirect_uri=${redirectURL}`);
            if (!hash) {
                return;
            }
            const duck = useDuck();
            const response = await duck.pub.postPubLoginYandex(hash);
            const { accessToken = '', refreshToken = '' } = response.data;
            this.jwt = {
                accessToken,
                refreshToken,
            };
            this.info = await this.getCurrentInfo();
            this.syncCookies();
        },
        async loginWithCookies(cookies) {
            const parsedCookies = parseCookies(cookies);
            const cookieJWT = parseJSON(parsedCookies[USER_COOKIE_JWT]);
            if (!Array.isArray(cookieJWT) || !cookieJWT.length) {
                return;
            }
            const [accessToken = '', refreshToken = ''] = cookieJWT;
            this.jwt = {
                accessToken,
                refreshToken,
            };
            this.info = await this.getCurrentInfo();
        },
        async loginWithCredentials(email, password) {
            const duck = useDuck();
            const response = await duck.auth.login({
                password,
                username: email,
            });
            const { accessToken = '', refreshToken = '' } = response.data;
            this.jwt.accessToken = accessToken;
            this.jwt.refreshToken = refreshToken;
            this.info = await this.getCurrentInfo();
            this.syncCookies();
        },
        logout() {
            this.jwt = {
                accessToken: '',
                refreshToken: '',
            };
            this.info = null;
            this.syncCookies();
        },
        recoverPassword(email) {
            return useDuck().pub.postPubRecovery({ email });
        },
        recoverPasswordConfirm() {
            return useDuck().pub.postPubNewPassword({});
        },
        syncCookies() {
            let maxAge = 60 * 60 * 24 * USER_COOKIE_EXPIRES_IN_DAYS;
            if (!this.isAuthorized) {
                maxAge = 0;
            }
            const value = `${USER_COOKIE_JWT}=${JSON.stringify([
                this.jwt.accessToken,
                this.jwt.refreshToken,
            ])}; Max-Age=${maxAge}; SameSite=${USER_COOKIE_SAMESITE}; Path=/`;
            const context = globalThis.__VUE_SSR_CONTEXT__;
            if (context) {
                return context.res?.setHeader('Set-Cookie', value);
            }
            localStorage.setItem(USER_COOKIE_JWT, JSON.stringify(this.jwt));
            document.cookie = value;
        },
    },
    getters: {
        activePackagesIds(state) {
            if (!state.info) {
                return [];
            }
            return state.info?.activePackages?.map((pkg) => pkg.package?.id) || [];
        },
        isAuthorized(state) {
            return !!(state.jwt.accessToken && state.jwt.refreshToken);
        },
        hasActiveSubscription(state) {
            if (!state.info) {
                return false;
            }
            return state.info?.activePackages?.some((pkg) => Number(pkg.end) * 1000 > new Date().getTime());
        },
    },
    state: () => ({
        info: null,
        jwt: {
            accessToken: '',
            refreshToken: '',
        },
    }),
});
