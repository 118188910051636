export function pluralize(number, singular, pluralToFive, pluralFromFive, shouldDisplayNumber = false) {
    const lastDigit = number % 10;
    if ([11, 12, 13, 14].includes(number)) {
        // Russian language specific ¯\_(ツ)_/¯
        return shouldDisplayNumber ? `${number} ${pluralFromFive}` : pluralFromFive;
    }
    if (lastDigit === 1) {
        return shouldDisplayNumber ? `${number} ${singular}` : singular;
    }
    if ([2, 3, 4].includes(lastDigit)) {
        return shouldDisplayNumber ? `${number} ${pluralToFive}` : pluralToFive;
    }
    return shouldDisplayNumber ? `${number} ${pluralFromFive}` : pluralFromFive;
}
