import Vue from 'vue';
const eventBus = new Vue();
const observer = process.client
    ? new IntersectionObserver((entries) => {
        for (const entry of entries) {
            if (!entry.isIntersecting) {
                continue;
            }
            eventBus.$emit('intersect', entry);
        }
    })
    : undefined;
export function useImageObserver() {
    let imageElement;
    return {
        disconnect() {
            return observer?.disconnect();
        },
        observe(el) {
            imageElement = el;
            return observer?.observe(imageElement);
        },
        onIntersect(callback) {
            return eventBus.$on('intersect', (entry) => {
                if (entry.target !== imageElement) {
                    return;
                }
                callback();
            });
        },
        unobserve() {
            return imageElement && observer?.unobserve(imageElement);
        },
    };
}
