import { defineComponent, reactive, ref, useCssModule } from '@nuxtjs/composition-api';
import { useTask } from 'vue-concurrency';
import { FORM_DATA, FORM_URL, MODAL_TEXT, MODAL_TITLE } from './';
import { useModal, VALIDATORS } from '~/composables';
export default defineComponent({
    name: 'EducationSubscription',
    setup() {
        /**
         * Composables.
         */
        const css = useCssModule();
        const modal = useModal();
        const errorMessage = ref('');
        const formData = reactive({ ...FORM_DATA });
        /**
         * Subscribe Task (POST)
         */
        const subscribeTask = useTask(function* () {
            const search = new URLSearchParams();
            for (const key in formData) {
                search.append(key, formData[key]);
            }
            const response = yield fetch(`${FORM_URL}?${search.toString()}`, {
                method: 'GET',
                headers: {
                    'Sec-Fetch-Mode': 'no-cors',
                },
            });
            const { ok, status, statusText } = response;
            if (!ok && status !== 200 && statusText.toLowerCase() !== 'ok') {
                modal.open('infoDialog', {
                    componentAttrs: {
                        title: 'Что-то пошло не так',
                        text: `Попробуйте подписаться позднее или напишите нам на <a href="mailto:education@forbes.ru?subject=Проблемы с формой обратной связи на странице ${location.href}" target="_blank">education@forbes.ru</a>`,
                        button: 'Закрыть',
                    },
                });
                return console.error('[EducationSubscription]: Request failed', response);
            }
            const html = yield response.text();
            if (!html) {
                modal.open('infoDialog', {
                    componentAttrs: {
                        title: 'Что-то пошло не так',
                        text: `Попробуйте подписаться позднее или напишите нам на <a href="mailto:education@forbes.ru?subject=Проблемы с формой обратной связи на странице ${location.href}" target="_blank">education@forbes.ru</a>`,
                        button: 'Закрыть',
                    },
                });
                return console.error('[EducationSubscription]: Failed to get html', html);
            }
            const doc = new DOMParser().parseFromString(html, 'text/html');
            const h = doc?.querySelector('h2');
            const title = h ? h.innerText : MODAL_TITLE;
            const text = h ? h.nextElementSibling?.innerText : MODAL_TEXT;
            if (title || text) {
                modal.open('infoDialog', {
                    componentAttrs: {
                        title,
                        text,
                        button: 'Хорошо',
                    },
                });
            }
            formData.email = '';
        });
        function handleSubmit() {
            errorMessage.value = '';
            if (formData.email.length === 0) {
                return (errorMessage.value = 'Необходимо указать почту');
            }
            if (!VALIDATORS.email({ value: formData.email })) {
                return (errorMessage.value = 'Неверный формат электронной почты');
            }
            subscribeTask.perform();
        }
        return {
            css,
            errorMessage,
            formData,
            handleSubmit,
            subscribeTask,
        };
    },
});
