import { render, staticRenderFns } from "./SberSpeech.vue?vue&type=template&id=3e19b6c8&"
import script from "./SberSpeech.vue?vue&type=script&lang=ts&"
export * from "./SberSpeech.vue?vue&type=script&lang=ts&"
import style0 from "./SberSpeech.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/var/www/src/components/IconButton/IconButton.vue').default,MicroLoader: require('/var/www/src/components/MicroLoader/MicroLoader.vue').default,RestartButton: require('/var/www/src/components/RestartButton/RestartButton.vue').default,ShareButton: require('/var/www/src/components/ShareButton/ShareButton.vue').default,Bookmark: require('/var/www/src/components/Bookmark/Bookmark.vue').default})
