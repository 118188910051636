import { RATINGS_TAG, RUBRICS_SECTIONS, SECTIONS, SPECIAL_SECTIONS, VIDEO_TAGS } from '~/constants';
/**
 * Wrapper around duck.pub methods for special page
 * @param config
 * @param query
 * @param params
 */
export function getPubSpecial(config, query = {}, params = {}) {
    const { duck, type } = config || {};
    if (type === 'all') {
        return duck.pub.getPubSpecial(query, params);
    }
    if (type === 'landings') {
        return duck.pub.getPubArticles({
            'node[types]': 'link',
            ...query,
        }, params);
    }
    const section = Object.entries(SPECIAL_SECTIONS).find(([key]) => key === type)?.[1]?.id;
    return duck.pub.getPubArticles({
        'node[sections]': section || '',
        ...query,
    }, params);
}
const NODE_SECTIONS = {
    rubrics: Object.values(RUBRICS_SECTIONS)
        .map(({ id }) => id)
        .join(','),
    ...Object.entries(SECTIONS).reduce((paths, [path, { id }]) => {
        paths[path] = id.toString();
        return paths;
    }, {}),
};
const NODE_TAGS = {
    ratings: RATINGS_TAG.id,
    video: Object.values(VIDEO_TAGS)
        .map(({ id }) => id)
        .join(','),
};
/**
 * Wrapper around duck.pub methods for search/archive pages
 * @param config
 * @param query
 * @param params
 */
export function getPubSearch(config, query = {}, params = {}) {
    const { duck, route, brandvoices } = config || {};
    const { name, params: { pathMatch = '' }, } = route;
    const isArchiveChildren = name?.startsWith('archive-all');
    if (isArchiveChildren) {
        const isSpecial = pathMatch.startsWith('special');
        if (isSpecial) {
            return duck.pub.getPubSpecial(query, params);
        }
        const archivePath = pathMatch?.replace(/\/$/, '') || '';
        const isBrandvoice = pathMatch.startsWith('brandvoice');
        const isPodcasts = pathMatch.startsWith('podcasts');
        const isRatings = pathMatch.startsWith('ratings');
        const isVideo = pathMatch.startsWith('video');
        if (isBrandvoice) {
            query = {
                ...query,
                'node[brandvoices]': brandvoices.nodeEntries[archivePath] || '',
            };
        }
        else if (isPodcasts) {
            query = {
                ...query,
                'node[types]': 'podcasts',
            };
        }
        else if (isRatings || isVideo) {
            query = {
                ...query,
                'node[tags]': NODE_TAGS[archivePath] || '',
            };
        }
        else {
            query = {
                ...query,
                'node[sections]': NODE_SECTIONS[archivePath] || '',
            };
        }
    }
    return duck.pub.getPubArticles(query, params);
}
