import { defineComponent, onMounted, ref, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'FormCheckbox',
    inheritAttrs: false,
    props: {
        error: {
            default: '',
            type: String,
        },
        label: {
            default: '',
            type: String,
        },
        labelPosition: {
            default: 'right',
            type: String,
            validator: (value) => {
                return ['left', 'right'].includes(value);
            },
        },
        size: {
            default: 'small',
            type: String,
            validator: (value) => {
                return ['small', 'big'].includes(value);
            },
        },
        theme: {
            default: 'light',
            type: String,
            validator: (value) => {
                return ['dark', 'light'].includes(value);
            },
        },
        validators: {
            default: () => [],
            type: Array,
        },
        value: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['input'],
    setup(_props, { attrs, emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const currentName = ref(attrs.name);
        function handleInput(event) {
            const { checked } = event.target;
            emit('input', checked, event);
        }
        onMounted(() => {
            if (!attrs.name) {
                const id = (Math.random() * 1000000).toFixed(0);
                currentName.value = `checkbox-${id}`;
            }
        });
        return {
            css,
            currentName,
            handleInput,
        };
    },
});
