import { computed, reactive, ref, useContext } from '@nuxtjs/composition-api';
const viewport = ref();
export const MODALS = {
    adsFormModal: {
        component: () => import('~/components/AdsForm/AdsForm.vue'),
        modalAttrs: reactive({
            withClose: true,
        }),
    },
    adDisablingModal: {
        component: () => import('~/components/AdDisablingModal/AdDisablingModal.vue'),
        modalAttrs: reactive({
            withClose: true,
        }),
    },
    emailSubscriptionModal: {
        component: () => import('~/components/EmailSubscriptionModal/EmailSubscriptionModal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '480px')),
            withClose: true,
        }),
    },
    dialogRead: {
        component: () => import('~/components/DialogRead/DialogRead.vue'),
        modalAttrs: reactive({
            justify: computed(() => (viewport.value.isLessThan('tablet') ? 'end' : 'center')),
        }),
    },
    dialogDelete: {
        component: () => import('~/components/DialogDelete/DialogDelete.vue'),
        modalAttrs: reactive({
            justify: computed(() => (viewport.value.isLessThan('tablet') ? 'end' : 'center')),
        }),
    },
    dialogDeleteAuthor: {
        component: () => import('~/components/DialogDeleteAuthor/DialogDeleteAuthor.vue'),
        modalAttrs: reactive({
            justify: computed(() => (viewport.value.isLessThan('tablet') ? 'end' : 'center')),
        }),
    },
    infoDialog: {
        component: () => import('~/components/InfoDialog/InfoDialog.vue'),
        modalAttrs: reactive({
            justify: computed(() => (viewport.value.isLessThan('tablet') ? 'end' : 'center')),
        }),
    },
    infoModal: {
        component: () => import('~/components/InfoModal/InfoModal.vue'),
    },
    paymentAutoRenewal: {
        component: () => import('~/components/PaymentAutoRenewal/PaymentAutoRenewal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '568px')),
            withClose: true,
        }),
    },
    paymentModal: {
        component: () => import('~/components/PaymentModal/PaymentModal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '568px')),
            withClose: true,
        }),
    },
    paymentModalDone: {
        component: () => import('~/components/PaymentModalDone/PaymentModalDone.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '568px')),
            withClose: true,
        }),
    },
    pricingFeatures: {
        component: () => import('~/components/PricingFeatures/PricingFeatures.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '568px')),
            withClose: true,
        }),
    },
    promocode: {
        component: () => import('~/components/PromocodeModal/PromocodeModal.vue'),
        modalAttrs: {
            data: null,
        },
    },
    signChangePassword: {
        component: () => import('~/components/SignChangePassword/SignChangePassword.vue'),
    },
    signIn: {
        component: () => import('~/components/SignIn/SignIn.vue'),
    },
    signRecovery: {
        component: () => import('~/components/SignRecovery/SignRecovery.vue'),
    },
    signRecoveryDone: {
        component: () => import('~/components/SignRecoveryDone/SignRecoveryDone.vue'),
    },
    signUp: {
        component: () => import('~/components/SignUp/SignUp.vue'),
    },
    signUpDone: {
        component: () => import('~/components/SignUpDone/SignUpDone.vue'),
    },
    franchiseOffer: {
        component: () => import('~/components/FranchiseOfferModal/FranchiseOfferModal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '611px')),
        }),
    },
    franchiseRequest: {
        component: () => import('~/components/FranchiseRequestModal/FranchiseRequestModal.vue'),
        modalAttrs: reactive({
            withClose: true,
        }),
    },
    scannerApplication: {
        component: () => import('~/components/ScannerApplicationModal/ScannerApplicationModal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '600px')),
        }),
    },
    clubOffer: {
        component: () => import('~/components/ClubOfferModal/ClubOfferModal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '600px')),
        }),
    },
    commentClubDialog: {
        component: () => import('~/components/CommentClubDialog/CommentClubDialog.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '680px')),
        }),
    },
    clubBiographyModal: {
        component: () => import('~/components/ClubBiographyModal/ClubBiographyModal.vue'),
        modalAttrs: reactive({
            width: computed(() => (viewport.value.isLessThan('tablet') ? '100%' : '600px')),
        }),
    },
};
const modalKey = ref('');
const data = reactive({});
export function useModal() {
    const context = useContext();
    viewport.value = context.$viewport;
    return {
        data,
        get key() {
            return modalKey.value;
        },
        set key(value) {
            if (!value || value in MODALS) {
                modalKey.value = value;
            }
            else {
                console.error(`Modal "${value}" does not exist`);
            }
        },
        get modals() {
            return MODALS;
        },
        /**
         * Closes modal
         */
        close() {
            modalKey.value = '';
        },
        /**
         * Opens modal with optionally setting attributes for it
         */
        open(key, options = {}) {
            if (!key || key in MODALS) {
                this.setAttrs(key, options);
                modalKey.value = key;
            }
        },
        /**
         * Just sets attributes for modal without opening it
         */
        setAttrs(key, options) {
            if (!key || key in MODALS) {
                MODALS[key] = {
                    ...MODALS[key],
                    ...options,
                };
            }
            else {
                console.error(`Modal "${key}" does not exist`);
            }
        },
    };
}
