import { defineComponent, useCssModule, reactive, computed, useContext } from '@nuxtjs/composition-api';
import { COPYRIGHT } from '~/constants';
import { addUtm } from '~/utils';
export default defineComponent({
    name: 'TheMenuMobile',
    props: {
        menus: {
            default: () => [],
            type: Array,
        },
    },
    setup(props) {
        /**
         * Composables
         */
        const css = useCssModule();
        const context = useContext();
        const viewport = context.$viewport;
        const isMobile = viewport.isLessThan('desktopMedium');
        /**
         * The menu items
         */
        const menu = computed(() => {
            return [
                ...(props.menus?.map((menu) => {
                    if (menu?.items?.length) {
                        return {
                            ...menu,
                            items: menu.items.map((item) => addUtm(item, 'burger', isMobile)),
                        };
                    }
                    return addUtm(menu, 'burger', isMobile);
                }) || []),
            ];
        });
        /**
         * The menu items ids that can be expanded
         */
        const expandableItems = reactive({
            rubrics: false,
            projects: false,
            brandvoice: false,
            info: false,
            social: false,
            events: false,
        });
        /**
         * Indicates if at least one item is expanded
         */
        const isExpanded = computed(() => Object.values(expandableItems).some((item) => item));
        /**
         * Toggles submenu
         */
        function toggleSubmenu(slug) {
            expandableItems[slug] = !expandableItems[slug];
        }
        return {
            COPYRIGHT,
            css,
            menu,
            expandableItems,
            isExpanded,
            toggleSubmenu,
        };
    },
});
