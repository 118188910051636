const middleware = {}

middleware['auth'] = require('../src/middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['data'] = require('../src/middleware/data.ts')
middleware['data'] = middleware['data'].default || middleware['data']

middleware['redirects'] = require('../src/middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
