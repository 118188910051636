// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fHgSk{display:block}@media screen and (min-width:1024px){.cJilI{display:flex;align-items:center;justify-content:space-between}}.Xg5Eo{display:flex;width:100%;height:48px;align-items:center;border:1px solid #2d2d2d;border-radius:4px;transition:border-color .2s}.emt2O .Xg5Eo{border-color:#f02d37}.isJFs{width:100%;height:100%;flex:1;padding:12px 16px;border:0;background-color:transparent;border-radius:4px 0 0 4px;caret-color:#0082ff;color:#fff;font-size:15px;line-height:24px;outline:0;transition:color .2s}.isJFs::-webkit-input-placeholder{color:#767676}.isJFs::-moz-placeholder{color:#767676}.isJFs:-ms-input-placeholder{color:#767676}.isJFs:-moz-placeholder{color:#767676}.emt2O .isJFs::-webkit-input-placeholder{color:#f02d37}.emt2O .isJFs::-moz-placeholder{color:#f02d37}.emt2O .isJFs:-ms-input-placeholder{color:#f02d37}.emt2O .isJFs:-moz-placeholder{color:#f02d37}.vERnc{display:flex;width:48px;height:48px;flex-shrink:0;align-items:center;justify-content:center;padding:0;border:0;margin:0;background:transparent;border-radius:0 4px 4px 0;color:#767676;outline:0}.vERnc svg{transform:rotate(-90deg)}@media screen and (min-width:1024px){.OoZS7{display:block;flex-shrink:0;margin-left:16px}}.Tt9WB{margin:4px 0;color:#f02d37;font-size:13px;line-height:20px}.\\+\\+VYZ{padding:24px 16px;margin-top:16px;background-color:#232323;border-radius:4px}@media screen and (min-width:1024px){.\\+\\+VYZ{display:grid;width:272px;padding:0;background:0;grid-gap:12px 16px;gap:12px 16px;grid-template-columns:repeat(2,1fr)}}.\\+\\+VYZ .KIBL\\+{width:100%;justify-content:space-between}@media screen and (min-width:1024px){.\\+\\+VYZ .KIBL\\+{width:auto;justify-content:flex-start}}.KIBL\\++.KIBL\\+{margin-top:24px}@media screen and (min-width:1024px){.KIBL\\++.KIBL\\+{margin-top:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "fHgSk",
	"field": "cJilI",
	"label": "Xg5Eo",
	"withError": "emt2O",
	"input": "isJFs",
	"submitIcon": "vERnc",
	"submit": "OoZS7",
	"error": "Tt9WB",
	"types": "++VYZ",
	"type": "KIBL+"
};
module.exports = ___CSS_LOADER_EXPORT___;
