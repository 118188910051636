// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3wnwF{position:relative}.joX56{display:flex;min-width:32px;min-width:var(--trigger-width,32px);min-height:32px;min-height:var(--trigger-height,32px);align-items:center;justify-content:center;cursor:pointer;line-height:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_3wnwF",
	"trigger": "joX56"
};
module.exports = ___CSS_LOADER_EXPORT___;
