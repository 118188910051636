// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fCQd5{position:relative;width:100%}.wJJv7{position:absolute;z-index:3;top:0;left:0;display:flex;width:100%;height:80px;flex:0 0 auto;align-items:center;padding:16px;background-color:#232323;grid-gap:8px;gap:8px;transform:translateY(48px);transition:transform .2s}.eaOb9 .wJJv7{transform:translateY(0)}.Lrp1T{position:relative;overflow:hidden;width:100%;height:48px;background-color:#2d2d2d;border-radius:4px}.Lrp1T,.bB1pi{display:flex;align-items:center}.bB1pi{position:absolute;top:50%;left:0;padding-left:8px;color:#989898;cursor:text;font-family:\"Source Sans Pro\",sans-serif;font-size:16px;line-height:24px;pointer-events:none;transform:translateY(-50%)}.bB1pi>span{margin-left:8px}.Bo8Hy{width:100%;height:100%;flex:1;padding:8px 0 8px 16px;border:0;background-color:#2d2d2d;caret-color:#0082ff;color:#fff;font-family:\"Source Sans Pro\",sans-serif;font-size:16px;line-height:24px;outline:0}.ik5lL{display:flex;height:100%;flex-shrink:0;align-items:center;padding-right:16px}.A-u81{padding:0;border:0;margin:0 0 0 16px;background:0;color:#0082ff;font-size:16px;line-height:24px;outline:0}.fCQd5 .bguiz{width:48px;height:48px;flex:0 0 48px;padding:0}.fCQd5 .bguiz>svg{margin:0}.M\\+ioJ{position:relative;z-index:2;top:80px;width:100%;height:calc(100vh - 80px);height:calc(var(--vh, 1vh)*100 - 80px);padding:0 16px;overflow-y:auto}.w3Bpt{padding-bottom:80px}.ff7zK{left:50%;transform:translateX(-50%)}.ff7zK,.xdQWp{position:absolute;z-index:4;top:80px}.xdQWp{left:0;width:100%;font-size:15px;line-height:24px;text-align:center}.xdQWp span{color:#f02d37}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theSearchMobile": "fCQd5",
	"search": "wJJv7",
	"isSearchFocused": "eaOb9",
	"label": "Lrp1T",
	"placeholder": "bB1pi",
	"input": "Bo8Hy",
	"controls": "ik5lL",
	"close": "A-u81",
	"advanced": "bguiz",
	"results": "M+ioJ",
	"resultsInner": "w3Bpt",
	"loader": "ff7zK",
	"notFound": "xdQWp"
};
module.exports = ___CSS_LOADER_EXPORT___;
