import { computed, defineComponent, onBeforeUnmount, onMounted, ref, useCssModule, useRoute, watchEffect, } from '@nuxtjs/composition-api';
import { BANNERS_BY_INDEX } from './';
import { useWaterfall } from '~/services';
export default defineComponent({
    name: 'WaterfallMobile',
    emits: ['close'],
    setup() {
        /**
         * Composables.
         */
        const css = useCssModule();
        const route = useRoute();
        const waterfall = useWaterfall();
        const currentLocation = ref('');
        const isEducation = computed(() => {
            return route.value.path.startsWith('/education') || route.value.path.startsWith('/tegi/education');
        });
        /**
         * Time intervals.
         */
        const timeIntervals = computed(() => {
            const intervals = {};
            const { items } = waterfall.state;
            let bannersByIndex = { ...BANNERS_BY_INDEX };
            let lastIndex = 0;
            for (let i = 0, l = items.length; i < l; i++) {
                const intervalKey = new Intl.DateTimeFormat('ru').format(Number(items[i].time) * 1000);
                if (Array.isArray(intervals[intervalKey])) {
                    intervals[intervalKey].push(items[i]);
                }
                else {
                    intervals[intervalKey] = [items[i]];
                }
                const bannersEntries = Object.entries(bannersByIndex);
                bannersEntries.forEach(([bannerIndex, banner]) => {
                    const index = parseInt(bannerIndex, 10);
                    if (i === index) {
                        intervals[intervalKey].splice(i, 0, banner);
                    }
                    lastIndex = index;
                });
                if (i <= lastIndex) {
                    continue;
                }
                bannersByIndex = bannersEntries.reduce((banners, [bannerIndex, banner]) => {
                    const index = parseInt(bannerIndex, 10);
                    banners[index] = banner;
                    banners[index + lastIndex] = banner;
                    return banners;
                }, {});
            }
            return intervals;
        });
        function onHistoryUpdate() {
            currentLocation.value = location.pathname.substr(1);
        }
        onBeforeUnmount(() => {
            window.removeEventListener('historyupdate', onHistoryUpdate);
        });
        onMounted(() => {
            window.addEventListener('historyupdate', onHistoryUpdate);
            watchEffect(() => {
                currentLocation.value = route.value.path.substr(1);
            });
        });
        return {
            css,
            currentLocation,
            isEducation,
            timeIntervals,
            waterfall,
        };
    },
});
