export const PRICING_FEATURES = {
    digest: 'Дайджест (е-мейл рассылка специальных материалов)',
    pdf: 'PDF версия журнала',
    congress: 'Посещение мероприятий Forbes Congress',
    club: 'Посещение мероприятий Forbes Club',
    education: 'Посещение мероприятий Forbes Education',
    investment: 'Подборка материалов по теме "Инвестиции"',
    ratings: 'Подборка материалов по теме "Рейтинги"',
    online: 'Online встречи с редакцией и спикерами',
    partners: 'Специальные предложения от партнеров',
    ad: 'Отключение рекламы',
};
export const PRICING_LINK_PLANS = {
    icon: 'chevron-24-right',
    title: 'Все тарифы',
    to: '/pricing',
};
export const PRICING_LINK_HAS_PLAN = {
    icon: 'enter',
    title: 'У меня уже есть подписка',
    to: '#',
};
export const PRICING_LINK_PROMOCODE = {
    icon: 'promo',
    title: 'У меня есть промокод',
    to: '#',
};
