import { computed, defineComponent, useContext, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'Error',
    props: {
        theme: {
            default: 'default',
            type: String,
        },
        type: {
            default: '404',
            type: String,
        },
    },
    setup() {
        const css = useCssModule();
        const context = useContext();
        const previousUrl = computed(() => {
            return context?.from?.value?.fullPath || '';
        });
        return {
            css,
            previousUrl,
        };
    },
});
