/**
 * Adfox fetch states
 */
export const ADFOX_FETCH_STATES = {
    isCompleted: false,
    isDisabled: false,
    isLoaded: false,
    isRendered: false,
    isUndefined: false,
    withError: false,
    withStub: false,
};
/**
 * Карточка в материале
 */
const articlePartner = {
    comments: `
<!-- Карточка "Партнерский материал" в материале (ТГБ inpage) -->
<!-- containerId: 'adfox_16279152854764771' -->
`,
    params: {
        pp: 'h',
        ps: 'lyw',
        p2: 'hhre',
    },
};
/**
 * Видео в материале
 */
const inPageVideo = {
    comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes /  / -->
<!--Тип баннера: 300x300-->
<!--Расположение: середина страницы-->
<!--<div id="adfox_167652256258079733"></div>-->
    `,
    params: {
        pp: 'h',
        ps: 'lyw',
        p2: 'hius',
        insertAfter: 'undefined',
        insertPosition: '0',
        stick: false,
        stickTo: 'auto',
        stickyColorScheme: 'light',
    },
};
/**
 * Adfox desktop codes
 */
export const ADFOX_CODES_DESKTOP = {
    /**
     * Промерочный пиксель для подсчета трафика
     */
    pixel: {
        comments: `
<!--Промерочный пиксель для подсчета трафика-->
<!--containerId: 'adfox_162988145291044108'-->
`,
        params: {
            pp: 'g',
            ps: 'lyw',
            p2: 'frfe',
        },
    },
    /**
     * логотип в шапке сайта
     */
    logo: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Тизер_вверху new design-->
<!--Расположение: <верх страницы / logo>-->
<!--containerId: 'adfox_163032800738415739'-->
`,
        params: {
            pp: 'chh',
            ps: 'lyw',
            p2: 'elqg',
        },
    },
    logo_story: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Логотип на главной-->
<!--Расположение: середина страницы-->
<!--containerId: 'adfox_17096367290437102'-->
    `,
        params: {
            pp: 'h',
            ps: 'lyw',
            p2: 'iwry',
        },
    },
    /**
     * перетяжка над шапкой сайта
     */
    billboard: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 1000x90js новый-->
<!--Расположение: <верх страницы / перетяжка>-->
<!--containerId: 'adfox_158289223437433218'-->
`,
        params: {
            pp: 'g',
            ps: 'lyw',
            p2: 'evgu',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302731',
                    },
                },
            ],
        },
    },
    /**
     * перетяжка в середине страницы
     */
    billboard_2: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 1000x90js новый-->
<!--Расположение: середина страницы-->
<!--containerId: 'adfox_167119377140136488'-->
`,
        params: {
            pp: 'h',
            ps: 'lyw',
            p2: 'evgu',
        },
    },
    /**
     * Background
     * боковые фреймы
     */
    background: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: background refresh-->
<!--Расположение: <низ страницы>-->
<!--containerId: 'adfox_158289236123392055'-->
`,
        params: {
            pp: 'i',
            ps: 'lyw',
            p2: 'evgv',
        },
    },
    /**
     * 300x600_1
     * первый из 300x600 в центральной колонке
     */
    '300x600_1': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x600-->
<!--Расположение: <верх страницы 300x600_1>-->
<!--containerId: 'adfox_15828924936695877'-->
`,
        params: {
            pp: 'g',
            ps: 'lyw',
            p2: 'fbuu',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302726',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858223',
                    },
                },
            ],
        },
    },
    /**
     * 300x600_2
     * второй из 300x600 в центральной колонке
     */
    '300x600_2': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x600-->
<!--Расположение: <середина страницы 300x600_2>-->
<!--containerId: 'adfox_15828925386687980'-->
`,
        params: {
            pp: 'h',
            ps: 'lyw',
            p2: 'fbuu',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302733',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858698',
                    },
                },
            ],
        },
    },
    /**
     * 300x600_3
     * третий из 300x600 в центральной колонке
     */
    '300x600_3': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x600-->
<!--Расположение: <низ страницы 300x600_3>-->
<!--containerId: 'adfox_158289259981491478'-->
`,
        params: {
            pp: 'i',
            ps: 'lyw',
            p2: 'fbuu',
        },
    },
    /**
     * FullScreen
     * поверх контента
     */
    fullscreen: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: FullScreen-->
<!--Расположение: <верх страницы>-->
<!--containerId: 'adfox_158289465773167840'-->
`,
        params: {
            pp: 'g',
            ps: 'lyw',
            p2: 'ejzo',
        },
    },
    /**
     * Floor ad
     * поверх контента внизу экрана, но ПОД фуллскрин, при одновременной загрузке
     */
    floor: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / / -->
<!--Тип баннера: Media banner-->
<!--Расположение: 2-й для блока-->
<!--containerId: 'adfox_165105792306461449'-->
`,
        params: {
            pp: 'chi',
            ps: 'lyw',
            p2: 'p',
        },
    },
    /**
     * 690x240_1
     * в статье через 3 абзаца от начала
     */
    '690x240_1': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 690x240-->
<!--Расположение: <середина страницы 690x240_1>-->
<!--containerId: 'adfox_158289432461613520'-->
    `,
        params: {
            pp: 'h',
            ps: 'lyw',
            p2: 'fjav',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302745',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '859457',
                    },
                },
            ],
        },
    },
    /**
     * 690x240_2
     * через 3 абзаца от Inpage
     */
    '690x240_2': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 690x240-->
<!--Расположение: 3-й для блока 690x240_2-->
<!--containerId: 'adfox_158289447767428926'-->
    `,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'fjav',
        },
    },
    /**
     * 690x240_3
     * через каждые три абзаца от последнего баннера
     */
    '690x240_3': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 690x240-->
<!--Расположение: 4-й для блока 690x240_3-->
<!--containerId: 'adfox_1582894526260591'-->
    `,
        params: {
            pp: 'chk',
            ps: 'lyw',
            p2: 'fjav',
        },
    },
    /**
     * 690x240_4
     * над блоком "новости партнеров"
     */
    '690x240_4': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 690x240-->
<!--Расположение: <низ страницы> 690x240_4-->
<!--containerId: 'adfox_158289456215228691'-->
    `,
        params: {
            pp: 'i',
            ps: 'lyw',
            p2: 'fjav',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302747',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '859458',
                    },
                },
            ],
        },
    },
    articlePartner,
    /**
     * Партнерский слайдер на главной: слайд 1
     */
    partner_1: {
        comments: `
<!-- Партнерский слайдер на главной: слайд 1 (ТГБ) -->
<!-- containerId: 'adfox_162687279622893126' -->
`,
        params: {
            pp: 'chh',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Партнерский слайдер на главной: слайд 2
     */
    partner_2: {
        comments: `
<!-- Партнерский слайдер на главной: слайд 2 (ТГБ) -->
<!-- containerId: 'adfox_162687286458426576' -->
`,
        params: {
            pp: 'chi',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Партнерский слайдер на главной: слайд 3
     */
    partner_3: {
        comments: `
<!-- Партнерский слайдер на главной: слайд 3 (ТГБ) -->
<!-- containerId: 'adfox_162687287992812927' -->
`,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Партнерский слайдер на главной: слайд 4
     */
    partner_4: {
        comments: `
<!-- Партнерский слайдер на главной: слайд 4 (ТГБ) -->
<!-- containerId: 'adfox_162687289601361764' -->
`,
        params: {
            pp: 'chk',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Слайдер спецпроектов на главной: слайд 1
     */
    special_1: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: ТГБ на главной_ПАРТНЕРЫ-->
<!--Расположение: 5-й для блока-->
<!--containerId: 'adfox_168320072272784477'-->
    `,
        params: {
            pp: 'dqe',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Слайдер спецпроектов на главной: слайд 2
     */
    special_2: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: ТГБ на главной_ПАРТНЕРЫ-->
<!--Расположение: 6-й для блока-->
<!--containerId: 'adfox_168320073438017852'-->
    `,
        params: {
            pp: 'dluk',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Слайдер спецпроектов на главной: слайд 3
     */
    special_3: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: ТГБ на главной_ПАРТНЕРЫ-->
<!--Расположение: 7-й для блока-->
<!--containerId: 'adfox_168320074755282596'-->
    `,
        params: {
            pp: 'dlul',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Слайдер спецпроектов на главной: слайд 4
     */
    special_4: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: ТГБ на главной_ПАРТНЕРЫ-->
<!--Расположение: 8-й для блока-->
<!--containerId: 'adfox_16832007578531213'-->
    `,
        params: {
            pp: 'dlum',
            ps: 'lyw',
            p2: 'hhkh',
        },
    },
    /**
     * Слайдер в материале: слайд 1
     */
    article_slider_1: {
        comments: `
<!-- Слайдер в материале: слайд 1 -->
<!-- containerId: 'adfox_162688171140792631' -->
`,
        params: {
            pp: 'chh',
            ps: 'lyw',
            p2: 'hhkk',
        },
    },
    /**
     * Слайдер в материале: слайд 2
     */
    article_slider_2: {
        comments: `
<!-- Слайдер в материале: слайд 2 -->
<!-- containerId: 'adfox_162688178129011134' -->
`,
        params: {
            pp: 'chi',
            ps: 'lyw',
            p2: 'hhkk',
        },
    },
    /**
     * Слайдер в материале: слайд 3
     */
    article_slider_3: {
        comments: `
<!-- Слайдер в материале: слайд 3 -->
<!-- containerId: 'adfox_162688179647829138' -->
`,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'hhkk',
        },
    },
    /**
     * Слайдер в материале: слайд 4
     */
    article_slider_4: {
        comments: `
<!-- Слайдер в материале: слайд 4 -->
<!-- containerId: 'adfox_162688181272645815' -->
`,
        params: {
            pp: 'chk',
            ps: 'lyw',
            p2: 'hhkk',
        },
    },
    /**
     * Waterfall banner 240x400 1 (редакционный баннер)
     */
    waterfall_240x400_1: {
        comments: `
<!-- Waterfall banner 240x400 1 (редакционный баннер) -->
<!-- containerId: 'adfox_162788722450038270' -->
`,
        params: {
            pp: 'h',
            ps: 'lyw',
            p2: 'fesk',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302724',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858222',
                    },
                },
            ],
        },
    },
    /**
     * Waterfall banner 240x400 2 (редакционный баннер)
     */
    waterfall_240x400_2: {
        comments: `
<!-- Waterfall banner 240x400 2 (редакционный баннер) -->
<!-- containerId: 'adfox_16286928096965347' -->
`,
        params: {
            pp: 'chi',
            ps: 'lyw',
            p2: 'fesk',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302735',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858696',
                    },
                },
            ],
        },
    },
    /**
     * Waterfall banner 240x400 3 (редакционный баннер)
     */
    waterfall_240x400_3: {
        comments: `
<!-- Waterfall banner 240x400 3 (редакционный баннер) -->
<!-- containerId: 'adfox_162869283911058577' -->
`,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'fesk',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858697',
                    },
                },
            ],
        },
    },
    /**
     * Waterfall partner link 1 (рекламный баннер)
     */
    waterfall_1: {
        comments: `
<!-- Waterfall partner link 1 (рекламный баннер) -->
<!-- containerId: 'adfox_162687202235351531' -->
`,
        params: {
            pp: 'chh',
            ps: 'lyw',
            p2: 'hhkg',
        },
    },
    /**
     * Waterfall partner link 2 (рекламный баннер)
     */
    waterfall_2: {
        comments: `
<!-- Waterfall partner link 2 (рекламный баннер) -->
<!-- containerId: 'adfox_162687214280385233' -->
`,
        params: {
            pp: 'chi',
            ps: 'lyw',
            p2: 'hhkg',
        },
    },
    /**
     * Waterfall partner link 3 (рекламный баннер)
     */
    waterfall_3: {
        comments: `
<!-- Waterfall partner link 3 (рекламный баннер) -->
<!-- containerId: 'adfox_162687215965861' -->
`,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'hhkg',
        },
    },
    /**
     * Информер_1
     */
    informer_1: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Информер 300х200-->
<!--Расположение: <верх страницы / informer_1>-->
<!--containerId: 'adfox_162970347587832120'-->
`,
        params: {
            pp: 'chh',
            ps: 'lyw',
            p2: 'fmkh',
        },
    },
    /**
     * Информер_2
     */
    informer_2: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Информер 300х200-->
<!--Расположение: <середина страницы / informer_2>-->
<!--containerId: 'adfox_16297035099442738'-->
`,
        params: {
            pp: 'chi',
            ps: 'lyw',
            p2: 'fmkh',
        },
    },
    /**
     * Информер_3
     */
    informer_3: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Информер 300х200-->
<!--Расположение: <низ страницы / informer_3>-->
<!--containerId: 'adfox_1629703525437722'-->
`,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'fmkh',
        },
    },
    /**
     * Информер_4
     */
    informer_4: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Информер 300х200-->
<!--Расположение: 4-й для блока / informer_4-->
<!--containerId: 'adfox_16696413379647922'-->
`,
        params: {
            pp: 'chk',
            ps: 'lyw',
            p2: 'fmkh',
        },
    },
    inPageVideo,
};
/**
 * Adfox mobile codes
 */
export const ADFOX_CODES_MOBILE = {
    /**
     * 300x250_1
     */
    '300x250_1': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x250 New Mobile-->
<!--Расположение: <верх страницы 300x250_1>-->
<!--containerId: 'adfox_163102688119065126'-->
`,
        params: {
            pp: 'g',
            ps: 'lyw',
            p2: 'fmdn',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302739',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858242',
                    },
                },
            ],
        },
    },
    /**
     * 300x250_2
     */
    '300x250_2': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x250 New Mobile-->
<!--Расположение: <середина страницы 300x250_2>-->
<!--containerId: 'adfox_163102692310344064'-->
`,
        params: {
            pp: 'h',
            ps: 'lyw',
            p2: 'fmdn',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302741',
                    },
                },
                {
                    bidder: 'sape',
                    params: {
                        placementId: '858243',
                    },
                },
            ],
        },
    },
    /**
     * 300x250_3
     */
    '300x250_3': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x250 New Mobile-->
<!--Расположение: 3-й для блока 300x250_3-->
<!--containerId: 'adfox_163102695961614159'-->
`,
        params: {
            pp: 'chj',
            ps: 'lyw',
            p2: 'fmdn',
        },
    },
    /**
     * 300x250_4
     */
    '300x250_4': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x250 New Mobile-->
<!--Расположение: 4-й для блока 300x250_4-->
<!--containerId: 'adfox_163102697724217480'-->
`,
        params: {
            pp: 'chk',
            ps: 'lyw',
            p2: 'fmdn',
        },
    },
    /**
     * 300x250_5
     */
    '300x250_5': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x250 New Mobile-->
<!--Расположение: 5-й для блока 300x250_5-->
<!--containerId: 'adfox_163102699243919724'-->
`,
        params: {
            pp: 'dqe',
            ps: 'lyw',
            p2: 'fmdn',
        },
    },
    /**
     * 300x250_6
     */
    '300x250_6': {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: 300x250 New Mobile-->
<!--Расположение: <низ страницы 300x250_6>-->
<!--containerId: 'adfox_1631027012145398'-->
`,
        params: {
            pp: 'i',
            ps: 'lyw',
            p2: 'fmdn',
        },
        adUnit: {
            bids: [
                {
                    bidder: 'myTarget',
                    params: {
                        placementId: '1302743',
                    },
                },
            ],
        },
    },
    /**
     * Fullscreen
     * поверх контента
     */
    fullscreen: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Fullscreen NEW Mobile-->
<!--Расположение: <верх страницы>-->
<!--containerId: 'adfox_158289470204662488'-->
`,
        params: {
            pp: 'g',
            ps: 'lyw',
            p2: 'fmdr',
        },
        // bannerStates: ['tablet', 'phone'],
        // adaptiveOptions: {
        //   tabletWidth: 720,
        //   phoneWidth: 720,
        // },
        adUnit: {
            bids: [
                {
                    bidder: 'sape',
                    params: {
                        placementId: '923023',
                    },
                },
            ],
        },
    },
    /**
     * Floor ad
     * поверх контента внизу экрана, но ПОД фуллскрин, при одновременной загрузке
     */
    floor: {
        comments: `
<!--AdFox START-->
<!--axelspringer-->
<!--Площадка: Forbes / * / *-->
<!--Тип баннера: Rich Media-->
<!--Расположение: <низ страницы / floor>-->
<!--containerId: 'adfox_158289479468583649'-->
`,
        params: {
            pp: 'i',
            ps: 'lyw',
            p2: 'p',
        },
        // bannerStates: ['tablet', 'phone'],
        // adaptiveOptions: {
        //   tabletWidth: 720,
        //   phoneWidth: 720,
        // },
        adUnit: {
            bids: [
                {
                    bidder: 'sape',
                    params: {
                        placementId: '923024',
                    },
                },
            ],
        },
    },
    articlePartner,
    inPageVideo,
};
