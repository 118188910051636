import { defineNuxtPlugin, onGlobalSetup, onMounted } from '@nuxtjs/composition-api';
import { useStack } from '~/composables';
import { isLightHouse } from '~/utils';
import { IS_DEVELOPMENT_MODE, IS_PRODUCTION_MODE } from '~/constants';
/**
 * Debug mode
 *
 * In Network:
 * https://analytics.google.com/g/collect
 * https://www.google-analytics.com/g/collect
 * https://www.google-analytics.com/j/collect
 */
const IS_DEBUG_MODE = false;
/**
 * Google gtag 4 id
 */
const GOOGLE_GTAG_ID = 'G-DN5YQHJW31';
/**
 * Google Tag Manager id
 */
const GOOGLE_TAG_MANAGER_ID = 'GTM-9DRG';
/**
 * Plugin name
 */
const PLUGIN_NAME = 'googleGtag';
export default defineNuxtPlugin(({ app, env, nuxtState, route }, inject) => {
    let isReady = false;
    const stack = useStack();
    inject(PLUGIN_NAME, ({ excluded, location, path, title } = {}) => {
        const callback = () => {
            const shouldPushGtag = !(excluded || []).includes(PLUGIN_NAME);
            // Push analytics 4
            if (shouldPushGtag) {
                window.dataLayer.push([
                    'config',
                    GOOGLE_GTAG_ID,
                    {
                        page_location: location || window.location.href,
                        page_path: path || window.location.pathname,
                        page_title: title || document.title,
                    },
                ]);
            }
            if (IS_DEBUG_MODE && IS_DEVELOPMENT_MODE && shouldPushGtag) {
                console.info(`[Send ${PLUGIN_NAME}]`, window.dataLayer);
            }
        };
        if (!isReady) {
            return stack.push(callback);
        }
        callback();
    });
    onGlobalSetup(() => {
        onMounted(() => {
            app[`$${PLUGIN_NAME}`]({
                location: env.HOST + route.fullPath,
                path: route.path,
                title: document.title,
            });
        });
    });
    app?.router?.onReady(() => {
        app?.router?.afterEach((to) => {
            app[`$${PLUGIN_NAME}`]({
                location: env.HOST + to.fullPath,
                path: to.path,
                title: (app.$page || nuxtState.data[0].$page || document).title,
            });
        });
    });
    if (IS_DEBUG_MODE || IS_PRODUCTION_MODE) {
        return window.addEventListener('load', async () => {
            if (isLightHouse(navigator.userAgent)) {
                return;
            }
            initTagManager();
            try {
                await initGtag();
                isReady = true;
                stack.execute();
            }
            catch {
                stack.close();
            }
        });
    }
    console.info(`%c${PLUGIN_NAME} is disabled in development mode. Switch to debug mode to enable.`, 'color: DarkGoldenRod;');
});
/**
 * Initialize Google 4 gtag script
 */
function initGtag() {
    return new Promise((resolve, reject) => {
        /* eslint-disable */
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_GTAG_ID}`;
        script.onerror = reject;
        script.onload = resolve;
        document.body.insertBefore(script, document.body.firstElementChild);
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        function gtag() { window.dataLayer.push(arguments); }
        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', GOOGLE_GTAG_ID);
        /* eslint-enable */
    });
}
/**
 * Initialize Google Tag Manager script
 */
function initTagManager() {
    /* eslint-disable */
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0], // @ts-ignore
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.defer = true;
        j.src = // @ts-ignore
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GOOGLE_TAG_MANAGER_ID);
    /* eslint-enable */
    // Noscript
    const iframe = document.createElement('iframe');
    const noscript = document.createElement('noscript');
    iframe.height = '0';
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`;
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.width = '0';
    noscript.appendChild(iframe);
    document?.body?.firstElementChild?.insertAdjacentElement('afterend', noscript);
}
