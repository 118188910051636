export function useStack() {
    let canExecute = true;
    const state = [];
    return {
        close() {
            canExecute = false;
            state.length = 0;
        },
        execute() {
            if (!canExecute) {
                return;
            }
            for (const callback of state) {
                callback();
            }
        },
        push(callback) {
            if (!canExecute) {
                return;
            }
            state.push(callback);
        },
        get state() {
            return state;
        },
    };
}
