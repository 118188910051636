// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R3uN9{position:relative;margin-top:24px}.GMXPL{position:absolute;top:0;left:0;height:40px}.GMXPL>:first-child{height:16px}.GMXPL>:last-child{height:20px;margin-top:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theWaterfallItemAdfox": "R3uN9",
	"loader": "GMXPL"
};
module.exports = ___CSS_LOADER_EXPORT___;
