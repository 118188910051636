import { computed, defineComponent, ref, useContext, useCssModule, useRouter } from '@nuxtjs/composition-api';
import { useAudio } from '~/composables';
import { EDUCATIONS_PODCASTS } from '~/constants';
export default defineComponent({
    name: 'PodcastPlayerMobile',
    props: {
        eventDetail: {
            default: () => ({}),
            type: Object,
            required: true,
        },
        volume: {
            default: () => ({}),
            type: Object,
        },
        audioVolume: {
            default: 75,
            type: Number,
        },
        isMobileFolder: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['folded'],
    setup(props, { emit }) {
        /**
         * CSS Composables.
         */
        const context = useContext();
        const css = useCssModule();
        const router = useRouter();
        /**
         * Context variables.
         */
        const env = context.env;
        /**
         * Audio.
         */
        const audioParam = computed(() => props.eventDetail);
        const audio = computed(() => useAudio(audioParam.value.file.uri || ''));
        const currentVolume = ref(props.volume);
        audio.value.volume = currentVolume.value.current;
        const isEducation = computed(() => {
            return EDUCATIONS_PODCASTS.includes(audioParam.value?.serial?.url?.replace('podcasts/', '') || '');
        });
        const lastTouchCloseY = ref(0);
        const seekBeforeWidth = ref('0');
        const seekTicking = ref();
        const updateTicking = ref();
        const shareUrl = computed(() => {
            const path = audioParam.value.url || audioParam.value.path;
            return env.HOST + path;
        });
        function goToPodcastPage() {
            if (audioParam.value?.serial?.url) {
                emit('folded', true);
                router.push(`/${audioParam.value.serial.url}`);
            }
        }
        function handleBlackout() {
            emit('folded', true);
        }
        function handleSeekInput(event) {
            window.cancelAnimationFrame(seekTicking.value);
            seekTicking.value = window.requestAnimationFrame(() => {
                const target = event.target;
                audio.value.currentTime = parseInt(target.value, 10);
                seekBeforeWidth.value = `${(audio.value.currentTime / audio.value.duration) * 100}%`;
            });
        }
        function handleTouchClose({ targetTouches, type }) {
            const { clientY } = targetTouches[0] || {};
            if (type === 'touchstart') {
                lastTouchCloseY.value = clientY;
            }
            if (clientY > lastTouchCloseY.value) {
                emit('folded', true);
            }
        }
        audio.value.on('update', () => {
            window.cancelAnimationFrame(updateTicking.value);
            updateTicking.value = window.requestAnimationFrame(() => {
                seekBeforeWidth.value = `${(audio.value.currentTime / audio.value.duration) * 100}%`;
            });
        });
        function seekBackward() {
            window.requestAnimationFrame(() => {
                if (audio.value.currentTime < 10) {
                    audio.value.currentTime = 0;
                }
                else {
                    audio.value.currentTime -= 10;
                }
            });
        }
        function seekForward() {
            window.requestAnimationFrame(() => {
                if (audio.value.currentTime > audio.value.duration - 10) {
                    audio.value.currentTime = audio.value.duration;
                }
                else {
                    audio.value.currentTime += 10;
                }
            });
        }
        function togglePlayer() {
            emit('folded', !props.isMobileFolder);
        }
        return {
            audio,
            audioParam,
            css,
            currentVolume,
            goToPodcastPage,
            handleBlackout,
            handleSeekInput,
            handleTouchClose,
            isEducation,
            lastTouchCloseY,
            seekBackward,
            seekBeforeWidth,
            seekForward,
            seekTicking,
            shareUrl,
            togglePlayer,
            updateTicking,
        };
    },
});
