// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5cVcZ{position:relative;z-index:1;display:flex;width:100%;height:100%;min-height:100vh;min-height:calc(var(--vh, 1vh)*100);flex-direction:column;align-items:center;padding:104px 32px;text-align:center}@media screen and (min-width:768px){._5cVcZ{padding-top:72px}}@media screen and (min-width:1552px){._5cVcZ{padding-top:104px}}._5cVcZ.i3kqb{padding:72px 24px}@media screen and (min-width:1552px){._5cVcZ.i3kqb{padding-top:136px}}.ZgG95{display:inline-flex;color:#fff}.Pg\\+P7 .ZgG95{color:#101010}.R1loN{padding:0;margin:48px 0 0;color:hsla(0,0%,46.3%,.35);font-family:\"Merriweather\",serif;font-size:160px;font-weight:900;line-height:176px}@media screen and (min-width:768px){.R1loN{margin-top:40px;font-size:232px;line-height:208px}}@media screen and (min-width:1024px){.R1loN{margin-top:48px;font-size:288px;line-height:264px}}.Pg\\+P7 .R1loN{color:hsla(0,0%,59.6%,.35)}._3-M9C{max-width:272px;margin:16px auto 32px}@media screen and (min-width:768px){._3-M9C{margin-top:32px}}@media screen and (min-width:1552px){._3-M9C{margin-top:48px}}.i3kqb ._3-M9C{max-width:352px;margin:0 auto 32px}.SvKmc{padding:0;margin:0 0 8px;color:#fff;font-size:20px;line-height:32px}@media screen and (min-width:1552px){.SvKmc{margin-bottom:12px;font-size:22px}}.i3kqb .SvKmc{margin-bottom:12px;font-size:22px;line-height:28px}.Pg\\+P7 .SvKmc{color:#101010}.nY5tO{padding:0;margin:0;color:#767676;font-size:14px;line-height:22px}._5cVcZ.Pg\\+P7 .ttLWw{background-color:#232323;color:#fff}._5cVcZ.Pg\\+P7 .ttLWw:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#2d2d2d}@media(any-hover:hover){._5cVcZ.Pg\\+P7 .ttLWw:hover{background-color:#2d2d2d}}._5cVcZ.Pg\\+P7 .ttLWw:focus{background-color:#2d2d2d}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "_5cVcZ",
	"noConnection": "i3kqb",
	"logo": "ZgG95",
	"bestEmployers": "Pg+P7",
	"fourofour": "R1loN",
	"container": "_3-M9C",
	"title": "SvKmc",
	"description": "nY5tO",
	"button": "ttLWw"
};
module.exports = ___CSS_LOADER_EXPORT___;
