import { defineComponent, onMounted, ref, useCssModule } from '@nuxtjs/composition-api';
import { useTask } from 'vue-concurrency';
import { useDuck } from '~/services';
import { CUSTOM_ITEMS, EXCLUDED_BRANDVOICES, MAX_ITEMS_COUNT } from '~/components/TheCarousel';
export default defineComponent({
    name: 'TheCarouselMobile',
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Current item index.
         */
        const currentIndex = ref(0);
        /**
         * Duck service.
         */
        const duck = useDuck();
        /**
         * IntersectionObserver.
         */
        const intersectionObserver = ref();
        /**
         * Items.
         */
        const items = ref([]);
        /**
         * List Element.
         */
        const listEl = ref();
        /**
         * Fetch items task.
         */
        const fetchItemsTask = useTask(function* () {
            try {
                items.value = yield duck.pub.getPubListById('up-waterfall').then(({ data }) => {
                    return (data?.articles
                        ?.filter(({ data }) => {
                        const slug = data?.brandvoice?.[0]?.url?.replace('brandvoice/', '') || '';
                        return !EXCLUDED_BRANDVOICES.includes(slug);
                    })
                        ?.map(({ data }) => {
                        const { name, url } = data?.brandvoice?.[0] || {};
                        return {
                            ...data,
                            heading: {
                                title: name || '',
                                to: `/${url || 'brandvoice'}`,
                            },
                        };
                    }) || []);
                });
            }
            catch (error) {
                console.error(error);
            }
            finally {
                items.value = [...CUSTOM_ITEMS, ...items.value].slice(0, MAX_ITEMS_COUNT);
                yield new Promise((resolve) => setTimeout(resolve));
                if (listEl.value) {
                    intersectionObserver.value = new IntersectionObserver(([entry]) => {
                        if (entry.isIntersecting) {
                            const target = entry.target;
                            const { index = '0' } = target.dataset;
                            currentIndex.value = parseInt(index, 10);
                        }
                    }, {
                        threshold: 1,
                    });
                    for (const child of Array.from(listEl.value.children)) {
                        intersectionObserver.value.observe(child);
                    }
                }
            }
        });
        function isExternalLink(url) {
            return url.startsWith('http');
        }
        onMounted(() => {
            fetchItemsTask.perform();
        });
        return {
            css,
            currentIndex,
            fetchItemsTask,
            items,
            isExternalLink,
            listEl,
        };
    },
});
