import { defineStore } from 'pinia';
import Vue from 'vue';
export const useBookmarkStore = defineStore('bookmarkStore', {
    actions: {
        setData(key, value) {
            Vue.set(this.data, key, value);
        },
    },
    state: () => ({
        data: {},
    }),
});
