// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2DkVm{color:#0082ff;text-decoration:none;transition:color .2s;display:inline-flex;align-items:center;cursor:pointer;font-size:16px;line-height:24px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}._2DkVm:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#006ee1}@media(any-hover:hover){._2DkVm:hover{color:#006ee1}}._2DkVm.AcqMg{font-size:14px}.pN9Lh{transform:translateY(2px) rotate(-90deg)}.AcqMg .pN9Lh{transform:translateY(1px) rotate(-90deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"readMore": "_2DkVm",
	"small": "AcqMg",
	"chevron": "pN9Lh"
};
module.exports = ___CSS_LOADER_EXPORT___;
