import { defineComponent, onBeforeUnmount, onMounted, reactive, ref, useCssModule, useRoute, watch, } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ThePlayer',
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Route.
         */
        const route = useRoute();
        const root = ref(null);
        const isVisible = ref(false);
        const isPodcast = ref(true);
        const isMobileFolder = ref(true);
        const eventDetail = ref();
        const updateTicking = ref();
        const audioVolume = ref();
        const playbackRate = ref(1);
        const isPlaying = ref(false);
        const volume = reactive({
            beforeWidth: '100%',
            current: 1,
            last: 1,
            state: 'full',
            ticking: 0,
        });
        watch(() => [root.value, route.value.path], () => root.value?.focus());
        function handleVolumeInput(event) {
            window.cancelAnimationFrame(volume.ticking);
            volume.ticking = window.requestAnimationFrame(() => {
                const target = event.target;
                const value = parseInt(target.value, 10);
                volume.current = value / 100;
                audioVolume.value = volume.last = volume.current;
                volume.beforeWidth = `${value}%`;
                setVolumeState();
            });
        }
        function toggleVolume() {
            switch (true) {
                case volume.last === 0:
                    audioVolume.value = volume.current = volume.last = 1;
                    volume.beforeWidth = `100%`;
                    break;
                case volume.state === 'off':
                    audioVolume.value = volume.current = volume.last;
                    volume.beforeWidth = `${volume.last * 100}%`;
                    break;
                default:
                    audioVolume.value = volume.current = 0;
                    volume.beforeWidth = '0%';
            }
            setVolumeState();
        }
        function setVolumeState() {
            switch (true) {
                case volume.current === 0:
                    volume.state = 'off';
                    break;
                case volume.current > 0 && volume.current <= 0.5:
                    volume.state = 'half';
                    break;
                case volume.current > 0.5 && volume.current <= 1:
                    volume.state = 'full';
                    break;
            }
        }
        function close() {
            isVisible.value = false;
        }
        function playPause(event) {
            const { detail } = event;
            if (!detail) {
                return;
            }
            isPodcast.value = !!detail.file;
            eventDetail.value = detail;
            isMobileFolder.value = false;
            isVisible.value = true;
            root.value?.focus();
        }
        function handleFolded(newValue) {
            isMobileFolder.value = newValue;
        }
        function changePlaybackSpeed(rage) {
            playbackRate.value = rage;
        }
        function handleDispatchEvents(event) {
            window.dispatchEvent(new CustomEvent(event));
        }
        onBeforeUnmount(() => {
            window.removeEventListener('playPause', playPause);
        });
        onMounted(() => {
            window.addEventListener('playPause', playPause);
        });
        return {
            close,
            playPause,
            toggleVolume,
            handleVolumeInput,
            handleFolded,
            changePlaybackSpeed,
            handleDispatchEvents,
            css,
            isVisible,
            isPodcast,
            eventDetail,
            volume,
            audioVolume,
            isMobileFolder,
            playbackRate,
            isPlaying,
            updateTicking,
            root,
        };
    },
});
