import { defineNuxtPlugin, onGlobalSetup, onMounted } from '@nuxtjs/composition-api';
import { useStack } from '~/composables';
import { isLightHouse } from '~/utils';
import { IS_DEVELOPMENT_MODE, IS_PRODUCTION_MODE } from '~/constants';
/**
 * Debug mode
 *
 * In network:
 * http://dmg.digitaltarget.ru
 * http://h.dmg.digitaltarget.ru
 * http://t2-blocked.com/images/adv.gif
 */
const IS_DEBUG_MODE = false;
/**
 * Amberdata id
 */
const AMBERDATA_ID = 7568;
/**
 * Plugin name
 */
const PLUGIN_NAME = 'amberData';
export default defineNuxtPlugin(({ app }, inject) => {
    let isReady = false;
    const stack = useStack();
    inject(PLUGIN_NAME, (options = {}) => {
        const callback = () => {
            const shouldHit = !(options.excluded || []).includes(PLUGIN_NAME);
            if (!shouldHit) {
                return;
            }
            window.adcm.call();
            if (IS_DEBUG_MODE && IS_DEVELOPMENT_MODE) {
                console.info(`[Send ${PLUGIN_NAME}]`);
            }
        };
        if (!isReady) {
            return stack.push(callback);
        }
        callback();
    });
    onGlobalSetup(() => {
        onMounted(() => {
            app[`$${PLUGIN_NAME}`]();
        });
    });
    app?.router?.onReady(() => {
        app?.router?.afterEach(() => {
            app[`$${PLUGIN_NAME}`]();
        });
    });
    if (IS_DEBUG_MODE || IS_PRODUCTION_MODE) {
        return window.addEventListener('load', async () => {
            if (isLightHouse(navigator.userAgent)) {
                return;
            }
            try {
                await initAmberData();
                isReady = true;
                stack.execute();
            }
            catch {
                stack.close();
            }
        });
    }
    console.info(`%c${PLUGIN_NAME} is disabled in development mode. Switch to debug mode to enable.`, 'color: DarkGoldenRod;');
});
/**
 * Initialize Abmerdata script
 */
function initAmberData() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = '//tag.digitaltarget.ru/adcm.js';
        script.onerror = reject;
        script.onload = () => {
            window.adcm.configure({
                id: AMBERDATA_ID,
            });
            resolve();
        };
        document.head.append(script);
    });
}
