// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0Ai4\\+{display:flex;overflow:hidden;width:20px;height:20px;border:2px solid;margin:auto;animation:_0Pil4 1.1s linear infinite;border-radius:100%}._0Ai4\\+.-\\+OF9{border-color:#0082ff #0082ff #0082ff #2d2d2d}._0Ai4\\+.GkCO4{border-color:#0082ff #0082ff #0082ff #e2e2e2}._0Ai4\\+.O-ktP{border-color:#f4e600 #f4e600 #f4e600 rgba(244,230,0,.5)}@keyframes _0Pil4{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"microLoader": "_0Ai4+",
	"loading-rotate": "_0Pil4",
	"dark": "-+OF9",
	"light": "GkCO4",
	"yellow": "O-ktP"
};
module.exports = ___CSS_LOADER_EXPORT___;
