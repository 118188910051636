// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:768px){.qt8qO{position:fixed;z-index:1000000;bottom:0;left:0;width:calc(100% - 16px);margin:8px;background:#101010;border-radius:8px;opacity:.8;outline:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.qt8qO.Nr8Zn,.qt8qO:focus,.qt8qO[focus-within]{opacity:1}.qt8qO.Nr8Zn,.qt8qO:focus,.qt8qO:focus-within{opacity:1}.qt8qO:focus,.qt8qO:focus-visible,.qt8qO[focus-within]{opacity:1}.qt8qO:focus,.qt8qO:focus-visible,.qt8qO:focus-within{opacity:1}}@media screen and (min-width:1236px){.qt8qO{max-width:1220px}}@media screen and (min-width:1552px){.qt8qO{max-width:1536px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"player": "qt8qO",
	"focus-visible": "Nr8Zn"
};
module.exports = ___CSS_LOADER_EXPORT___;
