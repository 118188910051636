import { defineComponent, h } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'TransitionExpand',
    setup(_props, { slots }) {
        const data = {
            nativeOn: {
                /**
                 * Handles "afterEnter" event.
                 */
                afterEnter(element) {
                    element.style.height = 'auto';
                },
                /**
                 * Handles "enter" event.
                 */
                enter(element) {
                    const { style } = element;
                    const { width } = getComputedStyle(element);
                    style.width = width;
                    style.position = 'absolute';
                    style.visibility = 'hidden';
                    style.height = 'auto';
                    const { height } = getComputedStyle(element);
                    style.width = '';
                    style.position = '';
                    style.visibility = '';
                    style.height = '0px';
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;
                    return requestAnimationFrame(() => {
                        style.height = height;
                    });
                },
                /**
                 * Handles "leave" event.
                 */
                leave(element) {
                    const { style } = element;
                    const { height } = getComputedStyle(element);
                    style.height = height;
                    // eslint-disable-next-line no-unused-expressions
                    getComputedStyle(element).height;
                    requestAnimationFrame(() => {
                        style.height = '0px';
                    });
                },
            },
            props: {
                name: 'expand',
            },
        };
        return () => h('transition', data, slots.default ? slots.default() : null);
    },
});
