import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c95207c0 = () => interopDefault(import('../src/pages/sport/index.vue' /* webpackChunkName: "" */))
const _bad80490 = () => interopDefault(import('../src/pages/_rubric/index.vue' /* webpackChunkName: "" */))
const _3ed11906 = () => interopDefault(import('../src/pages/profile/_id.vue' /* webpackChunkName: "" */))
const _8d13c476 = () => interopDefault(import('../src/pages/_rubric/_.vue' /* webpackChunkName: "" */))
const _40e7cba7 = () => interopDefault(import('../src/pages/-actual/index.vue' /* webpackChunkName: "pages/-actual/index" */))
const _0a67073b = () => interopDefault(import('../src/pages/-fcongress/index.vue' /* webpackChunkName: "pages/-fcongress/index" */))
const _15effd56 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _0862ab59 = () => interopDefault(import('../src/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _06f5abf2 = () => interopDefault(import('../src/pages/account/data.ts' /* webpackChunkName: "pages/account/data" */))
const _3c6c92de = () => interopDefault(import('../src/pages/account/favorites.vue' /* webpackChunkName: "pages/account/favorites" */))
const _3db9bd6f = () => interopDefault(import('../src/pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _73dd48f0 = () => interopDefault(import('../src/pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _620b9166 = () => interopDefault(import('../src/pages/account/subscription.vue' /* webpackChunkName: "pages/account/subscription" */))
const _211e016b = () => interopDefault(import('../src/pages/archive.vue' /* webpackChunkName: "pages/archive" */))
const _68e08bae = () => interopDefault(import('../src/pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _04a99d87 = () => interopDefault(import('../src/pages/archive/data.ts' /* webpackChunkName: "pages/archive/data" */))
const _51f416bb = () => interopDefault(import('../src/pages/archive/_.vue' /* webpackChunkName: "pages/archive/_" */))
const _9bd2e916 = () => interopDefault(import('../src/pages/brandvoice/index.vue' /* webpackChunkName: "pages/brandvoice/index" */))
const _6f8d2edd = () => interopDefault(import('../src/pages/club.vue' /* webpackChunkName: "pages/club" */))
const _21ff48c0 = () => interopDefault(import('../src/pages/club/index.vue' /* webpackChunkName: "pages/club/index" */))
const _616efe4a = () => interopDefault(import('../src/pages/club/about.vue' /* webpackChunkName: "pages/club/about" */))
const _0400c1f9 = () => interopDefault(import('../src/pages/club/data.ts' /* webpackChunkName: "pages/club/data" */))
const _02da4cbb = () => interopDefault(import('../src/pages/club/events.vue' /* webpackChunkName: "pages/club/events" */))
const _92d1d2f8 = () => interopDefault(import('../src/pages/club/faces.vue' /* webpackChunkName: "pages/club/faces" */))
const _56e05666 = () => interopDefault(import('../src/pages/club/partners.vue' /* webpackChunkName: "pages/club/partners" */))
const _56e404bb = () => interopDefault(import('../src/pages/club/partners/_partner.vue' /* webpackChunkName: "pages/club/partners/_partner" */))
const _1a602710 = () => interopDefault(import('../src/pages/club/_subrubric.vue' /* webpackChunkName: "pages/club/_subrubric" */))
const _22529106 = () => interopDefault(import('../src/pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _1411dad4 = () => interopDefault(import('../src/pages/education/index.vue' /* webpackChunkName: "pages/education/index" */))
const _395ba786 = () => interopDefault(import('../src/pages/email_confirm.vue' /* webpackChunkName: "pages/email_confirm" */))
const _21cc9182 = () => interopDefault(import('../src/pages/email_recovery.vue' /* webpackChunkName: "pages/email_recovery" */))
const _ae09f946 = () => interopDefault(import('../src/pages/franchises.vue' /* webpackChunkName: "pages/franchises" */))
const _82465fc0 = () => interopDefault(import('../src/pages/franchises/index.vue' /* webpackChunkName: "pages/franchises/index" */))
const _c1b6154a = () => interopDefault(import('../src/pages/franchises/about.vue' /* webpackChunkName: "pages/franchises/about" */))
const _2dc17667 = () => interopDefault(import('../src/pages/franchises/catalog.vue' /* webpackChunkName: "pages/franchises/catalog" */))
const _71705679 = () => interopDefault(import('../src/pages/franchises/data.ts' /* webpackChunkName: "pages/franchises/data" */))
const _aa4b3e9e = () => interopDefault(import('../src/pages/franchises/methodology.vue' /* webpackChunkName: "pages/franchises/methodology" */))
const _62e5ecaa = () => interopDefault(import('../src/pages/franchises/offer.vue' /* webpackChunkName: "pages/franchises/offer" */))
const _becefae0 = () => interopDefault(import('../src/pages/franchises/_subrubric.vue' /* webpackChunkName: "pages/franchises/_subrubric" */))
const _354f5bc9 = () => interopDefault(import('../src/pages/new.vue' /* webpackChunkName: "pages/new" */))
const _2068cd00 = () => interopDefault(import('../src/pages/oauth.vue' /* webpackChunkName: "pages/oauth" */))
const _0b2ae219 = () => interopDefault(import('../src/pages/podcasts/index.vue' /* webpackChunkName: "pages/podcasts/index" */))
const _3f2e9e6f = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _06dec73c = () => interopDefault(import('../src/pages/ratings/index.vue' /* webpackChunkName: "pages/ratings/index" */))
const _7a38c0be = () => interopDefault(import('../src/pages/reg_confirm.vue' /* webpackChunkName: "pages/reg_confirm" */))
const _2a2245ea = () => interopDefault(import('../src/pages/scanner/index.vue' /* webpackChunkName: "pages/scanner/index" */))
const _fbe681e2 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _a360e43c = () => interopDefault(import('../src/pages/special.vue' /* webpackChunkName: "pages/special" */))
const _208ae732 = () => interopDefault(import('../src/pages/video/index.vue' /* webpackChunkName: "pages/video/index" */))
const _e2f31dd4 = () => interopDefault(import('../src/pages/contacts/data.ts' /* webpackChunkName: "pages/contacts/data" */))
const _1cc80db0 = () => interopDefault(import('../src/pages/embed/news.vue' /* webpackChunkName: "pages/embed/news" */))
const _6427d436 = () => interopDefault(import('../src/pages/ratings/best-cities/index.vue' /* webpackChunkName: "pages/ratings/best-cities/index" */))
const _7a039bd2 = () => interopDefault(import('../src/pages/ratings/best-employers/index.vue' /* webpackChunkName: "pages/ratings/best-employers/index" */))
const _29641368 = () => interopDefault(import('../src/pages/scanner/application.vue' /* webpackChunkName: "pages/scanner/application" */))
const _bb66927a = () => interopDefault(import('../src/pages/scanner/data.ts' /* webpackChunkName: "pages/scanner/data" */))
const _0d627da2 = () => interopDefault(import('../src/pages/scanner/oauth.vue' /* webpackChunkName: "pages/scanner/oauth" */))
const _76ec3309 = () => interopDefault(import('../src/pages/sport/[id].vue' /* webpackChunkName: "pages/sport/[id]" */))
const _3e858279 = () => interopDefault(import('../src/pages/sport/data.ts' /* webpackChunkName: "pages/sport/data" */))
const _50b0497e = () => interopDefault(import('../src/pages/ratings/best-cities/data.ts' /* webpackChunkName: "pages/ratings/best-cities/data" */))
const _5a4bc10d = () => interopDefault(import('../src/pages/ratings/best-employers/about.vue' /* webpackChunkName: "pages/ratings/best-employers/about" */))
const _424b32aa = () => interopDefault(import('../src/pages/ratings/best-employers/data.ts' /* webpackChunkName: "pages/ratings/best-employers/data" */))
const _4a921ee3 = () => interopDefault(import('../src/pages/ratings/best-employers/methodology.vue' /* webpackChunkName: "pages/ratings/best-employers/methodology" */))
const _3c9848c1 = () => interopDefault(import('../src/pages/ratings/best-employers/participation.vue' /* webpackChunkName: "pages/ratings/best-employers/participation" */))
const _53c27f1c = () => interopDefault(import('../src/pages/ratings/best-employers/_year.vue' /* webpackChunkName: "pages/ratings/best-employers/_year" */))
const _441ec6a3 = () => interopDefault(import('../src/pages/-actual/_subtopic.vue' /* webpackChunkName: "pages/-actual/_subtopic" */))
const _5d5344c0 = () => interopDefault(import('../src/pages/brandvoice/_voice.vue' /* webpackChunkName: "pages/brandvoice/_voice" */))
const _124c25e7 = () => interopDefault(import('../src/pages/person/_id.vue' /* webpackChunkName: "pages/person/_id" */))
const _7967d3d1 = () => interopDefault(import('../src/pages/podcasts/_podcast/index.vue' /* webpackChunkName: "pages/podcasts/_podcast/index" */))
const _2a96b765 = () => interopDefault(import('../src/pages/tegi/_id.vue' /* webpackChunkName: "pages/tegi/_id" */))
const _257e7c8f = () => interopDefault(import('../src/pages/video/_id.vue' /* webpackChunkName: "pages/video/_id" */))
const _2eb98350 = () => interopDefault(import('../src/pages/podcasts/_podcast/_episode/index.vue' /* webpackChunkName: "pages/podcasts/_podcast/_episode/index" */))
const _3a776212 = () => interopDefault(import('../src/pages/scanner/_.vue' /* webpackChunkName: "pages/scanner/_" */))
const _3110a16f = () => interopDefault(import('../src/pages/ratings/_.vue' /* webpackChunkName: "pages/ratings/_" */))
const _64be820a = () => interopDefault(import('../src/pages/contacts/_.vue' /* webpackChunkName: "pages/contacts/_" */))
const _fc0f3e70 = () => interopDefault(import('../src/pages/-fcongress/_.vue' /* webpackChunkName: "pages/-fcongress/_" */))
const _513c51bb = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d873ebde = () => interopDefault(import('../src/pages/_rubric/data.ts' /* webpackChunkName: "pages/_rubric/data" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '',
  linkExactActiveClass: '',
  scrollBehavior,

  routes: [{
    path: "/sport/figure-skating",
    component: _c95207c0
  }, {
    path: "/education/:subrubric(\\D+)",
    component: _bad80490
  }, {
    path: "/rating/40-publichnyh-kompanii/2009/afk-sistema",
    component: _3ed11906
  }, {
    path: "/rating/30-rante-2010/2010/enka",
    component: _3ed11906
  }, {
    path: "/rating/200-krupneishih-nepublichnyh-kompanii/2007/dsk-avtoban",
    component: _3ed11906
  }, {
    path: "/rating/100-krupneishih-bankov-aprel-2010/uralskii-bank-rekonstruktsii-i-razvitiya",
    component: _3ed11906
  }, {
    path: "/rating/100-krupneishih-bankov-aprel-2010/2010/rosevrobank",
    component: _3ed11906
  }, {
    path: "/rating/100-krupneishih-bankov-aprel-2010/2010/evrofinans-mosnarbank",
    component: _3ed11906
  }, {
    path: "/rating/100-krupneishih-bankov-112009/natskliringtsentr",
    component: _3ed11906
  }, {
    path: "/rating/100-krupneishih-bankov-112009/2009/raiffaizenbank",
    component: _3ed11906
  }, {
    path: "/rating/100-bankov/2009/zapsibkombank",
    component: _3ed11906
  }, {
    path: "/rating/100-bankov/2009/ak-bars",
    component: _3ed11906
  }, {
    path: "/video/:video(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/reklama-v-zhurnale",
    component: _8d13c476
  }, {
    path: "/reklama-na-saite",
    component: _8d13c476
  }, {
    path: "/club/:subrubric(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/club/partners/(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/franchises/:subrubric(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/education/:subrubric(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/brandvoice/:voice/(.+)",
    component: _8d13c476
  }, {
    path: "/brandvoice/:voice(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/:rubric(\\?!podcasts)/:subrubric/(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/:article(\\d+)-(.*)",
    component: _8d13c476
  }, {
    path: "/-actual",
    component: _40e7cba7,
    name: "-actual"
  }, {
    path: "/-fcongress",
    component: _0a67073b,
    name: "-fcongress"
  }, {
    path: "/account",
    component: _15effd56,
    children: [{
      path: "",
      component: _0862ab59,
      name: "account"
    }, {
      path: "data",
      component: _06f5abf2,
      name: "account-data"
    }, {
      path: "favorites",
      component: _3c6c92de,
      name: "account-favorites"
    }, {
      path: "notifications",
      component: _3db9bd6f,
      name: "account-notifications"
    }, {
      path: "profile",
      component: _73dd48f0,
      name: "account-profile"
    }, {
      path: "subscription",
      component: _620b9166,
      name: "account-subscription"
    }]
  }, {
    path: "/archive",
    component: _211e016b,
    children: [{
      path: "",
      component: _68e08bae,
      name: "archive"
    }, {
      path: "data",
      component: _04a99d87,
      name: "archive-data"
    }, {
      path: "*",
      component: _51f416bb,
      name: "archive-all"
    }]
  }, {
    path: "/brandvoice",
    component: _9bd2e916,
    name: "brandvoice"
  }, {
    path: "/club",
    component: _6f8d2edd,
    children: [{
      path: "",
      component: _21ff48c0,
      name: "club"
    }, {
      path: "about",
      component: _616efe4a,
      name: "club-about"
    }, {
      path: "data",
      component: _0400c1f9,
      name: "club-data"
    }, {
      path: "events",
      component: _02da4cbb,
      name: "club-events"
    }, {
      path: "faces",
      component: _92d1d2f8,
      name: "club-faces"
    }, {
      path: "partners",
      component: _56e05666,
      name: "club-partners",
      children: [{
        path: ":partner?",
        component: _56e404bb,
        name: "club-partners-partner"
      }]
    }, {
      path: ":subrubric",
      component: _1a602710,
      name: "club-subrubric"
    }]
  }, {
    path: "/contacts",
    component: _22529106,
    name: "contacts"
  }, {
    path: "/education",
    component: _1411dad4,
    name: "education"
  }, {
    path: "/email_confirm",
    component: _395ba786,
    name: "email_confirm"
  }, {
    path: "/email_recovery",
    component: _21cc9182,
    name: "email_recovery"
  }, {
    path: "/franchises",
    component: _ae09f946,
    children: [{
      path: "",
      component: _82465fc0,
      name: "franchises"
    }, {
      path: "about",
      component: _c1b6154a,
      name: "franchises-about"
    }, {
      path: "catalog",
      component: _2dc17667,
      name: "franchises-catalog"
    }, {
      path: "data",
      component: _71705679,
      name: "franchises-data"
    }, {
      path: "methodology",
      component: _aa4b3e9e,
      name: "franchises-methodology"
    }, {
      path: "offer",
      component: _62e5ecaa,
      name: "franchises-offer"
    }, {
      path: ":subrubric",
      component: _becefae0,
      name: "franchises-subrubric"
    }]
  }, {
    path: "/new",
    component: _354f5bc9,
    name: "new"
  }, {
    path: "/oauth",
    component: _2068cd00,
    name: "oauth"
  }, {
    path: "/podcasts",
    component: _0b2ae219,
    name: "podcasts"
  }, {
    path: "/pricing",
    component: _3f2e9e6f,
    name: "pricing"
  }, {
    path: "/ratings",
    component: _06dec73c,
    name: "ratings"
  }, {
    path: "/reg_confirm",
    component: _7a38c0be,
    name: "reg_confirm"
  }, {
    path: "/scanner",
    component: _2a2245ea,
    name: "scanner"
  }, {
    path: "/search",
    component: _fbe681e2,
    name: "search"
  }, {
    path: "/special",
    component: _a360e43c,
    name: "special"
  }, {
    path: "/sport",
    component: _c95207c0,
    name: "sport"
  }, {
    path: "/video",
    component: _208ae732,
    name: "video"
  }, {
    path: "/contacts/data",
    component: _e2f31dd4,
    name: "contacts-data"
  }, {
    path: "/embed/news",
    component: _1cc80db0,
    name: "embed-news"
  }, {
    path: "/ratings/best-cities",
    component: _6427d436,
    name: "ratings-best-cities"
  }, {
    path: "/ratings/best-employers",
    component: _7a039bd2,
    name: "ratings-best-employers"
  }, {
    path: "/scanner/application",
    component: _29641368,
    name: "scanner-application"
  }, {
    path: "/scanner/data",
    component: _bb66927a,
    name: "scanner-data"
  }, {
    path: "/scanner/oauth",
    component: _0d627da2,
    name: "scanner-oauth"
  }, {
    path: "/sport/[id]",
    component: _76ec3309,
    name: "sport-[id]"
  }, {
    path: "/sport/data",
    component: _3e858279,
    name: "sport-data"
  }, {
    path: "/ratings/best-cities/data",
    component: _50b0497e,
    name: "ratings-best-cities-data"
  }, {
    path: "/ratings/best-employers/about",
    component: _5a4bc10d,
    name: "ratings-best-employers-about"
  }, {
    path: "/ratings/best-employers/data",
    component: _424b32aa,
    name: "ratings-best-employers-data"
  }, {
    path: "/ratings/best-employers/methodology",
    component: _4a921ee3,
    name: "ratings-best-employers-methodology"
  }, {
    path: "/ratings/best-employers/participation",
    component: _3c9848c1,
    name: "ratings-best-employers-participation"
  }, {
    path: "/ratings/best-employers/:year?",
    component: _53c27f1c,
    name: "ratings-best-employers-year"
  }, {
    path: "/-actual/:subtopic?",
    component: _441ec6a3,
    name: "-actual-subtopic"
  }, {
    path: "/brandvoice/:voice",
    component: _5d5344c0,
    name: "brandvoice-voice"
  }, {
    path: "/person/:id?",
    component: _124c25e7,
    name: "person-id"
  }, {
    path: "/podcasts/:podcast",
    component: _7967d3d1,
    name: "podcasts-podcast"
  }, {
    path: "/profile/:id?",
    component: _3ed11906,
    name: "profile-id"
  }, {
    path: "/tegi/:id?",
    component: _2a96b765,
    name: "tegi-id"
  }, {
    path: "/video/:id",
    component: _257e7c8f,
    name: "video-id"
  }, {
    path: "/podcasts/:podcast/:episode",
    component: _2eb98350,
    name: "podcasts-podcast-episode"
  }, {
    path: "/scanner/*",
    component: _3a776212,
    name: "scanner-all"
  }, {
    path: "/ratings/*",
    component: _3110a16f,
    name: "ratings-all"
  }, {
    path: "/contacts/*",
    component: _64be820a,
    name: "contacts-all"
  }, {
    path: "/-fcongress/*",
    component: _fc0f3e70,
    name: "-fcongress-all"
  }, {
    path: "/",
    component: _513c51bb,
    name: "index"
  }, {
    path: "/:rubric",
    component: _bad80490,
    alias: ["/:rubric/(\\w+)"],
    name: "rubric"
  }, {
    path: "/:rubric/data",
    component: _d873ebde,
    name: "rubric-data"
  }, {
    path: "/:rubric/*",
    component: _8d13c476,
    name: "rubric-all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
