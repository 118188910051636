import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'MicroLoader',
    props: {
        theme: {
            default: 'light',
            type: String,
            validator(value) {
                return ['dark', 'light', 'yellow'].includes(value);
            },
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            css,
        };
    },
});
