import 'dayjs/plugin/relativeTime';
import { humanizeDate } from './humanizeDate';
import { EDUCATION_TAGS_REGEXP } from '~/constants';
import { getUTCTime } from '~/utils';
/**
 * Returns formatted article info.
 */
export function getArticleInfo({ authors, brandvoice, changed, creator, sections, tags, time, url_alias: path, type }, { shouldGetParentRubrics = true, shouldGetTags = true } = {}) {
    /**
     * Is franchise
     */
    const isFranchise = type === 'franchise';
    /**
     * Is press release
     */
    const isPressRelease = Boolean(sections?.find(({ url }) => url === 'novosti-kompaniy'));
    /**
     * Published at.
     */
    const publishedAt = humanizeDate.call(this, time * 1000);
    /**
     * Rubrics.
     */
    let rubrics = sections?.reduce((list, section, _, array) => {
        let title = section.name;
        let to = `/${section.url}`;
        if (brandvoice) {
            const { name, url } = brandvoice;
            title = name + ' Блог';
            to = `/${url}`;
        }
        if (shouldGetParentRubrics && section?.parent) {
            const hasRubric = array.findIndex(({ id }) => Number(id) === Number(section.parent.id)) > -1;
            if (!hasRubric) {
                list.push({
                    title: section.parent.name,
                    to: `/${section.parent.url}`,
                });
            }
        }
        list.push({
            title,
            to,
        });
        return list;
    }, []) || [];
    if (path && type === 'link') {
        if (path.includes('education.forbes.ru')) {
            rubrics.unshift({
                title: 'Образование',
                to: '/education/',
            });
        }
        else {
            rubrics.unshift({
                title: rubrics[0]?.title || 'Лендинги',
                to: '/special?type=landings',
            });
        }
        if (!path.startsWith('http')) {
            path = 'https://' + path;
        }
    }
    if (type === 'podcasts' && !rubrics.length) {
        rubrics.unshift({
            title: 'Подкасты',
            to: '/podcasts',
        });
    }
    if (shouldGetTags && (tags || []).length) {
        rubrics = [
            ...tags.map(({ name = '', url = '' }) => ({
                title: name.replace(EDUCATION_TAGS_REGEXP, ''),
                to: `/${url}`,
            })),
            ...rubrics,
        ];
    }
    if (isFranchise) {
        rubrics = [
            { title: 'Forbes Франшизы', to: '/franchises' },
            { title: 'Каталог', to: '/franchises/catalog' },
        ];
    }
    const utcPublishedAt = getUTCTime.call(this, time);
    const utcModifiedAt = changed < time ? utcPublishedAt : getUTCTime.call(this, changed);
    return {
        authors,
        creator,
        isFranchise,
        isPressRelease,
        path,
        publishedAt,
        rubrics,
        utcPublishedAt,
        utcModifiedAt,
    };
}
