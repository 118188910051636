import { render, staticRenderFns } from "./AdfoxAdvertiser.vue?vue&type=template&id=2b803937&"
import script from "./AdfoxAdvertiser.vue?vue&type=script&lang=ts&"
export * from "./AdfoxAdvertiser.vue?vue&type=script&lang=ts&"
import style0 from "./AdfoxAdvertiser.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./AdfoxAdvertiser.vue?vue&type=style&index=1&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TooltipMobile: require('/var/www/src/components/TooltipMobile/TooltipMobile.vue').default,Tooltip: require('/var/www/src/components/Tooltip/Tooltip.vue').default})
