import { defineComponent, onMounted, ref, useCssModule } from '@nuxtjs/composition-api';
import { EMPTY_IMAGE_DATA } from '../BaseImage';
export default defineComponent({
    name: 'ImageAvatar',
    props: {
        name: {
            default: '',
            type: String,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const source = ref(EMPTY_IMAGE_DATA);
        const size = ref({
            height: 512,
            width: 512,
        });
        function createAvatar() {
            const { height, width } = size.value;
            const canvas = document.createElement('canvas');
            canvas.height = height;
            canvas.width = width;
            const context = canvas.getContext('2d');
            if (!context) {
                return '';
            }
            const color = '#f4e600';
            const fontPos = width / 2;
            context.fillStyle = color;
            context.fillRect(0, 0, width, height);
            context.font = `${width / 2.5}px Merriweather`;
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.fillStyle = 'black';
            context.fillText(props.name[0], fontPos + 1, fontPos + 14, fontPos);
            return canvas.toDataURL();
        }
        onMounted(() => {
            source.value = createAvatar();
        });
        return {
            createAvatar,
            css,
            size,
            source,
        };
    },
});
