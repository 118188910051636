import { render, staticRenderFns } from "./SpeechPlayer.vue?vue&type=template&id=7a215630&"
import script from "./SpeechPlayer.vue?vue&type=script&lang=ts&"
export * from "./SpeechPlayer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SberSpeechMobile: require('/var/www/src/components/SberSpeechMobile/SberSpeechMobile.vue').default,SberSpeech: require('/var/www/src/components/SberSpeech/SberSpeech.vue').default})
