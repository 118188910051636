/**
 * Returns a human readable date.
 */
export function humanizeDate(input) {
    const now = this.$dayjs();
    const date = this.$dayjs(input);
    if (now.diff(date, 'days') > 3) {
        return date.format('DD MMMM YYYY');
    }
    else if (now.diff(date, 'seconds') < 60) {
        return '1 минуту назад';
    }
    else {
        return date.from(now);
    }
}
