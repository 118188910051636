export const DEFAULT_OPTIONS = {
    borderRadius: '4px',
    triggerHeight: '32px',
    triggerWidth: '32px',
    event: 'hover',
    gap: '10px',
    height: 'auto',
    justify: 'center',
    padding: '',
    position: 'bottom',
    size: 'small',
    shouldCloseOnScroll: true,
    shouldAppendToBody: false,
    isVisibleTooltip: true,
    whiteSpace: 'nowrap',
    width: 'auto',
    withCorner: true,
};
export const DEFAULT_PADDINGS = {
    small: '8px 16px',
    'small-plus': '12px 20px',
    medium: '24px 16px',
};
