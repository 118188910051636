import { defineComponent, onMounted, ref, useCssModule, useRoute, watch } from '@nuxtjs/composition-api';
import { ADFOX_CODES_DESKTOP, ADFOX_FETCH_STATES } from '~/components/Adfox';
import { parseAdfoxHTML } from '~/utils';
import { SHOULD_DISABLE } from '~/plugins/adfox';
export default defineComponent({
    name: 'TheHeaderAdfox',
    setup() {
        /**
         * Composables.
         */
        const css = useCssModule();
        const route = useRoute();
        /**
         * Adfox config
         */
        const config = ADFOX_CODES_DESKTOP.logo;
        /**
         * Root element
         */
        const rootEl = ref();
        /**
         * Adfox pseudo-article
         */
        const adfoxArticle = ref();
        /**
         * Article path
         */
        const articlePath = ref('');
        const shouldRender = ref(!SHOULD_DISABLE);
        const currentStates = ref({ ...ADFOX_FETCH_STATES });
        /**
         * Handles custom switch article event
         */
        function handleSwitchArticle() {
            articlePath.value = location.pathname;
        }
        /**
         * Adfox onComplete
         */
        function onComplete({ state }) {
            currentStates.value = state;
            if (rootEl.value) {
                const adfoxEl = rootEl.value.querySelector('[id^="adfox"]');
                if (!adfoxEl) {
                    return;
                }
                shouldRender.value = !(state.withError || state.withStub);
                if (shouldRender.value) {
                    // Parse HTMl in Adfox container
                    adfoxArticle.value = parseAdfoxHTML(adfoxEl);
                    // Slice title
                    if (adfoxArticle.value?.title) {
                        adfoxArticle.value.title = adfoxArticle.value.title.slice(0, 25);
                    }
                }
            }
        }
        onMounted(() => {
            watch(route, ({ name = '' }) => {
                if (name === 'rubric-all') {
                    window.addEventListener('switchArticle', handleSwitchArticle);
                }
                else {
                    articlePath.value = '';
                    window.removeEventListener('switchArticle', handleSwitchArticle);
                }
            }, {
                immediate: true,
            });
        });
        return {
            articlePath,
            adfoxArticle,
            config,
            css,
            currentStates,
            onComplete,
            rootEl,
            shouldRender,
        };
    },
});
