export const MENU_PROFILE = [
    {
        icon: 'profile-info',
        title: 'Личный кабинет',
        to: '/account/profile',
    },
    {
        icon: 'profile-subscription',
        isLocked: true,
        title: 'Forbes подписка',
        to: '/account/subscription',
    },
    {
        icon: 'profile-bookmark',
        title: 'Избранное',
        to: '/account/favorites',
    },
    {
        icon: 'profile-notifications',
        title: 'Рассылки и уведомления',
        to: '/account/notifications',
    },
];
