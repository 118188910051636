import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { DEFAULT_TOOLTIP } from './';
import { useA11y, useBookmarkStore, useModal, useUser } from '~/composables';
import { useDuck } from '~/services';
import { ARTICLE_BOOKMARK_COOKIE } from '~/constants';
export default defineComponent({
    name: 'Bookmark',
    inheritAttrs: false,
    props: {
        article: {
            default: '',
            type: String,
        },
        theme: {
            default: 'light',
            type: String,
            validator: (value) => {
                return ['a11y', 'light', 'dark', 'transparent', 'blue'].includes(value);
            },
        },
        tooltip: {
            default: false,
            type: [Boolean, Object],
        },
    },
    setup(props) {
        const bookmarkStore = useBookmarkStore();
        const a11y = useA11y();
        const css = useCssModule();
        const duck = useDuck();
        const modal = useModal();
        const user = useUser();
        const currentId = computed(() => {
            const strings = props.article?.split('/') || [];
            const lastString = strings[strings.length - 1];
            const [match = ''] = lastString?.match(/\d+/) || [];
            return match;
        });
        const isActive = computed(() => bookmarkStore.data[currentId.value] !== undefined);
        const currentText = computed(() => (isActive.value ? 'Убрать из избранного' : 'В избранное'));
        const currentTooltip = computed(() => {
            return {
                ...DEFAULT_TOOLTIP,
                size: a11y.isEnabled ? 'small-plus' : 'small',
                ...(typeof props.tooltip === 'object' && props.tooltip),
            };
        });
        const iconName = computed(() => {
            let name = 'bookmark';
            if (isActive.value) {
                name += '-active';
            }
            if (a11y.isEnabled) {
                name += '-a11y';
            }
            return name;
        });
        async function handleClick() {
            if (!currentId.value) {
                return;
            }
            if (!user.isAuthorized) {
                const maxAge = 60 * 60 * 24 * 7; // 7 days
                document.cookie = `${ARTICLE_BOOKMARK_COOKIE}=${currentId.value}; Path=/; Max-Age=${maxAge}; Secure; SameSite=Strict`;
                modal.open('signIn', {
                    componentAttrs: {
                        alert: {
                            text: 'Чтобы добавить материал в избранное войдите или зарегистрируйтесь.',
                        },
                    },
                });
                return;
            }
            if (isActive.value) {
                bookmarkStore.setData(currentId.value, undefined);
                return duck.savedArticle.deleteSaveArticle({
                    articleId: +currentId.value,
                });
            }
            duck.savedArticle.postSaveArticle({
                articleId: +currentId.value,
            });
            bookmarkStore.setData(currentId.value, -1);
        }
        return {
            a11y,
            css,
            currentId,
            currentText,
            currentTooltip,
            iconName,
            isActive,
            bookmarkStore,
            handleClick,
            user,
        };
    },
});
