import { render, staticRenderFns } from "./default.vue?vue&type=template&id=56dee4ec&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/var/www/src/components/TheHeader/TheHeader.vue').default,TheFooter: require('/var/www/src/components/TheFooter/TheFooter.vue').default,TheWaterfall: require('/var/www/src/components/TheWaterfall/TheWaterfall.vue').default,ThePlayer: require('/var/www/src/components/ThePlayer/ThePlayer.vue').default,Modal: require('/var/www/src/components/Modal/Modal.vue').default})
