import { computed, defineComponent, reactive, ref, useCssModule } from '@nuxtjs/composition-api';
import { useTask } from 'vue-concurrency';
import { useDuck } from '~/services';
import { useModal, VALIDATORS } from '~/composables';
import { NEWSLETTER_TYPES } from '~/constants';
/**
 * Duck service.
 */
const duck = useDuck();
export default defineComponent({
    name: 'NewsletterSubscription',
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Modal.
         */
        const modal = useModal();
        const currentEmail = ref('');
        const errorMessage = ref('');
        /**
         * Newsletter types
         */
        const newsletterTypes = {
            [NEWSLETTER_TYPES.all]: reactive({
                checked: true,
                label: 'Все сразу',
                type: 'all',
            }),
            [NEWSLETTER_TYPES.everyweek]: reactive({
                checked: false,
                label: 'Еженедельная',
                type: 'everyweek',
            }),
            [NEWSLETTER_TYPES.everyday]: reactive({
                checked: false,
                label: 'Ежедневная',
                type: 'everyday',
            }),
        };
        /**
         * All newsletter type value
         */
        const allValue = computed(() => {
            const [value] = Object.entries(newsletterTypes)?.find(([_, { type }]) => type === 'all') || [];
            return value;
        });
        /**
         * Selected newsletter types for POST
         */
        const selectedValues = new Set();
        /**
         * Initialize selected types
         */
        for (const type in newsletterTypes) {
            const { checked } = newsletterTypes[type];
            selectedValues[checked ? 'add' : 'delete'](type);
        }
        /**
         * Subscribe Task (POST)
         */
        const subscribeTask = useTask(function* () {
            return duck.pub
                .subscribe({
                email: currentEmail.value,
                listIds: JSON.stringify(Array.from(selectedValues)),
            })
                .then(() => {
                modal.open('infoDialog', {
                    componentAttrs: {
                        title: 'Отлично! Вы подписались на рассылки forbes.ru',
                        text: 'На указанный вами email будут приходить свежие новости и рассылки.',
                        button: 'Хорошо',
                    },
                });
                setInitialState();
            })
                .catch((error) => {
                modal.open('infoDialog', {
                    componentAttrs: {
                        title: 'Что-то пошло не так',
                        text: 'Попробуйте подписаться позднее или напишите нам на <a href="mailto:press-release@forbes.ru?subject=Проблемы с подпиской в подвале сайта" target="_blank">press-release@forbes.ru</a>',
                        button: 'Закрыть',
                    },
                });
                console.error('[NewsletterSubscription]', error);
            });
        });
        function handleInputType(checked, event) {
            const { dataset: { value = '' }, } = event.target;
            const type = newsletterTypes[value];
            errorMessage.value = '';
            selectedValues[checked ? 'add' : 'delete'](value);
            if (type.checked) {
                if (type.type === 'all') {
                    for (const value in newsletterTypes) {
                        const type = newsletterTypes[value];
                        if (type.type !== 'all') {
                            type.checked = false;
                        }
                    }
                    selectedValues.clear();
                    selectedValues.add(allValue.value);
                }
                else {
                    newsletterTypes[allValue.value].checked = false;
                    selectedValues.delete(allValue.value);
                }
            }
        }
        function handleInputEmail(event) {
            const target = event.target;
            currentEmail.value = target.value;
            errorMessage.value = '';
        }
        function handleSubmit() {
            errorMessage.value = '';
            if (currentEmail.value.length === 0) {
                return (errorMessage.value = 'Необходимо указать почту');
            }
            if (!VALIDATORS.email({ value: currentEmail.value })) {
                return (errorMessage.value = 'Неверный формат электронной почты');
            }
            if (selectedValues.size === 0) {
                return (errorMessage.value = 'Необходимо выбрать хотя бы одну рассылку');
            }
            subscribeTask.perform();
        }
        function setInitialState() {
            currentEmail.value = '';
            for (const value in newsletterTypes) {
                const type = newsletterTypes[value];
                type.checked = type.type === 'all';
            }
        }
        return {
            allValue,
            css,
            currentEmail,
            errorMessage,
            handleInputEmail,
            handleInputType,
            handleSubmit,
            newsletterTypes,
            selectedValues,
            subscribeTask,
        };
    },
});
