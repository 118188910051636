import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { PROJECTS } from '~/constants';
export default defineComponent({
    name: 'Informer',
    props: {
        adfoxArticle: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const withAdvertiser = props.adfoxArticle?.ad?.title || false;
        const label = computed(() => {
            return PROJECTS[props.adfoxArticle.type || 'partner'].title;
        });
        return {
            css,
            label,
            withAdvertiser,
        };
    },
});
