// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DlLPK{position:relative;display:flex;flex-direction:column}.FBKaY{display:flex;flex:1;flex-flow:column}.NQ9SX{display:block;height:100%;flex:1 0 auto;padding:0 16px;margin:0;color:#2d2d2d;font-size:15px;line-height:22px;text-decoration:none;transition:color .2s}.NQ9SX>span{-webkit-line-clamp:var(--waterfall-line-clamp);-webkit-box-orient:vertical;display:block;display:-webkit-box;overflow:hidden;max-height:calc(22px*var(--waterfall-line-clamp))}.NQ9SX:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:rgba(45,45,45,.8)}@media(any-hover:hover){.NQ9SX:hover{color:rgba(45,45,45,.8)}}.POBFe{flex:0 0 auto;padding:24px 16px 8px;margin:0;color:#2d2d2d;font-family:\"Merriweather\",serif;font-size:18px;font-weight:700;line-height:24px}.POBFe>a{color:#2d2d2d;text-decoration:none}.POBFe>a>span{color:#0082ff}.iCFtY{justify-content:space-between;padding:16px 8px 8px 16px}.iCFtY,.pZkDI{display:flex;align-items:center}.pZkDI{padding:0;margin:0;list-style:none}.L1dWq{width:8px;height:8px;border:2px solid transparent;background-clip:content-box;background-color:#767676;border-radius:100%;cursor:pointer}.L1dWq:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#0082ff}@media(any-hover:hover){.L1dWq:hover{background-color:#0082ff}}.L1dWq._5F3Qn{background-color:#101010}.Ahh85{display:flex;align-items:center}.Eblg4{transform:rotate(90deg)}.Eblg4,.pNw8q{color:#767676;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.pNw8q{transform:rotate(-90deg)}.eZNM7{position:absolute;top:0;left:0;display:flex;width:100%;height:100%;align-items:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": "DlLPK",
	"item": "FBKaY",
	"description": "NQ9SX",
	"title": "POBFe",
	"controls": "iCFtY",
	"dots": "pZkDI",
	"dot": "L1dWq",
	"dotActive": "_5F3Qn",
	"buttons": "Ahh85",
	"buttonBackward": "Eblg4",
	"buttonForward": "pNw8q",
	"loader": "eZNM7"
};
module.exports = ___CSS_LOADER_EXPORT___;
