import { CURRENT_YEAR } from '~/constants';
export const CLOUDPAYMENTS_SRC = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';
export const USER_SCOPES = [
    'Бытовое обслуживание',
    'Медицина',
    'Юриспруденция',
    'Промышленность',
    'Малый и средний бизнес',
    'Наука',
    'Культура',
    'Социально-психологическая деятельность',
    'Искусство и прикладное творчество',
    'Информационные системы',
    'Армия и силовые структуры',
    'Морское дело',
    'Транспорт',
    'Государственная служба (чиновники)',
    'Жилищно-коммунальное хозяйство',
    'Сельское хозяйство',
    'Инспекторские службы',
    'Политика и общественная деятельность',
    'Строительство',
    'Электротехника',
    'Финансовая деятельность',
    'Образование и воспитание',
    'Торговля',
    'Менеджмент (управление)',
    'Информационные технологии и связь',
    'Энергетика',
    'Химия и биология',
    'Средства массовой информации',
    'Добыча и переработка ископаемых',
    'Аналитическая деятельность и прогнозирование',
    'Природопользование (экология и охрана)',
    'Администрирование',
    'Туризм, отдых, путешествия',
    'Спорт',
    'Развлекательный и шоу-бизнес',
    'Металлообработка',
    'Лесное хозяйство',
    'Деревообработка',
    'Производство продуктов питания',
    'Моделирование и производство одежды и обуви',
    'Полиграфия и рекламное дело',
    'Общественное питание и ресторанный бизнес',
];
/**
 * Select options for days
 */
export const DAYS_OPTIONS = Array.from({ length: 31 }, (_, i) => {
    return {
        checked: false,
        label: (i + 1).toString(),
        value: (i + 1).toString(),
    };
});
/**
 * Select options for months
 */
export const MONTHS_OPTIONS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
].map((month, i) => {
    return {
        checked: false,
        label: month,
        value: i.toString(),
    };
});
/**
 * Select options for years
 */
export const YEARS_OPTIONS = Array.from({ length: 100 }, (_, i) => {
    return {
        checked: false,
        label: (CURRENT_YEAR - i).toString(),
        value: (CURRENT_YEAR - i).toString(),
    };
});
export const SCANNER_PROFILE_CONTEXT = {
    application: {
        title: 'Отправка заявки',
        text: 'Чтобы получить доступ к сканеру иноагентов и нежелательных организаций:',
        list: ['Заполните форму на предоставление доступа к сканеру.', 'Ожидайте одобрения заявки.'],
    },
    recall: {
        title: 'Заявка отклонена',
        text: 'К сожалению, ваша заявка была отклонена. Вы можете отправить новую заявку.',
    },
    provide: {
        title: 'Заявка принята',
        text: 'Ваша заявка успешно одобрена. Вы можете начать пользоваться сканером. Благодарим за выбор нашего сервиса!',
        button: {
            text: 'Перейти в сканер',
            to: '/scanner',
        },
    },
    waiting: {
        title: 'Заявка на рассмотрении',
        text: 'Ваша заявка принята в обработку. Ожидайте ответа. Мы уведомим вас о результате.',
    },
};
