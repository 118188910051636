import { onMounted, defineComponent, useCssModule, onBeforeUnmount } from '@nuxtjs/composition-api';
import { useModal } from '~/composables';
export default defineComponent({
    name: 'Modal',
    props: {
        align: {
            default: 'center',
            type: String,
            validator(value) {
                return ['start', 'center', 'end'].includes(value);
            },
        },
        justify: {
            default: 'center',
            type: String,
            validator(value) {
                return ['start', 'center', 'end'].includes(value);
            },
        },
        width: {
            default: '',
            type: String,
        },
        withClose: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['close'],
    setup(_, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const modal = useModal();
        function close() {
            emit('close', modal.key);
            modal.close();
        }
        function onKeyUp(event) {
            if (event.key === 'Escape') {
                close();
            }
        }
        onBeforeUnmount(() => {
            window.removeEventListener('keyup', onKeyUp);
        });
        onMounted(() => {
            window.addEventListener('keyup', onKeyUp);
        });
        return {
            css,
            close,
            modal,
        };
    },
});
