import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
export const BANNERS_BY_INDEX = {
    4: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_240x400_1,
        type: 'adfox-banner',
    },
    7: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_1,
        type: 'adfox-partner',
    },
    10: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_2,
        type: 'adfox-partner',
    },
    13: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_240x400_2,
        type: 'adfox-banner',
    },
    16: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_3,
        type: 'adfox-partner',
    },
    19: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_1,
        type: 'adfox-partner',
    },
    22: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_240x400_3,
        type: 'adfox-banner',
    },
    25: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_2,
        type: 'adfox-partner',
    },
    28: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_3,
        type: 'adfox-partner',
    },
};
