/**
 * All menus slugs
 */
export const MENUS_SLUGS = [
    'brandvoice',
    'events',
    'info',
    'main',
    'pollout',
    'projects',
    'rubrics',
    'social',
    'under',
];
/**
 * Menus slugs mobile
 */
export const MENUS_SLUGS_MOBILE = [
    'rubrics',
    'pollout',
    'events',
    'brandvoice',
    'info',
    'social',
    'under',
];
/**
 * Menus slugs desktop
 */
export const MENUS_SLUGS_DESKTOP = ['rubrics', 'main', 'projects', 'events', 'brandvoice'];
/**
 * Menus slugs by breakpoints
 */
export const MENUS_SLUGS_BY_BREAKPOINTS = {
    // Mobile menus
    mobile: MENUS_SLUGS_MOBILE,
    mobileMedium: MENUS_SLUGS_MOBILE,
    mobileWide: MENUS_SLUGS_MOBILE,
    tablet: MENUS_SLUGS_MOBILE,
    desktop: MENUS_SLUGS_MOBILE,
    // Desktop menus
    desktopMedium: MENUS_SLUGS_DESKTOP,
    desktopWide: MENUS_SLUGS_DESKTOP,
};
/**
 * Defines component and attrs for menu items
 */
export function handleMenuItems(items) {
    return (items?.map((item) => {
        const name = item.alias || item.source.name;
        const url = item.source.url;
        return {
            id: item?.id || '',
            title: name,
            component: 'NLink',
            to: url,
            attrs: {
                to: url?.startsWith('/') ? url : '/' + url,
            },
            ...(url?.match(/(https?:)?\/\//g) && {
                component: 'a',
                attrs: {
                    href: url,
                    rel: 'noopener',
                    target: '_blank',
                },
            }),
        };
    }) || []);
}
