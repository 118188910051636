export function sendAnalytics({ excluded, path, referer, title, url } = {}) {
    const options = {
        ...(excluded && excluded.length && { excluded }),
        ...(path && { path }),
        ...(referer && { referer }),
        ...(title && { title }),
        ...(url && { url }),
    };
    this.$googleGtag({
        ...options,
        ...(url && { location: url }),
    });
    this.$amberData(options);
    this.$liveInternet(options);
    this.$mailRu(options);
    this.$yandexMetrika.pageHit(options);
}
