export const RUBRICS = [
    {
        title: 'Миллиардеры',
        to: '/milliardery',
    },
    {
        title: 'Бизнес',
        to: '/biznes',
    },
    {
        title: 'Технологии',
        to: '/tekhnologii',
    },
    {
        title: 'Финансы',
        to: '/finansy',
    },
    {
        title: 'Инвестиции',
        to: '/investicii',
    },
    {
        title: 'Мнения',
        to: '/mneniya',
    },
    {
        title: 'Общество',
        to: '/society',
    },
    {
        title: 'Карьера и свой бизнес',
        to: '/svoi-biznes',
    },
    {
        title: 'Новости компаний',
        to: '/novosti-kompaniy',
    },
    {
        title: 'Продовольственная безопасность',
        to: '/prodovolstvennaya-bezopasnost',
    },
    {
        title: 'F Research',
        to: '/fresearch',
    },
];
/**
 * Base sections
 */
export const SECTIONS = {
    // Рубрики
    milliardery: { id: '19480', title: 'Миллиардеры' },
    biznes: { id: '35777', title: 'Бизнес' },
    tekhnologii: { id: '8', title: 'Технологии' },
    finansy: { id: '5', title: 'Финансы' },
    investicii: { id: '55009', title: 'Инвестиции' },
    mneniya: { id: '2858', title: 'Мнения' },
    society: { id: '44683', title: 'Общество' },
    'svoi-biznes': { id: '7', title: 'Карьера и свой бизнес' },
    'prodovolstvennaya-bezopasnost': { id: '55507', title: 'Продовольственная безопасность' },
    'novosti-kompaniy': { id: '55490', title: 'Новости компаний' },
    fresearch: { id: '55539', title: 'F Research' },
    // Основное меню
    franchises: { id: '55909', title: 'Forbes Франшизы' },
    forbeslife: { id: '57', title: 'ForbesLife' },
    'forbes-woman': { id: '423', title: 'Forbes Woman' },
    sport: { id: '55015', title: 'Forbes Sport' },
    young: { id: '55679', title: 'Forbes Young' },
    // Проекты
    education: { id: '54955', title: 'Forbes Education' },
    // Блоги
    brandvoice: { id: '40097', title: 'Блоги' },
    // Остальное
    'forbes-agenda': { id: '44661', title: 'Деловая Повестка' },
    ontology: { id: '46183', title: 'Ontology' },
    partners: { id: '17038', title: 'Партнерские материалы' },
    spetsproekt: { id: '20665', title: 'Спецпроект' },
    club: { id: '55940', title: 'Club Partners' },
    'club-expertise': { id: '55980', title: 'Club Expertise' },
    'club-practicum': { id: '55981', title: 'Club Practicum' },
    'club-news': { id: '55982', title: 'Club News' },
};
/**
 * Rubrics sections
 */
export const RUBRICS_SECTIONS = createSections(JSON.parse(JSON.stringify(RUBRICS))?.map((item) => item.to?.slice(1)));
/**
 * Special projects sections
 */
export const SPECIAL_SECTIONS = createSections(['brandvoice', 'partners']);
/**
 * Create sections from base list
 * @param keys List of sections keys
 */
function createSections(keys) {
    return Object.entries(SECTIONS).reduce((sections, [key, value]) => {
        if (keys.includes(key)) {
            sections[key] = value;
        }
        return sections;
    }, {});
}
