import { ref } from '@nuxtjs/composition-api';
const changePassword = ref({
    email: '',
    code: '',
});
const recoveryEmail = ref('');
const signUpEmail = ref('');
export function useSign() {
    return {
        get changePassword() {
            return changePassword.value;
        },
        set changePassword(value) {
            changePassword.value = value;
        },
        get recoveryEmail() {
            return recoveryEmail.value;
        },
        set recoveryEmail(value) {
            recoveryEmail.value = value;
        },
        get signUpEmail() {
            return signUpEmail.value;
        },
        set signUpEmail(value) {
            signUpEmail.value = value;
        },
    };
}
