import { computed, watch, defineComponent, useCssModule, ref, onMounted, useContext } from '@nuxtjs/composition-api';
import { debounce } from 'throttle-debounce-ts';
import { useTask } from 'vue-concurrency';
import { useDuck } from '~/services';
import { addUtm } from '~/utils';
import { READ_MORE_TEXTS } from '~/components/Menu/index';
export default defineComponent({
    name: 'Menu',
    props: {
        menu: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Nuxt context.
         */
        const context = useContext();
        /**
         * Viewport.
         */
        const viewport = context.$viewport;
        const isMobile = viewport.isLessThan('desktopMedium');
        /**
         * Duck service.
         */
        const duck = useDuck();
        /**
         * read More Text.
         */
        const readMoreText = computed(() => READ_MORE_TEXTS[props.menu.slug]);
        /**
         * Rubrics.
         */
        const rubrics = props.menu.items.map((item) => {
            return {
                ...addUtm(item, 'header', isMobile),
                withArticles: item.component === 'NLink',
                items: [],
            };
        });
        /**
         * Fetch items task.
         */
        const fetchItemsTask = useTask(function* (signal, rubricKey) {
            const rubric = rubrics[rubricKey];
            if (!rubric) {
                return;
            }
            if (rubric.items.length) {
                return;
            }
            signal.addEventListener('abort', () => duck.abortRequest('getPubTax'));
            try {
                const { data } = yield duck.pub.getPubTax({
                    'list[limit]': 4,
                    'tax[url]': currentRubric.value.to,
                }, {
                    cancelToken: 'getPubTax',
                });
                if (!data) {
                    return;
                }
                rubric.items = (data.articles || []).map(({ title = '', url_alias: url = '' }) => {
                    return {
                        title,
                        to: `/${url}`,
                    };
                });
            }
            catch (error) {
                console.error(error);
            }
        });
        /**
         * Fetch items debounced.
         */
        const fetchItemsDebounced = debounce(250, () => {
            fetchItemsTask.perform(selectedRubric.value);
        });
        /**
         * Selected rubric.
         */
        const selectedRubric = ref(0);
        /**
         * Current rubric.
         */
        const currentRubric = ref(rubrics[selectedRubric.value] || false);
        watch(() => selectedRubric.value, () => {
            currentRubric.value = rubrics[selectedRubric.value];
        });
        const rootMinHeight = computed(() => (currentRubric.value.withArticles ? '400px' : 'auto'));
        function handleMouseEnter(rubricKey) {
            selectedRubric.value = rubricKey;
            if (rubrics[rubricKey].withArticles) {
                fetchItemsDebounced();
            }
        }
        onMounted(() => {
            if (!rubrics[selectedRubric.value]?.withArticles) {
                return;
            }
            fetchItemsTask.perform(selectedRubric.value);
        });
        return {
            currentRubric,
            css,
            fetchItemsTask,
            handleMouseEnter,
            rubrics,
            selectedRubric,
            readMoreText,
            rootMinHeight,
        };
    },
});
