export const RBE_NAV_LINKS = [
    { title: 'Методология', to: 'methodology' },
    { title: 'О рейтинге', to: 'about' },
    { title: 'Участие в рейтинге 2025 года', to: 'participation' },
];
export const SHARE_OPTIONS = [
    // {
    //   url: '//www.facebook.com/sharer/sharer.php?u=',
    //   title: 'Facebook',
    //   hint: 'Поделиться в Facebook',
    // },
    {
        url: '//t.me/share/url?url=',
        title: 'Telegram',
        hint: 'Поделиться в Telegram',
    },
    {
        url: '//vk.com/share.php?url=',
        title: 'Вконтакте',
        hint: 'Поделиться во Вконтакте',
    },
    {
        url: '//connect.ok.ru/offer?url=',
        title: 'Одноклассники',
        hint: 'Поделиться в Одноклассниках',
    },
    {
        url: '//api.whatsapp.com/send?text=',
        title: 'WhatsApp',
        hint: 'Поделиться в WhatsApp',
    },
    {
        url: '//twitter.com/intent/tweet?url=',
        title: 'Twitter',
        hint: 'Поделиться в Twitter',
    },
];
