const ERID_REG_EXP = /(?=.+\d)[a-z\d]{10,}/gi;
/**
 * Parse article erid
 */
export function parseArticleErid(items) {
    let erid = '';
    for (const item of items) {
        if (erid) {
            break;
        }
        for (const value of Object.values(item)) {
            if (!value) {
                continue;
            }
            const match = value.match(ERID_REG_EXP);
            if (match) {
                erid = match[0];
                break;
            }
        }
    }
    return erid;
}
