export const VIDEO_TAGS = {
    'tegi/dorogie-stucki': { id: '55478', title: 'Дорогие штучки', to: 'tegi/dorogie-stucki' },
    'tegi/forbes-biznes-klass': { id: '55614', title: 'Forbes Бизнес-класс', to: 'tegi/forbes-biznes-klass' },
    'tegi/forbes-capital-0': { id: '45799', title: 'Forbes Capital', to: 'tegi/forbes-capital-0' },
    'tegi/forbes-digest-0': { id: '46957', title: 'Forbes Digest', to: 'tegi/forbes-digest-0' },
    'tegi/forbes-glavnoe': { id: '55836', title: 'Forbes.Главное', to: 'tegi/forbes-glavnoe' },
    'tegi/forbes-intervyu': { id: '47035', title: 'Forbes Интервью', to: 'tegi/forbes-intervyu' },
    'tegi/forbes-talk': { id: '46963', title: 'Forbes Talk', to: 'tegi/forbes-talk' },
    'tegi/kuhnya-forbes-0': { id: '46959', title: 'Кухня Forbes', to: 'tegi/kuhnya-forbes-0' },
    'tegi/neforbsy': { id: '55065', title: 'НеФорбсы', to: 'tegi/neforbsy' },
};
export const RATINGS_TAG = { id: '54619', title: 'ForbesРейтинг', to: 'tegi/forbesreyting' };
