import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { EDUCATION_TAGS_REGEXP, PROJECTS } from '~/constants';
import { useWaterfall } from '~/services';
export default defineComponent({
    name: 'TheWaterfallItem',
    props: {
        isActive: {
            type: Boolean,
        },
        item: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * Composables.
         */
        const css = useCssModule();
        const waterfall = useWaterfall();
        /**
         * Link
         */
        const link = computed(() => {
            const nodeType = props.item.node_type;
            let { url = '' } = props.item;
            if (nodeType === 'link' && !url.startsWith('http')) {
                url = 'https://' + url;
            }
            if (url.match(/(https?:)?\/\//g)) {
                return {
                    component: 'a',
                    attrs: {
                        href: `${url}?utm_source=forbes&utm_campaign=lnews`,
                        rel: 'noopener',
                        target: '_blank',
                    },
                };
            }
            return {
                component: 'NLink',
                attrs: {
                    to: `/${url}?utm_source=forbes&utm_campaign=lnews`,
                },
            };
        });
        /**
         * Time
         */
        const time = computed(() => {
            return (props.item.time &&
                new Date(Number(props.item.time) * 1000).toLocaleTimeString('ru', { hour: '2-digit', minute: '2-digit' }));
        });
        /**
         * Formatted sections
         * Pushes parent section if it is not there
         */
        const sections = computed(() => {
            if (!props.item?.sections?.length) {
                return [];
            }
            return (props.item.sections.reduce((list, section, _, array) => {
                if (section?.parent) {
                    const hasRubric = array.findIndex(({ id }) => Number(id) === Number(section.parent?.id)) > -1;
                    if (!hasRubric) {
                        list.push(section.parent);
                    }
                }
                list.push(section);
                return list;
            }, []) || []);
        });
        /**
         * Formatted tags
         * Handles education tags
         */
        const tags = computed(() => {
            if (!props.item?.tags?.length) {
                return [];
            }
            return props.item.tags.map((tag) => {
                tag.name = tag?.name?.replace(EDUCATION_TAGS_REGEXP, '');
                return tag;
            });
        });
        /**
         * Сравнивает две строки друг с другом.
         * @param stringOrRegExp Примтивная строка или регулярка.
         * @param string Примтивная строка.
         */
        function isEquals(stringOrRegExp, string) {
            if (!stringOrRegExp || !string) {
                return false;
            }
            if (stringOrRegExp instanceof RegExp) {
                return stringOrRegExp.test(string);
            }
            return stringOrRegExp === string;
        }
        /**
         * Entries
         */
        const entries = computed(() => {
            const { brandvoice } = props.item;
            const isAd = !(time.value || brandvoice || sections.value.length || tags.value.length);
            const { mode } = waterfall.state;
            const isBasicWaterfall = mode === 'basic';
            let entry = null;
            let title = '';
            let type = '';
            // Обрабатываем рекламу.
            if (isAd) {
                title = props.item?.rubric || 'Спецпроект';
                type = props.item?.type;
                if (type) {
                    title = Object.entries(PROJECTS).find(([key]) => key === type)?.[1]?.title || title;
                }
                else {
                    type = 'partner';
                }
                // Обрабатываем обычные записи вотерфолла.
            }
            else {
                let project;
                // Для базового вотерфолла обрабатываем только брендвоисы и рубрики.
                if (isBasicWaterfall) {
                    if (brandvoice) {
                        // Ищем среди проектов конкретный брендвоис
                        entry = brandvoice;
                        project = Object.entries(PROJECTS).find(([_key, { url }]) => {
                            url = url?.[mode] || url || '';
                            return isEquals(url, entry?.url || '');
                        });
                        if (!project) {
                            // Если среди проектов нет конкретного брендвоиса, сохраняем общие данные
                            const { title: name, url } = PROJECTS.brandvoice;
                            entry = { name, url };
                            project = ['brandvoice', PROJECTS.brandvoice];
                        }
                    }
                    else if (sections.value.length) {
                        // Для базового вотерфолла всегда берем только самую первую рубрику
                        entry = sections.value[0] || {};
                        project = Object.entries(PROJECTS).find(([_key, { url }]) => {
                            url = url?.[mode] || url || '';
                            return isEquals(url, entry?.url || '');
                        });
                    }
                    // Для остальных типов вотерфолла обрабатываем теги и рубрики.
                }
                else {
                    project = Object.entries(PROJECTS).find(([_key, { url = {} }]) => url?.[mode]);
                    const projectUrl = project?.[1]?.url?.[mode] || '';
                    // Выбираем тег из списка тегов, отвечающий условию.
                    // Неочевидное поведение: почему-то иногда сравнивается второй тег, а не первый.
                    let tag = tags.value?.find(({ url }) => isEquals(projectUrl, url || ''));
                    // Магический хак для сохранения первого тега
                    if (!tag && isEquals(projectUrl, tags.value?.[0]?.url || '')) {
                        tag = tags.value[0];
                    }
                    // Для небазового вотерфолла сначала всегда берем вторую рубрику,
                    // т.к. считаем, что первая рубрика - родительская.
                    const section = sections.value?.[1] || sections.value?.[0] || {};
                    if (tag) {
                        entry = tag;
                    }
                    else if (isEquals(projectUrl, section?.url || '')) {
                        entry = section;
                    }
                }
                // Извлекаем заголовок и тип проекта, если есть.
                if (project) {
                    const [projectKey, projectEntry] = project;
                    title = projectEntry.title;
                    type = projectKey;
                }
            }
            // Если есть тип, то считаем это проектом и выводим лейбл.
            if (type) {
                const { name, url } = entry || {};
                const label = {
                    title: name && !isBasicWaterfall ? name : title,
                    type: isBasicWaterfall || isAd ? type : '',
                    ...(url && { url }),
                };
                return [label];
            }
            // Иначе - считаем новостью и выводим время.
            return [time.value];
        });
        return {
            css,
            entries,
            link,
            sections,
            tags,
            time,
            waterfall,
        };
    },
});
