import { computed, defineComponent, useContext, useCssModule } from '@nuxtjs/composition-api';
import { CURRENT_YEAR, FOOTER_FZ_RULES, FOOTER_INFO, FOOTER_SOCIALS } from '~/constants';
export default defineComponent({
    name: 'TheFooter',
    setup() {
        /**
         * Composables.
         */
        const css = useCssModule();
        const context = useContext();
        /**
         * Viewport.
         */
        const viewport = context.$viewport;
        const FreshMagazineDirection = computed(() => {
            return viewport.isGreaterThan('tablet') ? 'row' : 'column';
        });
        const columns = computed(() => {
            return [
                {
                    id: 'info',
                    title: 'Информация:',
                    items: FOOTER_INFO,
                },
                {
                    id: 'social',
                    title: 'Мы в соцсетях:',
                    items: FOOTER_SOCIALS,
                },
            ];
        });
        return {
            CURRENT_YEAR,
            FOOTER_FZ_RULES,
            css,
            columns,
            viewport,
            FreshMagazineDirection,
        };
    },
});
