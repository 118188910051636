import { computed, defineComponent, useContext, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'TheWaterfallFeed',
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const context = useContext();
        const buttonSize = computed(() => {
            if (context.$viewport.isLessThan('tablet')) {
                return 'medium';
            }
            return 'small';
        });
        return {
            buttonSize,
            css,
        };
    },
});
