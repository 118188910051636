import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'AdfoxAdvertiser',
    props: {
        article: {
            required: true,
            type: Object,
        },
        tooltip: {
            default: false,
            type: [Boolean, Object],
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Computed property for advertiser details.
         * @returns {object|boolean} - The advertiser details or false if title is missing.
         */
        const advertiser = computed(() => {
            const { title, to } = props.article?.ad || {};
            if (!title) {
                return false;
            }
            let domain = '';
            if (to) {
                domain = to.replace(/(https?:)?\/\/(www\.)?/g, '').replace(/\/$/, '');
            }
            return {
                domain,
                title,
                to: to || props.article.path,
            };
        });
        return {
            advertiser,
            css,
        };
    },
});
