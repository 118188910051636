import { computed, defineComponent, onBeforeUnmount, onMounted, ref, useCssModule, useRoute, watch, watchEffect, } from '@nuxtjs/composition-api';
import { throttle } from 'throttle-debounce-ts';
import { BANNERS_BY_INDEX } from './';
import { useBodyLocker } from '~/composables';
import { useWaterfall } from '~/services';
export default defineComponent({
    name: 'TheWaterfall',
    setup() {
        /**
         * Composables.
         */
        const bodyLocker = useBodyLocker();
        const css = useCssModule();
        const route = useRoute();
        const waterfall = useWaterfall();
        /**
         * Active time interval.
         */
        const activeTimeInterval = ref('');
        /**
         * Container element.
         */
        const containerEl = ref();
        const currentLocation = ref('');
        /**
         * Intervals elements.
         */
        const intervalsElements = ref([]);
        /**
         * Intervals offsets
         */
        const intervalsOffsets = ref([]);
        const lastScroll = ref(0);
        const currentStyle = computed(() => {
            if (!bodyLocker.isFixed) {
                return '';
            }
            return {
                marginTop: `${bodyLocker.lastScroll}px`,
            };
        });
        const isEducation = computed(() => {
            return route.value.path.startsWith('/education') || route.value.path.startsWith('/tegi/education');
        });
        const currentTitle = computed(() => {
            let result = 'Новости';
            if (waterfall.state.mode === 'education' || isEducation.value) {
                result = 'Образование';
            }
            if (waterfall.state.mode === 'sport' || route.value.path.startsWith('/sport')) {
                result = 'Forbes Sport';
            }
            return result;
        });
        /**
         * Time intervals.
         */
        const timeIntervals = computed(() => {
            const intervals = {};
            const { items } = waterfall.state;
            const [firstIndex] = Object.keys(BANNERS_BY_INDEX);
            let bannersByIndex = JSON.parse(JSON.stringify(BANNERS_BY_INDEX));
            let lastIndex = 0;
            for (let i = 0, l = items.length; i < l; i++) {
                const intervalKey = new Intl.DateTimeFormat('ru').format(Number(items[i].time) * 1000);
                if (Array.isArray(intervals[intervalKey])) {
                    intervals[intervalKey].push(items[i]);
                }
                else {
                    intervals[intervalKey] = [items[i]];
                }
                // Add partner banners
                for (const key in bannersByIndex) {
                    const index = parseInt(key, 10);
                    if (index === parseInt(firstIndex, 10)) {
                        bannersByIndex[key].adfoxConfig.shouldSyncSession = true;
                    }
                    if (index === i) {
                        intervals[intervalKey].splice(i, 0, bannersByIndex[key]);
                    }
                    lastIndex = index;
                }
                if (i <= lastIndex) {
                    continue;
                }
                bannersByIndex = Object.entries(bannersByIndex).reduce((banners, [bannerIndex, banner]) => {
                    const index = parseInt(bannerIndex, 10);
                    banners[index] = banner;
                    banners[index + lastIndex] = banner;
                    return banners;
                }, {});
            }
            return intervals;
        });
        const handleScroll = throttle(250, (event) => {
            const offsets = intervalsOffsets.value;
            if (!offsets.length) {
                return;
            }
            const target = event.target;
            const scrollTop = target.scrollTop;
            const firstOffset = offsets[0];
            if (scrollTop <= firstOffset.value) {
                activeTimeInterval.value = firstOffset.key;
                return;
            }
            const lastOffset = offsets[offsets.length - 1];
            if (scrollTop >= lastOffset.value) {
                activeTimeInterval.value = lastOffset.key;
                return;
            }
            for (const offset of offsets) {
                if (offset.value < scrollTop) {
                    continue;
                }
                activeTimeInterval.value = offset.key;
                return;
            }
        });
        function onHistoryUpdate() {
            currentLocation.value = location.pathname.substr(1);
        }
        /**
         * Sync scroll with the window.
         */
        function syncScroll() {
            const currentScroll = window.scrollY;
            if (containerEl.value) {
                containerEl.value.scrollTop += currentScroll - lastScroll.value;
            }
            lastScroll.value = currentScroll;
        }
        onBeforeUnmount(() => {
            window.removeEventListener('scroll', syncScroll);
            window.removeEventListener('historyupdate', onHistoryUpdate);
        });
        onMounted(() => {
            window.addEventListener('scroll', syncScroll);
            window.addEventListener('historyupdate', onHistoryUpdate);
            watchEffect(() => {
                currentLocation.value = route.value.path.substr(1);
            });
        });
        watch(intervalsElements, (elements) => {
            intervalsOffsets.value = elements.reduce((acc, element) => {
                acc.push({
                    key: element.dataset.interval,
                    value: element.offsetTop + element.offsetHeight,
                });
                return acc;
            }, []);
            if (!activeTimeInterval.value) {
                activeTimeInterval.value = intervalsOffsets.value[0].key;
            }
        });
        return {
            activeTimeInterval,
            bodyLocker,
            containerEl,
            css,
            currentLocation,
            currentStyle,
            currentTitle,
            handleScroll,
            intervalsElements,
            intervalsOffsets,
            isEducation,
            lastScroll,
            timeIntervals,
            waterfall,
        };
    },
});
