import { defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'SberSpeech',
    props: {
        eventDetail: {
            default: () => ({}),
            type: Object,
            required: true,
        },
        currentVolume: {
            type: Object,
            required: true,
        },
        playbackRate: {
            default: 1,
            type: Number,
        },
        nextTitle: {
            default: '',
            type: String,
        },
        prevTitle: {
            default: '',
            type: String,
        },
        isPrevList: {
            default: 0,
            type: Number,
        },
        isWaitLoadSwitchArticle: {
            default: false,
            type: Boolean,
        },
        shareUrl: {
            default: '',
            type: String,
        },
        isPlaying: {
            default: false,
            type: Boolean,
        },
        isChunkLoading: {
            default: false,
            type: Boolean,
        },
        hasShowPopup: {
            default: false,
            type: Boolean,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const isShowPopup = ref(props.hasShowPopup);
        const isPrevArticle = ref(false);
        function showPopup(isPrev) {
            if (isPrev && !props.isPrevList)
                return;
            isPrevArticle.value = isPrev;
            isShowPopup.value = true;
        }
        return {
            css,
            isShowPopup,
            isPrevArticle,
            showPopup,
        };
    },
});
