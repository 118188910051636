import { computed, defineComponent, onMounted, onBeforeUnmount, useCssModule, ref } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from '.';
export default defineComponent({
    name: 'IntersectionObserver',
    props: {
        options: {
            default: () => {
                return {};
            },
            type: Object,
        },
    },
    emits: ['intersect'],
    setup(props, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        const observer = ref();
        const rootEl = ref();
        onMounted(() => {
            setTimeout(() => {
                observer.value = new IntersectionObserver(([entry], observer) => {
                    if (entry && entry.isIntersecting) {
                        emit('intersect', { entry, observer });
                    }
                }, currentOptions.value);
                observer.value?.observe(rootEl.value);
            });
        });
        onBeforeUnmount(() => {
            observer.value?.disconnect();
        });
        return {
            css,
            currentOptions,
            observer,
            rootEl,
        };
    },
});
