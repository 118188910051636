import { ADFOX_CODES_DESKTOP, ADFOX_CODES_MOBILE } from '~/components/Adfox';
export const BANNERS_BY_INDEX = {
    4: {
        adfoxConfig: ADFOX_CODES_MOBILE['300x250_1'],
        type: 'adfox-banner',
    },
    8: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_1,
        type: 'adfox-partner',
    },
    12: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_2,
        type: 'adfox-partner',
    },
    16: {
        adfoxConfig: ADFOX_CODES_MOBILE['300x250_2'],
        type: 'adfox-banner',
    },
    20: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_3,
        type: 'adfox-partner',
    },
    24: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_1,
        type: 'adfox-partner',
    },
    28: {
        adfoxConfig: ADFOX_CODES_MOBILE['300x250_3'],
        type: 'adfox-banner',
    },
    32: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_2,
        type: 'adfox-partner',
    },
    36: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_3,
        type: 'adfox-partner',
    },
    40: {
        adfoxConfig: ADFOX_CODES_MOBILE['300x250_4'],
        type: 'adfox-banner',
    },
    44: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_1,
        type: 'adfox-partner',
    },
    48: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_2,
        type: 'adfox-partner',
    },
    52: {
        adfoxConfig: ADFOX_CODES_MOBILE['300x250_5'],
        type: 'adfox-banner',
    },
    56: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_3,
        type: 'adfox-partner',
    },
    60: {
        adfoxConfig: ADFOX_CODES_DESKTOP.waterfall_1,
        type: 'adfox-partner',
    },
};
