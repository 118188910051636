import { defineComponent, h } from '@nuxtjs/composition-api';
import Vue from 'vue';
const DEFAULT_SIZE = 16;
const SvgIcon = defineComponent({
    name: 'SvgIcon',
    props: {
        name: {
            required: true,
            type: String,
        },
        /**
         * Webpack resource query provided by @nuxtjs/svg
         * @see {@link https://github.com/nuxt-community/svg-module}
         */
        resourceQuery: {
            default: 'sprite',
            type: String,
            validator: (value) => {
                return ['inline', 'sprite'].includes(value);
            },
        },
    },
    setup(props, { attrs }) {
        let component;
        let rootAttrs = {};
        try {
            if (props.resourceQuery === 'sprite') {
                component = require(`~/assets/icons/${props.name}.svg?sprite`).default;
                if (component) {
                    const [, , width, height] = component?.viewBox?.split(' ') || [];
                    rootAttrs = {
                        ...attrs,
                        'aria-hidden': attrs['aria-hidden'] || true,
                        height: attrs.height || height || DEFAULT_SIZE,
                        width: attrs.width || width || DEFAULT_SIZE,
                    };
                }
            }
            else if (props.resourceQuery === 'inline') {
                component = require(`~/assets/icons/${props.name}.svg?inline`).default;
            }
        }
        catch (error) {
            console.error('SvgIcon', error);
        }
        if (props.resourceQuery === 'sprite') {
            return () => h('svg', { attrs: rootAttrs }, [h('use', { attrs: { 'xlink:href': `#${component?.id}` } })]);
        }
        return h(component);
    },
});
Vue.component(SvgIcon.name, SvgIcon);
