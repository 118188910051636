import { computed, defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { useA11y } from '~/composables';
export default defineComponent({
    name: 'CopyLink',
    inheritAttrs: false,
    props: {
        url: {
            default: '',
            type: String,
        },
        withText: {
            default: true,
            type: Boolean,
        },
        withTextTag: {
            default: 'BaseButton',
            type: String,
        },
        theme: {
            default: 'light',
            type: String,
            validator(value) {
                return ['a11y', 'light', 'dark'].includes(value);
            },
        },
    },
    setup(props) {
        /**
         * Accessibility
         */
        const a11y = useA11y();
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const buttonThemesMap = {
            a11y: 'a11y',
            light: 'transparent',
            dark: 'dark-alternate',
        };
        const rootEl = ref();
        const timeout = ref(0);
        const isCopied = ref(false);
        const copiedText = computed(() => (isCopied.value ? 'Скопировано' : 'Копировать ссылку'));
        const currentUrl = computed(() => {
            if (props.url) {
                return props.url;
            }
            return location.href;
        });
        async function copy() {
            window.clearTimeout(timeout.value);
            let isSuccessed = false;
            try {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(currentUrl.value);
                    isSuccessed = true;
                }
                else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
                    // Create textarea and pass the url into it
                    const textArea = document.createElement('textarea');
                    textArea.value = currentUrl.value;
                    // Avoid scrolling to bottom
                    textArea.style.position = 'fixed';
                    textArea.style.left = '-9999px';
                    textArea.style.pointerEvents = 'none';
                    // Append in body and select
                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();
                    // Copy value
                    document.execCommand('copy');
                    isSuccessed = true;
                    // Kill
                    document.body.removeChild(textArea);
                }
                else {
                    return console.error('[there are no clipboard in navigator and execCommand in document]');
                }
            }
            catch (e) {
                console.error('[copy to clipboard error]:', e);
            }
            if (isSuccessed) {
                isCopied.value = true;
            }
            timeout.value = window.setTimeout(() => {
                isCopied.value = false;
            }, 1000);
        }
        return {
            a11y,
            buttonThemesMap,
            copiedText,
            copy,
            css,
            currentUrl,
            isCopied,
            rootEl,
            timeout,
        };
    },
});
