// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uLCvA{display:flex;min-height:var(--root-height);justify-content:flex-end;padding:24px 64px 40px 0;margin-left:auto}._5rcnC,.PkLf0{padding:0;margin:0;list-style:none}._5rcnC{position:absolute;top:0;left:0;width:100%;height:100%}.t\\+OJ\\+{display:flex;width:240px;align-items:center;justify-content:space-between;padding:8px 0;border-right:1px solid #2d2d2d;font-size:14px;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;line-height:24px}.t\\+OJ\\+:first-child{padding-top:0}.t\\+OJ\\+:last-child{padding-bottom:0}.t\\+OJ\\+:after{width:1px;height:24px;margin-right:-1px;background-color:#f4e600;content:\"\";opacity:0;transition:opacity .2s}.t\\+OJ\\+.Gr\\+2f:after{opacity:1}.yQ-LI{padding-right:10px;color:#989898;text-decoration:none;transition:color .2s}.Gr\\+2f .yQ-LI{color:#fff}.BIJxC{display:flex;width:534px;flex-direction:column;justify-content:space-between;padding-left:40px;font-size:16px;line-height:24px}.AQgrV:not(:last-child){margin-bottom:32px}._94\\+Ht{color:#989898;text-decoration:none;transition:color .2s}._94\\+Ht:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#fff}@media(any-hover:hover){._94\\+Ht:hover{color:#fff}}.THsRX{position:absolute;top:0;left:0;display:flex;width:100%;height:100%;align-items:center;justify-content:center}.\\+\\+wie{position:relative;flex:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "uLCvA",
	"newsList": "_5rcnC",
	"rubrics": "PkLf0",
	"rubric": "t+OJ+",
	"isActive": "Gr+2f",
	"rubricLink": "yQ-LI",
	"news": "BIJxC",
	"newsItem": "AQgrV",
	"newsLink": "_94+Ht",
	"loader": "THsRX",
	"newsContent": "++wie"
};
module.exports = ___CSS_LOADER_EXPORT___;
