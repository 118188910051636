import { render, staticRenderFns } from "./TheWaterfallMobile.vue?vue&type=template&id=6c5b09cd&"
import script from "./TheWaterfallMobile.vue?vue&type=script&lang=ts&"
export * from "./TheWaterfallMobile.vue?vue&type=script&lang=ts&"
import style0 from "./TheWaterfallMobile.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./TheWaterfallMobile.vue?vue&type=style&index=1&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/var/www/src/components/IconButton/IconButton.vue').default,LinesLoader: require('/var/www/src/components/LinesLoader/LinesLoader.vue').default,TheCarouselMobile: require('/var/www/src/components/TheCarouselMobile/TheCarouselMobile.vue').default,EducationSubscription: require('/var/www/src/components/EducationSubscription/EducationSubscription.vue').default,TheWaterfallFeed: require('/var/www/src/components/TheWaterfallFeed/TheWaterfallFeed.vue').default,TheWaterfallAdfox: require('/var/www/src/components/TheWaterfallAdfox/TheWaterfallAdfox.vue').default,TheWaterfallItemAdfox: require('/var/www/src/components/TheWaterfallItemAdfox/TheWaterfallItemAdfox.vue').default,TheWaterfallItem: require('/var/www/src/components/TheWaterfallItem/TheWaterfallItem.vue').default,MicroLoader: require('/var/www/src/components/MicroLoader/MicroLoader.vue').default,IntersectionObserver: require('/var/www/src/components/IntersectionObserver/IntersectionObserver.vue').default})
