import { computed, defineComponent, onBeforeUnmount, onMounted, ref, useContext, useRouter, watch, } from '@nuxtjs/composition-api';
import { useTask } from 'vue-concurrency';
import { useAudioChunks } from '~/composables';
import { useDuck } from '~/services';
export default defineComponent({
    name: 'SpeechPlayer',
    props: {
        eventDetail: {
            default: () => ({}),
            type: Object,
            required: true,
        },
        volume: {
            type: Object,
            required: true,
        },
        audioVolume: {
            default: 1,
            type: Number,
        },
        playbackRate: {
            default: 1,
            type: Number,
        },
        isPlaying: {
            default: false,
            type: Boolean,
        },
        isMobileFolder: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['close', 'toggle-volume', 'volume-input', 'change-speed', 'folded', 'update-detail'],
    setup(props, { emit }) {
        /**
         * Context.
         */
        const context = useContext();
        /**
         * Environment variables.
         */
        const env = context.env;
        const ym = context.$yandexMetrika;
        /**
         * Router.
         */
        const router = useRouter();
        /**
         * Duck service.
         */
        const duck = useDuck();
        /**
         * Article data.
         */
        const currentArticle = ref(props.eventDetail);
        const nextArticle = ref();
        const prevArticleList = ref([]);
        const prevTitle = ref('');
        const nextTitle = ref('');
        /**
         * Audio data.
         */
        const audio = computed(() => useAudioChunks(currentArticle.value.url_alias || ''));
        const shareUrl = computed(() => {
            const path = currentArticle.value.url_alias || currentArticle.value.path;
            return env.HOST + '/' + path;
        });
        const isCurrentPlaying = ref(audio.value.isPlaying);
        const currentVolume = ref(props.volume);
        const currentPlaybackRate = computed(() => props.playbackRate);
        const chunkCounter = ref(audio.value.bufferLength);
        const fetchAudioTask = audio.value.audioSpeechTask;
        /**
         * State data.
         */
        const blocks = ref();
        const isChunkLoading = ref(false);
        const isWaitLoadSwitchArticle = ref(false);
        const hasShowPopup = ref(false);
        const isWhileBreak = ref(true);
        const prevNextInitTimeout = ref();
        /**
         * Если запуск следующей статьи был из SpeechButton
         * Останавливаем воспроизведение, запускаем лоадер
         * Подменяем новый материал в nextArticle
         * Запускаем следующую статью
         */
        watch(() => props.eventDetail, (newArticle) => {
            if (isCurrentPlaying.value) {
                audio.value.playPause();
                isCurrentPlaying.value = audio.value.isPlaying;
            }
            isWaitLoadSwitchArticle.value = true;
            nextArticle.value = newArticle;
            handleSwitchArticle('next');
        });
        /**
         * При изменении аудио обновляем:
         * Слушатели, получаем заголовок следующего материала
         * Получаем количество чанков из буфера на случай если чанки дорожки уже были в буфере
         * Останавливаем цикл запросов api текущих аудио чанков
         */
        watch(() => audio.value, () => {
            updateNextArticleAndTitle();
            chunkCounter.value = audio.value.bufferLength;
            isWhileBreak.value = true;
            audio.value.on('update', (audio) => {
                isCurrentPlaying.value = !audio.paused;
            });
            audio.value.volume = currentVolume.value.current;
            audio.value.playbackRate = currentPlaybackRate.value;
            audio.value.onStatus('updateStatus', (audio, isWaitLoadChunk) => {
                isChunkLoading.value = isWaitLoadChunk;
            });
            audio.value.onTrackIsOver('track-is-over', () => {
                ym.reachGoal('100%');
                handleSwitchArticle('next', 1500);
            });
        });
        /**
         * Обновляем скоростью воспроизведения в audio
         */
        watch(() => props.playbackRate, (newValue) => {
            audio.value.playbackRate = newValue;
        });
        /**
         * Управление громкостью воспроизведения
         */
        watch(() => props.audioVolume, (newValue) => {
            audio.value.volume = newValue;
        });
        /**
         * Если плей/пауза были от пользователя то отпрвляем статистику
         */
        function playPause(event = null) {
            if (event) {
                ym.reachGoal('click_start');
            }
            audio.value.playPause();
        }
        /**
         * Останавливаем воспроизведение, отправляем статистику и закрываем
         */
        function close() {
            if (audio.value.isPlaying) {
                audio.value.playPause();
            }
            ym.reachGoal('click_close');
            emit('close');
        }
        /**
         * Начать воспроизведение сначала
         */
        function restart() {
            ym.reachGoal('click_repear');
            audio.value.restart();
        }
        /**
         * Вкл/выкл звука
         */
        function toggleVolume() {
            emit('toggle-volume');
        }
        /**
         * Управление скоростью воспроизведения
         */
        function handlePlaybackRate() {
            const rate = currentPlaybackRate.value === 1 ? 1.5 : 1;
            ym.reachGoal('click_speed');
            emit('change-speed', rate);
        }
        /**
         * Отправляем статистику при переходе на сайт сберспича
         */
        function handleLogo() {
            ym.reachGoal('click_logo');
        }
        /**
         * Вернуться к воспроизводимому материалу, скоролингом если на текущей странице
         * Через роут если пользователь ушел с страницы
         */
        function goToCurrentArticle() {
            if (router.currentRoute.path === '/' + currentArticle.value.url_alias) {
                window.scrollTo(0, 0);
            }
            else {
                router.push('/' + currentArticle.value.url_alias);
            }
        }
        /**
         * Получаем с бека количество текстовых блоков материала
         * Нужно для количества запросов за чанками и чтоб получить полностью скаченную дорожку
         */
        const fetchBlocksCount = useTask(function* () {
            return duck.pub
                .getPubMarusiaV1Blocks({ document_id: currentArticle.value.id })
                .then((response) => response)
                .then(({ data }) => {
                return data.meta.document.block_total || 0;
            })
                .catch((e) => console.log(e));
        });
        /**
         * Обновляем в локальном стейте количество блоков либо из аудио стейта либо из запроса
         */
        async function updateBlocksValue() {
            const count = audio.value.blocksCount || (await fetchBlocksCount.perform());
            audio.value.blocksCount = blocks.value = count;
        }
        /**
         * Запрос следующего материала
         * И получение его заголовка
         */
        async function updateNextArticleAndTitle() {
            nextArticle.value = await fetchArticle(currentArticle.value.next_url);
            nextTitle.value = nextArticle.value.title;
        }
        async function fetchArticle(url) {
            const { data } = await duck.pub.getPubArticleByAlias({
                url_alias: url,
            });
            return data;
        }
        /**
         * Переключение на следующую или предыдущую аудио дорожку
         * Останавливаем воспроизведение, сбрасываем интервалы и Cancel запросы, обновляем title для тултипа
         * Если идем на предыдущий материал title и url берем из списка, если следующий то из запроса
         * включаем лоадер, получаем url_alias для запроса и роута, обновляем currentArticle
         * через 1 сек запускам инициализацию аудио и отключаем лоадер
         */
        async function handleSwitchArticle(to, timeout = 1000) {
            if (to === 'back') {
                if (!prevArticleList.value.length)
                    return;
            }
            let nextUrl = '/';
            if (isCurrentPlaying.value) {
                audio.value.playPause();
            }
            audioReset();
            hasShowPopup.value = false;
            isWaitLoadSwitchArticle.value = true;
            ym.reachGoal(`click_${to}`);
            if (to === 'next') {
                prevTitle.value = currentArticle.value.title || '';
                prevArticleList.value.push({ title: currentArticle.value.title, url: currentArticle.value.url_alias });
                nextUrl += nextArticle.value.url_alias;
                currentArticle.value = nextArticle.value;
            }
            else {
                const { url } = prevArticleList.value.pop();
                nextUrl += url;
                prevTitle.value = prevArticleList.value[prevArticleList.value.length - 1]?.title || 'Отсутствует';
                currentArticle.value = await fetchArticle(url);
            }
            await router.push(nextUrl);
            prevNextInitTimeout.value = setTimeout(() => {
                initAudio();
                isWaitLoadSwitchArticle.value = false;
            }, timeout);
        }
        /**
         * Для подгрузки и запуска первого чанка или если плеер был закрыт и открыт а буфер пуст
         */
        async function fetchAndRunAudioChunk() {
            try {
                const isAudioInit = audio.value.audioInit;
                audio.value.initAudioListeners();
                isChunkLoading.value = true;
                const buffer = await fetchAudioTask.perform(currentArticle.value.id, chunkCounter.value);
                audio.value.addAudioChunk(buffer);
                await audio.value.initAudioChunk();
                if (!isAudioInit) {
                    playPause();
                }
                chunkCounter.value += 1;
                isChunkLoading.value = false;
            }
            catch (e) { }
        }
        /**
         * Инициализации аудио:
         * 1) Если ли буфер полностью заполнен чанками - запускаем воспроизведение из буфера
         * 2) Если буфер пуст - получаем из запроса и запускаем
         * 3) Если буфер не пуст, но и не полностью заполнен - запускам плей
         * При 2 и 3 сценарии запускаем цикл запросов с текущего чанка и заполняем буфер ими
         */
        async function initAudio() {
            await updateBlocksValue();
            if (audio.value.bufferLength === blocks.value) {
                await audio.value.runAudioFromBuffer();
                return;
            }
            if (!audio.value.bufferLength) {
                await fetchAndRunAudioChunk();
            }
            else if (!isCurrentPlaying.value) {
                playPause();
            }
            while (chunkCounter.value < blocks.value && isWhileBreak.value) {
                try {
                    const buffer = await fetchAudioTask.perform(currentArticle.value.id, chunkCounter.value);
                    audio.value.addAudioChunk(buffer);
                    chunkCounter.value += 1;
                    await new Promise((resolve) => setTimeout(resolve, 500));
                }
                catch (e) {
                    if (e === 'cancel') {
                        return;
                    }
                }
            }
        }
        /**
         * Прерываем запросы
         * Cancel текущий запрос
         * чистим timeout если там есть инициализация нового аудио
         */
        function audioReset() {
            clearTimeout(prevNextInitTimeout.value);
            audio.value.resetInterval();
            isWhileBreak.value = false;
            fetchAudioTask.cancelAll();
        }
        /**
         * Список событий для управления с клавиатуры
         */
        const speechEvents = new Map([
            ['playerEnter', playPause],
            ['playerEsc', close],
            ['playerLeft', handleEventSwitchArticle],
            ['playerRight', handleEventSwitchArticle],
            ['playerBackspace', restart],
        ]);
        /**
         * Определяем какая клавиша нажата и запускаем handleSwitchArticle.
         */
        function handleEventSwitchArticle(event) {
            const to = event.type === 'playerLeft' ? 'back' : 'next';
            handleSwitchArticle(to);
        }
        /**
         * При монтировании компонента запускам инициализацию, получаем следующий материал
         * Отправляем статистику, запускам слушатели для управления с клавиатуры
         */
        onMounted(() => {
            initAudio();
            updateNextArticleAndTitle();
            ym.reachGoal('start');
            speechEvents.forEach((fn, event) => {
                window.addEventListener(event, fn);
            });
        });
        onBeforeUnmount(() => {
            audioReset();
            speechEvents.forEach((fn, event) => {
                window.removeEventListener(event, fn);
            });
        });
        return {
            handleSwitchArticle,
            close,
            restart,
            handleLogo,
            toggleVolume,
            playPause,
            handlePlaybackRate,
            goToCurrentArticle,
            currentArticle,
            isChunkLoading,
            isCurrentPlaying,
            hasShowPopup,
            isWaitLoadSwitchArticle,
            prevArticleList,
            nextTitle,
            prevTitle,
            shareUrl,
            currentVolume,
            currentPlaybackRate,
        };
    },
});
