// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oo659,.QcnRn{width:100%;height:100%}.oo659{position:relative;display:flex;flex-flow:column;justify-content:space-between;background-color:rgba(24,23,22,.4);transition:background-color .2s}.QcnRn:active .oo659{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:rgba(24,23,22,.25)}@media(any-hover:hover){.QcnRn:hover .oo659{background-color:rgba(24,23,22,.25)}}.DfKlz{display:block;width:100%;height:100%;padding:16px}.r5u3d .DfKlz{padding-bottom:8px}.QcnRn ._0LrAE{position:absolute;width:100%;height:100%}.QcnRn ._0LrAE:after{content:none}.zm\\+c2{position:relative;display:flex;width:100%;height:100%;flex-flow:column;justify-content:space-between}.\\+sVV5{align-self:flex-start;padding:0 8px 2px;border-radius:2px;font-size:13px;line-height:18px}.\\+sVV5.dpMoS{background-color:#0082ff;color:#fff}.\\+sVV5.Gc0K2{background-color:#f02d37;color:#fff}.\\+sVV5.Zqens{background-color:#ffa00a;color:#181716}.A8OEn{color:#fff;font-size:15px;line-height:20px}.QcnRn .PDrfP{display:block;padding:0 16px 16px;color:hsla(0,0%,100%,.85);font-size:13px;line-height:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"informer": "oo659",
	"root": "QcnRn",
	"main": "DfKlz",
	"withAdvertaiser": "r5u3d",
	"image": "_0LrAE",
	"content": "zm+c2",
	"rubric": "+sVV5",
	"brandvoice": "dpMoS",
	"partner": "Gc0K2",
	"special": "Zqens",
	"title": "A8OEn",
	"advertiser": "PDrfP"
};
module.exports = ___CSS_LOADER_EXPORT___;
