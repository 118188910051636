import { parse as parseHTML } from 'node-html-parser';
const REGEX_HOST_FORBES = /(https?:)?\/\/(www)?\.?forbes\.ru/;
const REGEX_HOST_ADMIN = /(https?:)?\/\/(www)?\.?admin.forbes\.ru/;
const REGEX_HTTPS = /(https?:)?\/\//;
/**
 * Parse links in <a> and <img> tags
 */
export function parseLinksInString(string, flink) {
    if (!string) {
        return '';
    }
    const html = process.server ? parseHTML(string) : new DOMParser().parseFromString(string, 'text/html');
    html.querySelectorAll('a').forEach((anchor) => {
        const href = anchor.getAttribute('href') || '';
        const hasImage = Array.from(anchor?.childNodes).some((node) => node?.nodeName === 'IMG');
        if (anchor.parentNode?.childNodes?.length === 1 && !hasImage) {
            anchor.setAttribute('data-single', '');
        }
        if (REGEX_HOST_FORBES.test(href)) {
            anchor.setAttribute('href', href.replace(REGEX_HOST_FORBES, ''));
        }
        else if (REGEX_HTTPS.test(href)) {
            anchor.setAttribute('rel', 'nofollow noopener noreferrer');
            anchor.setAttribute('target', '_blank');
        }
        if (!flink) {
            return;
        }
        const id = href.slice(1);
        const profile = flink[id];
        if (!profile) {
            return;
        }
        const { ratingTop200 } = profile;
        if (ratingTop200 && Object.keys(ratingTop200 || {}).length) {
            const innerText = anchor.innerText.trim();
            anchor.innerHTML = `<!--flink:${id}:${innerText}-->`;
        }
    });
    html.querySelectorAll('img').forEach((image) => {
        let src = image.getAttribute('src') || '';
        if (REGEX_HOST_ADMIN.test(src)) {
            src = src.replace(REGEX_HOST_ADMIN, 'https://cdn.forbes.ru');
        }
        else if (src.startsWith('/')) {
            src = `https://cdn.forbes.ru/${src.substr(1)}`;
        }
        image.setAttribute('src', src);
    });
    return (process.server ? html.toString() : html.body.innerHTML).replace(/\n/g, '<br>');
}
/**
 * Enables router navigation for all links in target DOM element
 */
export function enableRouterForDOMLinks(props) {
    const { target, router } = props;
    if (!target) {
        return;
    }
    target.querySelectorAll('a').forEach((link) => {
        const { pathname } = link;
        const hrefAttr = link.getAttribute('href');
        if (hrefAttr?.startsWith('/')) {
            return link.addEventListener('click', (event) => {
                event.preventDefault();
                router.push(pathname);
            });
        }
    });
}
export function addQueryParamForUrl(url, params) {
    if (!url || process.server) {
        return '';
    }
    // TODO add URL.canParse(url) with polyfill
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/canParse_static
    const queryParams = new URL(url).searchParams;
    if (!('size' in queryParams)) {
        return '';
    }
    if (queryParams.has(params.name)) {
        return '';
    }
    const separator = queryParams.size ? '&' : '?';
    return `${separator}${encodeURIComponent(params.name)}${params.value ? '=' + encodeURIComponent(params.value) : ''}`;
}
/**
 * add UTM for links in <a>
 * @param string
 * @param utm: { name: string; value?: string }
 */
export function addUtmForLinksInString(string, utm) {
    if (!string) {
        return '';
    }
    const html = process.server ? parseHTML(string) : new DOMParser().parseFromString(string, 'text/html');
    html.querySelectorAll('a').forEach((link) => {
        const href = link.getAttribute('href') || '';
        const queryParam = addQueryParamForUrl(href, utm);
        if (REGEX_HOST_FORBES.test(href)) {
            link.setAttribute('href', href.replace(REGEX_HOST_FORBES, ''));
        }
        link.setAttribute('href', href.replace(href, `${href}${queryParam}`));
    });
    return (process.server ? html.toString() : html.body.innerHTML).replace(/\n/g, '<br>');
}
