import { computed, defineComponent, onBeforeUnmount, onMounted, onServerPrefetch, ref, useCssModule, useMeta, watch, } from '@nuxtjs/composition-api';
import { decode, isBlurhashValid } from 'blurhash';
import { EMPTY_IMAGE_DATA } from '.';
import { useImageFormat, useImageHashStore, useImageObserver } from '~/composables';
let canvas;
export default defineComponent({
    name: 'BaseImage',
    props: {
        alt: {
            default: '',
            type: String,
        },
        blurFull: {
            type: Boolean,
        },
        customRatio: {
            default: 0,
            type: Number,
        },
        image: {
            default: () => ({}),
            type: Object,
        },
        isBlurhashEnabled: {
            default: true,
            type: Boolean,
        },
        isLazy: {
            default: true,
            type: Boolean,
        },
        preload: {
            default: () => ({}),
            type: Object,
        },
        schema: {
            default: () => ({}),
            type: Object,
        },
        shouldCrop: {
            default: true,
            type: Boolean,
        },
        cropMethod: {
            default: 'cover',
            type: String,
        },
        shouldUseWebp: {
            default: true,
            type: Boolean,
        },
        height: {
            default: undefined,
            type: Number,
        },
        width: {
            default: undefined,
            type: Number,
        },
        fetchPriority: {
            default: 'auto',
            type: String,
        },
    },
    setup(props) {
        /**
         * Composables.
         */
        const css = useCssModule();
        const imageFormat = useImageFormat();
        const imageHashStore = useImageHashStore();
        const imageObserver = useImageObserver();
        const meta = useMeta();
        /**
         * Image source.
         */
        const sources = ref({
            blurhash: EMPTY_IMAGE_DATA,
            image: EMPTY_IMAGE_DATA,
            preloadImages: {
                1: '',
                2: '',
                3: '',
            },
            srcset: '',
        });
        /**
         * HTML elements.
         */
        const imageEl = ref();
        const rootEl = ref();
        /**
         * Image sizes
         */
        const height = ref();
        const width = ref();
        /**
         * Image loaded.
         */
        const isImageLoaded = ref(false);
        /**
         * Aspect ratio
         */
        const aspectRatio = computed(() => {
            if (props.customRatio) {
                return props.customRatio;
            }
            const { width = 1, height = 1 } = (props.image || {}).metadata || {};
            return height / width || 1;
        });
        /**
         * Has blurhash.
         */
        const hasBlurhash = computed(() => {
            const { blurhash } = props.image;
            return typeof blurhash === 'string' && blurhash.length > 5 && isBlurhashValid(blurhash);
        });
        /**
         * Square image flag
         */
        const isSquare = computed(() => height.value === width.value);
        /**
         * Preload
         */
        const { height: preloadHeight, width: preloadWidth } = props.preload;
        const shouldPreload = Boolean(preloadHeight && preloadWidth);
        if (shouldPreload) {
            processServerImage();
        }
        function handleBlurhash() {
            if (shouldPreload) {
                return;
            }
            if (props.isBlurhashEnabled && hasBlurhash.value) {
                if (imageHashStore.images[props.image.uri]) {
                    sources.value.blurhash = imageHashStore.images[props.image.uri];
                    return;
                }
                if (!canvas) {
                    canvas = document.createElement('canvas');
                }
                if (props.blurFull) {
                    height.value = rootEl.value?.offsetHeight || 0;
                    width.value = rootEl.value?.offsetWidth || 0;
                }
                if (!height || !width) {
                    return;
                }
                canvas.height = height.value;
                canvas.width = width.value;
                const ctx = canvas.getContext('2d');
                const imageData = ctx.createImageData(width.value, height.value);
                imageData.data.set(decode(props.image.blurhash, width.value, height.value));
                ctx.putImageData(imageData, 0, 0);
                canvas.toBlob((blob) => {
                    sources.value.blurhash = imageHashStore.images[props.image.uri] = URL.createObjectURL(blob);
                }, 'image/jpeg');
            }
        }
        function handleCropAndWebP() {
            let imageUrl = props.image?.uri || '';
            if (props.shouldCrop) {
                const cropChunk = props.cropMethod === 'cover' ? '/c' : '';
                imageUrl = imageUrl.replace(/\/(files|forbes-static)\//gi, `/$1${cropChunk}/${width.value}x${height.value}/`);
            }
            if (props.shouldUseWebp && imageFormat.isSupported('webp')) {
                imageUrl = imageUrl.replace(/.(jpg|jpeg|png)$/, '.webp');
            }
            sources.value.image = imageUrl;
            if (shouldPreload) {
                for (const pixelRatio of Object.keys(sources.value.preloadImages)) {
                    sources.value.preloadImages[pixelRatio] = imageUrl.replace(/\/(\d+)x(\d+)\//gi, (_, width, height) => {
                        const newWidth = Number(width) * Number(pixelRatio);
                        const newHeight = Number(height) * Number(pixelRatio);
                        return `/${newWidth}x${newHeight}/`;
                    });
                    sources.value.preloadImages[pixelRatio] += ` ${pixelRatio}x`;
                }
                sources.value.srcset = Object.values(sources.value.preloadImages).join(',');
                meta.link.value.unshift({
                    as: 'image',
                    href: sources.value.image,
                    imagesrcset: sources.value.srcset,
                    rel: 'preload',
                    fetchpriority: `${props.fetchPriority}`,
                    ...(props.preload.hid && { hid: props.preload.hid }),
                });
            }
        }
        function handleImageLoad() {
            isImageLoaded.value = true;
        }
        function handleImageSize() {
            if (shouldPreload) {
                return;
            }
            height.value = Math.round(height.value);
            width.value = Math.round(width.value);
            // if (viewport.isGreaterThan('mobileWide')) {
            //   height.value = Math.min(Math.max(1, window.innerHeight), height.value)
            //   width.value = Math.min(Math.max(1, window.innerWidth), width.value)
            // }
        }
        function handleRetina() {
            if (shouldPreload) {
                return;
            }
            const { effectiveType } = navigator?.connection || { effectiveType: '4g' };
            if (window.devicePixelRatio && effectiveType === '4g') {
                height.value *= window.devicePixelRatio;
                width.value *= window.devicePixelRatio;
            }
        }
        function handleSquareImage() {
            if (!isSquare.value) {
                return;
            }
            if (aspectRatio.value < 1) {
                width.value = width.value / aspectRatio.value;
            }
            else if (aspectRatio.value > 1) {
                height.value = height.value / aspectRatio.value;
            }
        }
        function processServerImage() {
            height.value = preloadHeight;
            width.value = preloadWidth;
            handleCropAndWebP();
        }
        function processClientImage() {
            if (!rootEl.value) {
                return;
            }
            if (!shouldPreload) {
                const { offsetHeight, offsetWidth } = rootEl.value;
                height.value = offsetHeight;
                width.value = offsetWidth;
            }
            handleBlurhash();
            handleSquareImage();
            handleRetina();
            handleImageSize();
            watch(() => props.shouldCrop, () => {
                isImageLoaded.value = false;
                handleCropAndWebP();
            }, {
                immediate: true,
            });
        }
        onServerPrefetch(async () => {
            if (!props.image || !props.isBlurhashEnabled) {
                return;
            }
            try {
                const { encode: encode64 } = await import('base64-arraybuffer');
                const UPNG = await import('upng-js');
                const height = 64;
                const width = 128;
                const pixels = decode(props.image.blurhash, width, height);
                const png = UPNG.encode([pixels], width, height, 256);
                imageHashStore.images[props.image.uri] = 'data:image/png;base64,' + encode64(png);
                sources.value.blurhash = imageHashStore.images[props.image.uri];
            }
            catch { }
        });
        onBeforeUnmount(() => {
            imageObserver.unobserve();
        });
        onMounted(() => {
            if (!props.image || !rootEl.value) {
                return;
            }
            if (shouldPreload || !props.isLazy) {
                return processClientImage();
            }
            imageObserver.observe(rootEl.value);
            imageObserver.onIntersect(() => {
                imageObserver.unobserve();
                processClientImage();
            });
        });
        return {
            aspectRatio,
            css,
            handleImageLoad,
            imageEl,
            isImageLoaded,
            rootEl,
            sources,
        };
    },
    // Important for useMeta
    head: {},
});
