import Vue from 'vue';
import { defineStore } from 'pinia';
import { handleMenuItems, MENUS_SLUGS } from '~/constants';
import { useDuck } from '~/services';
const duck = useDuck();
const STATE = {};
for (const slug of MENUS_SLUGS) {
    STATE[slug] = {
        slug,
        items: [],
    };
}
export const useMenus = defineStore('menu', {
    state: () => ({
        ...STATE,
    }),
    actions: {
        async fetchMenuBySlug(slug) {
            // If items already exist in state, exit the function
            if (this.$state[slug]?.items?.length) {
                return;
            }
            try {
                const { data } = await duck.pub.getApiPubMenusId(slug);
                if (!data?.items?.length) {
                    return console.error(`[Error while fetching menu by slug "${slug}"]: there is no items`);
                }
                Vue.set(this, slug, {
                    ...data,
                    items: handleMenuItems(data.items),
                });
            }
            catch (error) {
                console.error(`[Error while fetching menu by slug "${slug}"]:`, error);
            }
        },
        async fetchMenusBySlugs(slugs) {
            const slugsForFetching = [];
            for (const slug of slugs) {
                // If items already exist in state, go to next iteration
                if (this.$state[slug]?.items?.length) {
                    continue;
                }
                slugsForFetching.push(slug);
            }
            // If all menus already exist in state, exit the function
            if (!slugsForFetching?.length) {
                return;
            }
            try {
                const promises = await Promise.allSettled(slugsForFetching.map((slug) => {
                    // If items already exist in state, return true
                    if (this.$state[slug]?.items?.length) {
                        return true;
                    }
                    return duck.pub.getApiPubMenusId(slug);
                }));
                for (let i = 0, l = slugsForFetching.length; i < l; i++) {
                    const slug = slugsForFetching[i];
                    const { status } = promises[i];
                    if (status === 'rejected') {
                        // Try to fetch menu again, then go to next iteration
                        await this.fetchMenuBySlug(slug);
                        continue;
                    }
                    const { value } = promises[i];
                    // If items already exist in state, go to next iteration
                    if (typeof value === 'boolean' && value) {
                        continue;
                    }
                    const { data } = value || {};
                    if (!data?.items?.length) {
                        console.error(`[Error while fetching menu by slug "${slug}" in loop]: there is no items`);
                        continue;
                    }
                    Vue.set(this, slug, {
                        ...data,
                        items: handleMenuItems(data.items),
                    });
                }
            }
            catch (error) {
                console.error(`[Error while fetching menus by slugs: ${slugs.join(', ')}]:`, error);
            }
        },
    },
});
