import { render, staticRenderFns } from "./TheSearch.vue?vue&type=template&id=915d82ee&scoped=true&"
import script from "./TheSearch.vue?vue&type=script&lang=ts&"
export * from "./TheSearch.vue?vue&type=script&lang=ts&"
import style0 from "./TheSearch.vue?vue&type=style&index=0&lang=scss&module=true&"
import style1 from "./TheSearch.vue?vue&type=style&index=1&id=915d82ee&lang=scss&scoped=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "915d82ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/var/www/src/components/IconButton/IconButton.vue').default,MicroLoader: require('/var/www/src/components/MicroLoader/MicroLoader.vue').default,SearchTopic: require('/var/www/src/components/SearchTopic/SearchTopic.vue').default,BaseButton: require('/var/www/src/components/BaseButton/BaseButton.vue').default,TransitionExpand: require('/var/www/src/components/TransitionExpand/TransitionExpand.vue').default})
