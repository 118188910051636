// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tRQHu{padding:24px 16px;background-color:#181716;background-image:url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='160' height='400' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0c0 44.183 35.817 80 80 80s80-35.817 80-80H0zM0 80c0 44.183 35.817 80 80 80s80-35.817 80-80H0z' fill='%23232323'/%3E%3Ccircle cx='80' cy='240' r='80' transform='rotate(90 80 240)' fill='%23232323'/%3E%3Cpath d='M160 400c0-44.183-35.817-80-80-80S0 355.817 0 400h160z' fill='%23232323'/%3E%3C/svg%3E\");background-position:100% calc(100% + 64px);background-repeat:no-repeat;background-size:64px auto}@media screen and (min-width:768px){.tRQHu{background-image:url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='160' height='400' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0c0 44.183 35.817 80 80 80s80-35.817 80-80H0zM0 80c0 44.183 35.817 80 80 80s80-35.817 80-80H0z' fill='%23232323'/%3E%3Ccircle cx='80' cy='240' r='80' transform='rotate(90 80 240)' fill='%23232323'/%3E%3Cpath d='M160 400c0-44.183-35.817-80-80-80S0 355.817 0 400h160z' fill='%23232323'/%3E%3C/svg%3E\");background-position:100% calc(100% + 64px);background-size:64px auto}}._8RznC{margin:0 0 12px;color:#fff;font-family:\"Merriweather\",serif;font-size:22px;font-weight:700;line-height:28px}@media screen and (min-width:768px){._8RznC{margin-bottom:8px;font-size:18px;line-height:24px}}.\\+1Djb{margin:0 0 24px;color:#989898;font-size:16px;line-height:22px}@media screen and (min-width:768px){.\\+1Djb{font-size:15px;line-height:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tRQHu",
	"title": "_8RznC",
	"subtitle": "+1Djb"
};
module.exports = ___CSS_LOADER_EXPORT___;
