import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
/**
 * Список редиректов
 * В ключе указывается откуда редирект (БЕЗ ЗАКРЫВАЮЩЕГО СЛЭША!)
 * В значении указывается куда редирект (закрываюбщий слэш опционален)
 */
const REDIRECTS = {
    '/grid': '/',
    '/tegi/20-let-forbes-v-rossii': '/20forbesrussia/',
    // Образование: редирект списков на подрубрики
    '/education-articles': '/education_articles/',
    '/education-main': '/education/',
    '/education-news': '/education_news/',
    '/education-opinion': '/education_opinion/',
    '/education-ratings': '/education_ratings/',
    '/education-research': '/education_research/',
    // Образование: редирект списков на теги
    '/education-additional-education': '/tegi/education_additional-education/',
    '/education-business-education': '/tegi/education_business-education/',
    '/education-higher-education': '/tegi/education_higher-education/',
    '/education-kids': '/tegi/education_kids/',
    '/education-languages': '/tegi/education_languages/',
    '/education-professions': '/tegi/education_professions/',
    '/education-selfstudy': '/tegi/education_selfstudy/',
    '/education-study-abroad': '/tegi/education_study-abroad/',
    // Франшизы: редирект списков на подрубрики
    '/franchise': '/franchises/',
    '/franchises_films': '/franchises/films/',
    '/franchises_i-bought-a-franchise': '/franchises/i-bought-a-franchise/',
    '/franchises_i-will-sell-the-franchise': '/franchises/i-will-sell-the-franchise/',
    '/franchises_instructions': '/franchises/instructions/',
    '/franchises_magazines': '/franchises/magazines/',
    '/franchises_reviews': '/franchises/reviews/',
};
export default defineNuxtMiddleware(({ redirect, route, query }) => {
    // Редирект списков для слайдеров на страницах рубрик.
    // Например, forbeslife-news / forbeslife-partner
    const regExp = /-news\/?$|-partner\/?$/g;
    if (regExp.test(route.path)) {
        const rubricPath = route.path.replace(regExp, '');
        return redirect(301, rubricPath, query);
    }
    // Редиректы урлов из статичного списка
    for (const originalPath in REDIRECTS) {
        const regExp = new RegExp(`^(${originalPath})/?$`, 'g');
        if (regExp.test(route.path)) {
            const newPath = REDIRECTS[originalPath];
            return redirect(301, newPath, query);
        }
    }
});
