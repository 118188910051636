import { defineNuxtPlugin, onGlobalSetup, onMounted } from '@nuxtjs/composition-api';
import Vue from 'vue';
import { IS_DEVELOPMENT_MODE } from '~/constants';
import { isLightHouse, randomNumber } from '~/utils';
/**
 * Completely disables all banners in any environment
 */
export const SHOULD_DISABLE = false;
/**
 * Debug mode switcher
 */
export const IS_DEBUG_MODE = false;
/**
 * Displays banner config on page
 */
export const SHOULD_SHOW_DEBUG_INFO = false;
/**
 * Adfox owner id
 */
export const ADFOX_OWNER_ID = 162070;
/**
 * YaHeaderBiddingSettings
 */
export const ADFOX_HEADER_BIDDINGS_SETTINGS = {
    adUnits: [],
    biddersMap: {
        myTarget: '2441413',
        sape: '2576452',
    },
    timeout: 500,
};
/**
 * Disabled message
 */
export const ADFOX_DISABLED_MESSAGE = `Adfox is disabled in development mode. Switch to debug mode to enable.`;
/**
 * Reference to documentation
 */
export const ADFOX_DOCS_REF = 'https://yandex.ru/support2/adfox/ru/codes/code#callback';
export default defineNuxtPlugin(({ app, nuxtState }, inject) => {
    const adfoxLoader = new Vue({
        data() {
            return {
                error: false,
                done: false,
                isDisabled: false,
            };
        },
    });
    if (SHOULD_DISABLE) {
        adfoxLoader.isDisabled = true;
        console.info(`%cAdfox is disabled in any environment`, 'color: DarkGoldenRod;');
    }
    else if (IS_DEVELOPMENT_MODE && IS_DEBUG_MODE) {
        console.info(`%cAdfox is in development debug mode`, 'color: DarkGoldenRod;');
    }
    else if (IS_DEVELOPMENT_MODE && !IS_DEBUG_MODE) {
        adfoxLoader.isDisabled = true;
        console.info(`%c${ADFOX_DISABLED_MESSAGE}`, 'color: DarkGoldenRod;');
    }
    inject('adfoxLoader', adfoxLoader);
    if (adfoxLoader.isDisabled) {
        return;
    }
    onGlobalSetup(() => {
        onMounted(() => {
            window.puids = (app.$page || nuxtState?.data?.[0]?.$page || {}).puids || {};
            // Init global adfox object
            window.__ADFOX__ = window.__ADFOX__ || {};
            // Add storage and methods for banners
            window.__ADFOX__.banners = window.__ADFOX__.banners || [];
            window.__ADFOX__.getBannerByContainerId = (id) => {
                return window.__ADFOX__.banners.find(({ containerId = '' }) => containerId === id);
            };
            // Add ref to docs
            window.__ADFOX__.docsRef = ADFOX_DOCS_REF;
            window.__ADFOX__.openDocsRef = () => window.open(ADFOX_DOCS_REF);
            // Add random pr param for session banners
            window.__ADFOX__.pr = randomNumber(1, 4294967295);
        });
    });
    app?.router?.onReady(() => {
        app?.router?.beforeEach((_to, _from, next) => {
            window.__ADFOX__.banners.length = 0;
            // update random pr param for session banners
            window.__ADFOX__.pr = randomNumber(1, 4294967295);
            next();
        });
        app?.router?.afterEach(() => {
            window.Ya?.adfoxCode?.clearSession?.();
            window.puids = (app.$page || nuxtState?.data?.[0]?.$page || {}).puids || {};
        });
    });
    window.addEventListener('load', async () => {
        if (isLightHouse(navigator.userAgent)) {
            return;
        }
        try {
            try {
                await loadScript('https://yandex.ru/ads/system/header-bidding.js');
            }
            catch (error) {
                console.error('[Adfox]', error);
            }
            window.YaHeaderBiddingSettings = ADFOX_HEADER_BIDDINGS_SETTINGS;
            window.Ya || (window.Ya = {});
            window.yaContextCb = window.yaContextCb || [];
            window.Ya.adfoxCode || (window.Ya.adfoxCode = {});
            window.Ya.adfoxCode.hbCallbacks || (window.Ya.adfoxCode.hbCallbacks = []);
            await loadScript('https://yandex.ru/ads/system/context.js');
            adfoxLoader.done = true;
        }
        catch (error) {
            adfoxLoader.error = true;
            if (IS_DEVELOPMENT_MODE && IS_DEBUG_MODE) {
                console.error('[Adfox]', error);
                console.error('An error occurred while loading the Adfox script. Make sure you have disabled Adblock');
            }
        }
    });
    function loadScript(src) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.async = true;
            script.defer = true;
            script.src = src;
            script.onerror = reject;
            script.onload = resolve;
            document.head.append(script);
        });
    }
});
