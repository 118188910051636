import { defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'TooltipMobile',
    props: {
        triggerWidth: {
            default: '24px',
            type: String,
        },
        triggerHeight: {
            default: '24px',
            type: String,
        },
        withBodyPadding: {
            default: true,
            type: Boolean,
        },
        withFullBlackout: {
            default: false,
            type: Boolean,
        },
        withGap: {
            default: false,
            type: Boolean,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const isOpened = ref(false);
        function closeTooltip() {
            isOpened.value = false;
        }
        function toggleTooltip() {
            isOpened.value = !isOpened.value;
        }
        return {
            css,
            isOpened,
            closeTooltip,
            toggleTooltip,
        };
    },
});
