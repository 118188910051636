import { ref } from '@nuxtjs/composition-api';
import { useDuck } from '~/services';
const duck = useDuck();
const state = ref({
    data: null,
    isLoading: false,
});
export function useFreshMagazine() {
    return state;
}
