import { defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { RBE_NAV_LINKS } from '~/components/RatingBestEmployers/RbeHeader';
export default defineComponent({
    name: 'RbeMenu',
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const burgerToggle = ref(false);
        function menuControl() {
            burgerToggle.value = !burgerToggle.value;
            document.body.style.overflow = burgerToggle.value ? 'hidden' : '';
        }
        return {
            css,
            burgerToggle,
            menuControl,
            RBE_NAV_LINKS,
        };
    },
});
