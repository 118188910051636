// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".g2K80{padding:16px 8px 20px;background:rgba(25,160,250,.15)}@media screen and (min-width:400px){.g2K80{padding-right:16px;padding-left:16px}}.g2K80 a{color:#0082ff;cursor:pointer;text-decoration:none;transition:color .2s}.g2K80 a:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#006ee1}@media(any-hover:hover){.g2K80 a:hover{color:#006ee1}}.V6vBx{display:block;margin:0 0 8px;color:#101010;font-family:\"Merriweather\",serif;font-size:22px;font-weight:700;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;line-height:28px}._0x\\+fs{margin:0 0 24px;color:#232323;font-size:15px;font-weight:400;line-height:20px}.p9DU0{display:block;width:100%;height:48px}.oEwJi{width:100%;height:100%;flex:1;padding:12px 16px;border:0;background-color:#fff;border-radius:4px;caret-color:#0082ff;font-size:15px;line-height:24px;outline:0;transition:color .2s}.oEwJi::-webkit-input-placeholder{color:#767676}.oEwJi::-moz-placeholder{color:#767676}.oEwJi:-ms-input-placeholder{color:#767676}.oEwJi:-moz-placeholder{color:#767676}._0MIu4 .oEwJi::-webkit-input-placeholder{color:#f02d37}._0MIu4 .oEwJi::-moz-placeholder{color:#f02d37}._0MIu4 .oEwJi:-ms-input-placeholder{color:#f02d37}._0MIu4 .oEwJi:-moz-placeholder{color:#f02d37}.g2K80 .KbDj5{width:100%;margin:12px 0;background-color:#232323;font-weight:400}@media screen and (min-width:1024px){.g2K80 .KbDj5{display:block}}.g2K80 .KbDj5:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#2e2e2e}@media(any-hover:hover){.g2K80 .KbDj5:hover{background-color:#2e2e2e}}.g2K80 .KbDj5:focus{background-color:#2e2e2e}.uqAyo{display:block;margin:4px 0;color:#f02d37;font-size:13px;line-height:20px}.uIoG6{color:#151515;font-size:14px;font-weight:400;line-height:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "g2K80",
	"title": "V6vBx",
	"subtitle": "_0x+fs",
	"label": "p9DU0",
	"input": "oEwJi",
	"withError": "_0MIu4",
	"submit": "KbDj5",
	"error": "uqAyo",
	"footnote": "uIoG6"
};
module.exports = ___CSS_LOADER_EXPORT___;
