export const EDUCATIONS_PODCASTS = ['raspisanie-na-poslezavtra', 'go-ucit-sa'];
export const EDUCATION_TAGS_REGEXP = /fe_/gi;
export const EDUCATION_SUBRUBRICS = [
    {
        title: 'Cтатьи',
        to: '/education_articles',
    },
    {
        title: 'Новости',
        to: '/education_news',
    },
    {
        title: 'Рейтинги',
        to: '/education_ratings',
    },
    {
        title: 'Исследования',
        to: '/education_research',
    },
    {
        title: 'Точка зрения',
        to: '/education_opinion',
    },
    // {
    //   title: 'Спецпроекты',
    //   to: '/education_special',
    // },
    // {
    //   title: 'Мероприятия',
    //   to: '/education_events',
    // },
    // {
    //   title: 'Видео',
    //   to: '/education_video',
    // },
    // {
    //   title: 'Подкасты',
    //   to: '/education_podcasts',
    // },
];
export const EDUCATION_TAGS = [
    {
        title: '#детское образование',
        to: '/tegi/education_kids',
    },
    {
        title: '#высшее образование',
        to: '/tegi/education_higher-education',
    },
    {
        title: '#бизнес-образование',
        to: '/tegi/education_business-education',
    },
    {
        title: '#дополнительное образование',
        to: '/tegi/education_additional-education',
    },
    {
        title: '#направления и специальности',
        to: '/tegi/education_professions',
    },
    {
        title: '#образование за рубежом',
        to: '/tegi/education_study-abroad',
    },
    // {
    //   title: '#образование в России',
    //   to: '/tegi/education_study-in-russia',
    // },
    {
        title: '#иностранные языки',
        to: '/tegi/education_languages',
    },
    {
        title: '#саморазвитие',
        to: '/tegi/education_selfstudy',
    },
];
