// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uCKkE{position:relative;min-height:160px;border-top:1px solid #e2e2e2;border-bottom:1px solid #e2e2e2;background:#fff}.Xn9TX{padding:0;margin:0;list-style:none;overflow-x:auto;overflow-y:hidden;-ms-scroll-snap-type:x mandatory;scroll-snap-type:x mandatory}.YBGaR,.Xn9TX{display:flex}.YBGaR{width:100vw;flex:0 0 auto;flex-flow:column;padding:24px 16px 0;scroll-snap-align:start}.YBGaR a{color:#2d2d2d}.E2h3L{margin:0 0 12px;font-family:\"Merriweather\",serif;font-size:22px;line-height:28px}.E2h3L>a>span{color:#0082ff}.ltvpo{height:100%;margin:0 0 51px;font-size:16px;line-height:22px;-webkit-line-clamp:var(--waterfall-line-clamp);-webkit-box-orient:vertical;display:block;display:-webkit-box;overflow:hidden;max-height:calc(22px*var(--waterfall-line-clamp))}@media(min-width:452px){.ltvpo{margin:0 0 79px}}.-Y0Ho{position:absolute;bottom:0;left:0;display:flex;align-items:center;padding:0 16px 16px;margin:0;list-style:none}._2\\+rbd{width:10px;height:10px;border:3px solid transparent;background-clip:content-box;background-color:#767676;border-radius:100%}._2\\+rbd._81EPE{background-color:#101010}.Gw4SL{position:absolute;top:0;left:0;display:flex;width:100%;height:100%;align-items:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theCarouselMobile": "uCKkE",
	"list": "Xn9TX",
	"item": "YBGaR",
	"title": "E2h3L",
	"description": "ltvpo",
	"dots": "-Y0Ho",
	"dot": "_2+rbd",
	"dotActive": "_81EPE",
	"loader": "Gw4SL"
};
module.exports = ___CSS_LOADER_EXPORT___;
