import { defineComponent, ref, useCssModule, } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'SberSpeechMobile',
    props: {
        eventDetail: {
            default: () => ({}),
            type: Object,
            required: true,
        },
        currentVolume: {
            type: Object,
            required: true,
        },
        playbackRate: {
            default: 1,
            type: Number,
        },
        nextTitle: {
            default: '',
            type: String,
        },
        prevTitle: {
            default: '',
            type: String,
        },
        isPrevList: {
            default: 0,
            type: Number,
        },
        isWaitLoadSwitchArticle: {
            default: false,
            type: Boolean,
        },
        shareUrl: {
            default: '',
            type: String,
        },
        isPlaying: {
            default: false,
            type: Boolean,
        },
        isChunkLoading: {
            default: false,
            type: Boolean,
        },
        hasShowPopup: {
            default: false,
            type: Boolean,
        },
        isMobileFolder: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['folded', 'scroll-to', 'switch-article', 'play-pause', 'handle-logo', 'restart', 'handle-playback-rate'],
    setup(_, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const lastTouchCloseY = ref(0);
        function handleTouchClose({ targetTouches, type }) {
            const { clientY } = targetTouches[0] || {};
            if (type === 'touchstart') {
                lastTouchCloseY.value = clientY;
            }
            if (clientY > lastTouchCloseY.value) {
                emit('folded', true);
            }
        }
        return {
            css,
            handleTouchClose,
        };
    },
});
