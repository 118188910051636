import Vue from 'vue';
import { useTask } from 'vue-concurrency';
import { useDuck } from '~/services';
const duck = useDuck();
const eventBus = new Vue();
const sources = new Map();
/**
 * Аудио для подкастов
 */
export function useAudio(url, duration = 0) {
    const source = sources.get(url);
    let audio = source?.audio || null;
    if (!process.server) {
        if (!audio) {
            audio = new Audio(url);
            audio.preload = 'metadata';
            sources.set(url, { audio });
        }
        audio.oncanplay = audio.onplay = audio.onpause = () => eventBus.$emit('update', audio);
        audio.ontimeupdate = (event) => {
            const target = event.target;
            if (duration && target.currentTime >= duration) {
                target.pause();
                target.currentTime = 0;
            }
            eventBus.$emit('update', audio);
        };
    }
    return {
        get currentTime() {
            if (process.server) {
                return 0;
            }
            return audio?.currentTime || 0;
        },
        set currentTime(seconds) {
            if (process.server) {
                return;
            }
            if (audio) {
                audio.currentTime = seconds;
            }
        },
        get volume() {
            if (process.server) {
                return 0;
            }
            return audio?.volume || 0;
        },
        set volume(value) {
            if (process.server) {
                return;
            }
            if (audio) {
                audio.volume = value;
            }
        },
        get duration() {
            if (process.server) {
                return 0;
            }
            return audio?.duration || 0;
        },
        get isPlaying() {
            if (process.server) {
                return false;
            }
            return !!audio && !audio.paused;
        },
        on(event, callback) {
            if (process.server) {
                return;
            }
            return eventBus.$on(event, (source) => {
                if (audio !== source) {
                    return;
                }
                callback(source);
            });
        },
        playPause() {
            if (process.server) {
                return;
            }
            return sources.forEach((source) => {
                if (source.audio === audio) {
                    return source.audio[source.audio.paused ? 'play' : 'pause']();
                }
                source.audio.pause();
            });
        },
    };
}
/**
 * Аудио материалов
 */
export function useAudioChunks(url) {
    const source = sources.get(url);
    let audio = source?.audio || null;
    const tempAudioQueue = source?.tempAudioQueue || [];
    const arrayBufferQueue = source?.arrayBufferQueue || [];
    let articleBlocksLength = source?.articleBlocksLength || 0;
    let isWaitLoadChunk = false;
    let interVal = source?.interVal || null;
    let mimeType = source?.mimeType || 'opus';
    if (!process.server && !audio) {
        audio = new Audio();
        mimeType = audio.canPlayType('audio/ogg; codecs=opus') ? 'opus' : 'wav16';
        sources.set(url, { audio, tempAudioQueue, arrayBufferQueue, articleBlocksLength, interVal, mimeType });
        audio.oncanplay = audio.onplay = audio.onpause = () => eventBus.$emit('update', audio);
    }
    return {
        /**
         * Если чанк закнчил воспроизведение запускаем initInterval
         */
        initAudioListeners() {
            if (!audio) {
                return;
            }
            audio.onended = () => (interVal = this.initInterval());
        },
        /**
         * Берем чанк из временного стейта
         * Если чанков больше нет а количество чанков в буфер равно длине блоков материала
         * Значит материал закончился и запускаем следующий
         */
        async initAudioChunk() {
            const audioChunk = tempAudioQueue.shift();
            if (!audioChunk && articleBlocksLength === arrayBufferQueue.length) {
                eventBus.$emit('track-is-over');
                return false;
            }
            if (!audioChunk) {
                return false;
            }
            const audioBlob = new Blob([audioChunk], { type: `audio/${mimeType}` });
            const audioUrl = URL.createObjectURL(audioBlob);
            if (audio) {
                const currentPlaybackRate = this.playbackRate;
                audio.src = audioUrl;
                audio.playbackRate = currentPlaybackRate;
            }
            return true;
        },
        /**
         * Запрос чанков
         */
        audioSpeechTask: useTask(function* (signal, id, count) {
            signal.addEventListener('abort', () => duck.abortRequest(`cancelPubSalutespeech-${id}-${count}`));
            return duck.pub
                .getPubSalutespeech({ id, limit: 1, offset: count, format: mimeType }, {
                cancelToken: `cancelPubSalutespeech-${id}-${count}`,
            })
                .then((response) => response.arrayBuffer())
                .catch((e) => {
                throw e;
            });
        }).maxConcurrency(3),
        /**
         * Полученный чанк добавляем в временный стейт и буфер
         */
        addAudioChunk(arrayBuffer) {
            tempAudioQueue.push(arrayBuffer);
            arrayBufferQueue.push(arrayBuffer);
        },
        /**
         * Если чанк закончился а новый не успел загрузиться, запускаем интервал с 500мс
         * Отправляем emit состояния пока идет загрузка
         * Как только чанк загрузился чистим интервал запускам инициализацию аудио и запускаем воспроизведение
         */
        initInterval() {
            return setInterval(async () => {
                const isWaitChunk = this.audioSpeechTask.isRunning && !tempAudioQueue.length;
                const isWaitChunkIsOver = this.isChunkOver() && !tempAudioQueue.length && articleBlocksLength !== arrayBufferQueue.length;
                const isWaitingStatus = isWaitChunk || isWaitChunkIsOver;
                if (isWaitingStatus) {
                    isWaitLoadChunk = true;
                    eventBus.$emit('updateStatus', audio, isWaitLoadChunk);
                    return;
                }
                isWaitLoadChunk = false;
                eventBus.$emit('updateStatus', audio, isWaitLoadChunk);
                this.resetInterval();
                const wasChunk = await this.initAudioChunk();
                if (wasChunk) {
                    await audio?.play();
                }
            }, 500);
        },
        get bufferLength() {
            if (process.server) {
                return 0;
            }
            return arrayBufferQueue.length;
        },
        get audioInit() {
            if (process.server) {
                return;
            }
            return !!audio?.onended;
        },
        /**
         * Заполняем временный стейт из буфера
         */
        includesFromBuffer() {
            tempAudioQueue.length = 0;
            tempAudioQueue.push(...arrayBufferQueue);
        },
        async restart() {
            this.includesFromBuffer();
            await this.initAudioChunk();
            this.playPause();
        },
        /**
         * Если аудио загрузил все свои чанки, то для повторного воспроизвдения
         * Воспроизводим аудио из буфера
         */
        async runAudioFromBuffer() {
            if (tempAudioQueue.length) {
                await this.initAudioChunk();
                this.playPause();
            }
            else {
                await this.restart();
            }
        },
        get blocksCount() {
            if (process.server) {
                return 0;
            }
            return articleBlocksLength;
        },
        set blocksCount(value) {
            if (process.server) {
                return;
            }
            if (audio) {
                articleBlocksLength = value;
            }
        },
        resetInterval() {
            if (interVal) {
                clearInterval(interVal);
            }
        },
        get currentTime() {
            if (process.server) {
                return 0;
            }
            return audio?.currentTime || 0;
        },
        isChunkOver() {
            if (process.server) {
                return false;
            }
            return this.currentTime === this.duration;
        },
        get playbackRate() {
            if (process.server) {
                return 1;
            }
            return audio?.playbackRate || 1;
        },
        set playbackRate(value) {
            if (process.server) {
                return;
            }
            if (audio) {
                audio.playbackRate = value;
            }
        },
        get volume() {
            if (process.server) {
                return 0;
            }
            return audio?.volume || 0;
        },
        set volume(value) {
            if (process.server) {
                return;
            }
            if (audio) {
                audio.volume = value;
            }
        },
        get duration() {
            if (process.server) {
                return 0;
            }
            return audio?.duration || 0;
        },
        get isPlaying() {
            if (process.server) {
                return false;
            }
            return !!audio && !audio.paused;
        },
        /**
         * Событие для play/pause
         */
        on(event, callback) {
            if (process.server) {
                return;
            }
            return eventBus.$on(event, (source) => {
                if (audio !== source) {
                    return;
                }
                callback(source);
            });
        },
        /**
         * Событие для статуса загрузки чанка
         */
        onStatus(event, callback) {
            if (process.server) {
                return;
            }
            return eventBus.$on(event, (source, loadStatus) => {
                if (audio !== source) {
                    return;
                }
                callback(source, loadStatus);
            });
        },
        /**
         * Событие - аудио дорожка закончилась
         */
        onTrackIsOver(event, callback) {
            if (process.server) {
                return;
            }
            return eventBus.$on(event, () => {
                callback();
            });
        },
        playPause() {
            if (process.server) {
                return;
            }
            return sources.forEach((source) => {
                if (source.audio === audio) {
                    return source.audio[source.audio.paused ? 'play' : 'pause']();
                }
                source.audio.pause();
            });
        },
    };
}
