// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GCHUR{position:relative;display:flex;min-height:100vh;flex-direction:column;box-shadow:0 0 32px rgba(0,0,0,.08)}@media screen and (min-width:768px){.GCHUR{background-color:#fcfcfc}}@media screen and (min-width:1236px){.GCHUR{max-width:1236px}}@media screen and (min-width:1552px){.GCHUR{max-width:1552px}}._6CcPr{width:100%;padding-top:88px;margin:0 auto}@media screen and (min-width:1236px){._6CcPr{display:flex;align-items:flex-start;padding-top:48px}}.UQECv{flex:1}@media screen and (min-width:768px){.UQECv{display:flex;min-height:calc(100vh - 48px);min-height:calc(var(--vh, 1vh)*100 - 48px);flex-direction:column}}@media screen and (min-width:1236px){.UQECv{order:1}}.cSkVO{display:flex;flex:1;flex-direction:column}._3lsIr{flex:1}.He\\+cj{flex-shrink:0}.bYgdS{position:sticky;z-index:20;top:48px;width:256px;height:calc(100vh - 88px);height:calc(var(--vh, 1vh)*100 - 88px);flex:0 0 256px;background-color:#fff;box-shadow:0 0 24px 0 rgba(0,0,0,.1);color:#2d2d2d}@media screen and (min-width:1236px){.bYgdS{height:calc(100vh - 48px);height:calc(var(--vh, 1vh)*100 - 48px)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "GCHUR",
	"container": "_6CcPr",
	"content": "UQECv",
	"main": "cSkVO",
	"page": "_3lsIr",
	"footer": "He+cj",
	"waterfall": "bYgdS"
};
module.exports = ___CSS_LOADER_EXPORT___;
