import { computed, defineComponent, ref, useContext, useCssModule } from '@nuxtjs/composition-api';
import { ADFOX_CODES_DESKTOP, ADFOX_CODES_MOBILE } from '~/components/Adfox';
import { COPYRIGHT } from '~/constants';
export default defineComponent({
    setup() {
        const css = useCssModule();
        const context = useContext();
        const viewport = context.$viewport;
        const isLoading = ref(false);
        /**
         * Adfox Config
         */
        const adConfig = computed(() => {
            if (viewport.isLessThan('tablet')) {
                return ADFOX_CODES_MOBILE['300x250_1'];
            }
            return ADFOX_CODES_DESKTOP.billboard;
        });
        return {
            COPYRIGHT,
            adConfig,
            css,
            isLoading,
        };
    },
});
