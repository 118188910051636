import { computed, defineComponent, onBeforeMount, onMounted, onServerPrefetch, ref, useContext, useCssModule, useMeta, useRoute, useRouter, watch, } from '@nuxtjs/composition-api';
import { useDuck, useWaterfall } from '~/services';
import { isFlutter, parseCookies } from '~/utils';
import { ADFOX_CODES_DESKTOP, ADFOX_CODES_MOBILE } from '~/components/Adfox';
import { ARTICLES_HISTORY_STORAGE_KEY, useArticlesHistory, USER_COOKIE_JWT, useBookmarkStore, useA11y, useABTest, useModal, useRecommendationBanner, useSign, useUser, } from '~/composables';
import { CLOUDPAYMENTS_SRC } from '~/pages/account/data';
import { ARTICLE_BOOKMARK_COOKIE } from '~/constants';
export default defineComponent({
    name: 'DefaultLayout',
    setup() {
        const a11y = useA11y();
        const abTest = useABTest();
        const articlesHistory = useArticlesHistory();
        const bookmarkStore = useBookmarkStore();
        const context = useContext();
        const css = useCssModule();
        const duck = useDuck();
        const meta = useMeta();
        const modal = useModal();
        const recommendationBanner = useRecommendationBanner();
        const route = useRoute();
        const router = useRouter();
        const sign = useSign();
        const user = useUser();
        const waterfall = useWaterfall();
        const viewport = context.$viewport;
        const adfoxBackgroundKey = ref('');
        const articlePath = ref('');
        const layoutEl = ref();
        const shouldRenderAds = computed(() => !isFlutter(context.$device.userAgent));
        const shouldShowWaterfall = computed(() => {
            if (a11y.isEnabled) {
                return a11y.modes.waterfall.isEnabled;
            }
            return viewport.isGreaterThan('desktop');
        });
        /**
         * Adfox items
         */
        const adfoxItems = computed(() => {
            const { path } = route.value;
            return {
                desktop: {
                    // background: {
                    //   component: 'Adfox',
                    //   config: ADFOX_CODES_DESKTOP.background,
                    //   key: adfoxBackgroundKey.value,
                    // },
                    floor: {
                        component: 'Adfox',
                        config: ADFOX_CODES_DESKTOP.floor,
                        key: 'floor/' + viewport.breakpoint + path + articlePath.value,
                    },
                    fullscreen: {
                        component: 'FullscreenAdfox',
                        config: ADFOX_CODES_DESKTOP.fullscreen,
                        key: 'fullscreen/' + viewport.breakpoint,
                    },
                },
                mobile: {
                    floor: {
                        component: 'Adfox',
                        config: { ...ADFOX_CODES_MOBILE.floor, shouldSyncSession: true },
                        key: 'floor/' + viewport.breakpoint + path + articlePath.value,
                    },
                    fullscreen: {
                        component: 'FullscreenAdfox',
                        config: ADFOX_CODES_MOBILE.fullscreen,
                        key: 'fullscreen/' + viewport.breakpoint + path,
                    },
                },
                pixel: {
                    component: 'Adfox',
                    config: ADFOX_CODES_DESKTOP.pixel,
                    key: 'pixel',
                },
            };
        });
        /**
         * Calc and save in --vh the consistent and correct vh value.
         * Useful on mobile and tablet devices.
         * Allows to ignore the browser top and bottom bars.
         * Usage in css: `height: calc(var(--vh, 1vh) * 100);`
         */
        function setCssVhValue() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        /**
         * Handles custom switch article event
         */
        function handleSwitchArticle() {
            articlePath.value = location.pathname;
            adfoxBackgroundKey.value = 'background/' + viewport.breakpoint + route.value.path + articlePath.value;
        }
        onBeforeMount(() => {
            articlesHistory.appendData(articlesHistory.readFromHistory());
            window.addEventListener('storage', (event) => {
                if (event.key !== ARTICLES_HISTORY_STORAGE_KEY) {
                    return;
                }
                articlesHistory.appendData(articlesHistory.readFromHistory());
            });
        });
        onMounted(() => {
            /**
             * Listen storage
             */
            window.addEventListener('storage', async (event) => {
                if (event.key !== USER_COOKIE_JWT) {
                    return;
                }
                let value = event.newValue;
                function logout() {
                    if (!user.isAuthorized) {
                        return;
                    }
                    user.logout();
                    router.push('/');
                }
                if (!value) {
                    return logout();
                }
                try {
                    value = JSON.parse(value);
                    if (!value.accessToken || !value.refreshToken) {
                        return logout();
                    }
                    user.jwt = value;
                    user.info = await user.getCurrentInfo();
                }
                catch {
                    //
                }
            });
            /**
             * Watch authorized user
             */
            watch(() => user.isAuthorized, async (value) => {
                if (!value) {
                    bookmarkStore.data = {};
                    return;
                }
                meta.script.value.push({
                    async: true,
                    defer: true,
                    src: CLOUDPAYMENTS_SRC,
                });
                try {
                    const { data } = await duck.savedArticles.getSavedArticles();
                    for (const item of data) {
                        bookmarkStore.setData(item.articleId, item.readed ?? -1);
                    }
                    const cookies = parseCookies(document.cookie);
                    const bookmarkArticle = cookies[ARTICLE_BOOKMARK_COOKIE];
                    document.cookie = `${ARTICLE_BOOKMARK_COOKIE}=; Max-Age=-1`;
                    if (bookmarkArticle == null) {
                        return;
                    }
                    try {
                        if (bookmarkStore.data[bookmarkArticle] == null) {
                            bookmarkStore.setData(bookmarkArticle, -1);
                            duck.savedArticle.postSaveArticle({
                                articleId: Number(bookmarkArticle),
                            });
                        }
                    }
                    catch { }
                }
                catch (error) {
                    //
                }
            }, {
                immediate: true,
            });
            /**
             * Listen touchstart event
             */
            window.addEventListener('touchstart', () => {
                //
            }, {
                passive: false,
            });
            /**
             * Set CSS vh based on JS calculations
             */
            setCssVhValue();
            window.addEventListener('resize', setCssVhValue);
            /**
             * Listen completePageHeaderAdfox event
             */
            window.addEventListener('completePageHeaderAdfox', () => {
                adfoxBackgroundKey.value = 'background/' + viewport.breakpoint + route.value.path + articlePath.value;
            });
            /**
             * Watch route
             */
            watch(route, ({ name = '', path, query }) => {
                recommendationBanner.clear();
                let waterfallMode = 'basic';
                if (path.startsWith('/education') || path.startsWith('/tegi/education')) {
                    waterfallMode = 'education';
                }
                if (path.startsWith('/sport')) {
                    waterfallMode = 'sport';
                }
                waterfall.setMode(waterfallMode);
                if (name === 'rubric-all') {
                    window.addEventListener('switchArticle', handleSwitchArticle);
                }
                else {
                    articlePath.value = '';
                    window.removeEventListener('switchArticle', handleSwitchArticle);
                    if (a11y.isEnabled) {
                        a11y.reset();
                    }
                }
                if (!query.modal) {
                    return;
                }
                let modalKey = query.modal;
                switch (modalKey) {
                    case 'newsletter': {
                        modalKey = 'emailSubscriptionModal';
                        break;
                    }
                    case 'recovery': {
                        modalKey = 'signChangePassword';
                        sign.changePassword = {
                            ...query,
                        };
                        break;
                    }
                    case 'sign-in': {
                        modalKey = 'signIn';
                        break;
                    }
                    case 'sign-up': {
                        modalKey = 'signUp';
                        break;
                    }
                }
                modal.key = modalKey;
            }, {
                immediate: true,
            });
            if (!layoutEl.value) {
                return;
            }
            abTest.init(layoutEl.value, route.value);
        });
        onServerPrefetch(async () => {
            if (!user.isAuthorized) {
                return;
            }
            try {
                const { data } = await duck.savedArticles.getSavedArticles();
                for (const item of data) {
                    bookmarkStore.setData(item.articleId, item.readed ?? -1);
                }
            }
            catch (error) {
                //
            }
        });
        return {
            a11y,
            abTest,
            articlesHistory,
            articlePath,
            adfoxItems,
            css,
            modal,
            layoutEl,
            shouldRenderAds,
            shouldShowWaterfall,
        };
    },
    // Important for useMeta
    head: {},
});
