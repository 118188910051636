import { watch, useContext, computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, useCssModule, } from '@nuxtjs/composition-api';
import { ADFOX_FETCH_STATES } from './';
import { ADFOX_DISABLED_MESSAGE, ADFOX_DOCS_REF, ADFOX_OWNER_ID, IS_DEBUG_MODE, SHOULD_SHOW_DEBUG_INFO, } from '~/plugins/adfox';
import { useModal } from '~/composables';
import { IS_DEVELOPMENT_MODE } from '~/constants';
export default defineComponent({
    name: 'Adfox',
    props: {
        config: {
            default: () => ({}),
            required: true,
            type: Object,
        },
        disabledMessage: {
            default: '',
            type: String,
        },
    },
    setup(props, { emit }) {
        /**
         * Composables
         */
        const context = useContext();
        const modal = useModal();
        const css = useCssModule();
        const adfoxLoader = context.$adfoxLoader;
        const shouldRender = computed(() => {
            return process.server ? false : adfoxLoader.done;
        });
        /**
         * Adfox development debug component
         */
        let debugComponent = null;
        if (IS_DEVELOPMENT_MODE && IS_DEBUG_MODE && SHOULD_SHOW_DEBUG_INFO) {
            debugComponent = () => import('~/components/AdfoxDebug/AdfoxDebug.vue');
        }
        /**
         * HTML elements and API
         */
        const adfoxEl = ref();
        const intersectionObserver = ref();
        /**
         * Adfox fetched data
         */
        const currentData = ref();
        /**
         * Adfox fetched error
         */
        const currentError = ref();
        /**
         * Adfox container id
         */
        const currentId = ref(props.config.containerId);
        /**
         * Adfox config
         */
        const currentConfig = computed(() => {
            return {
                ownerId: ADFOX_OWNER_ID,
                onLoad: (data) => onLoad(data),
                onRender: () => onRender(),
                onError: (error) => onError(error),
                onStub: () => onStub(),
                ...props.config,
                containerId: currentId.value,
            };
        });
        const isBannerCreated = ref(false);
        /**
         * Adfox fetch state
         */
        const state = reactive({ ...ADFOX_FETCH_STATES });
        /**
         * Adfox fetch statuses
         */
        const STATUSES = {
            isDisabled: 'DISABLED',
            isLoaded: 'LOADED',
            isRendered: 'RENDERED',
            isUndefined: 'UNDEFINED',
            withError: 'ERROR',
            withStub: 'STUB',
        };
        /**
         * Adfox onLoad
         */
        function onLoad(data) {
            state.isLoaded = true;
            currentData.value = data;
        }
        /**
         * Adfox onRender. Executes after onLoad.
         */
        function onRender() {
            state.isRendered = true;
            onComplete({ data: currentData.value, state });
            if (!adfoxEl.value) {
                return;
            }
            const isEmailSubscription = adfoxEl.value?.style?.content?.includes('adfox-email-subscription');
            if (isEmailSubscription) {
                adfoxEl.value.style.cursor = 'pointer';
                adfoxEl.value.children[0].style.pointerEvents = 'none';
                adfoxEl.value.addEventListener('click', handleEmailSubscriptionClick);
            }
        }
        function handleEmailSubscriptionClick() {
            modal.open('emailSubscriptionModal', {
                componentAttrs: {
                    type: 'adfox',
                },
            });
        }
        /**
         * Adfox onError
         */
        function onError(error) {
            state.withError = true;
            currentError.value = error;
            onComplete({ error, state });
        }
        /**
         * Adfox onStub
         */
        function onStub() {
            state.withStub = true;
            onComplete({ state });
        }
        /**
         * Custom method for tracking all Adfox states
         */
        function onComplete({ data = {}, error = {}, state }) {
            const id = currentId.value;
            const hasData = Object.keys(data).length;
            const hasError = Object.keys(error).length;
            const shouldLog = IS_DEVELOPMENT_MODE && IS_DEBUG_MODE;
            state.isCompleted = true;
            let currentStatus;
            for (const key in state) {
                const hasOwnProp = Object.prototype.hasOwnProperty.call(state, key);
                if (hasOwnProp && state[key]) {
                    currentStatus = STATUSES[key];
                }
            }
            const banner = {
                containerId: currentConfig.value.containerId,
                status: currentStatus,
                config: currentConfig.value,
                state,
                href: location.href,
            };
            let msg = `${id} is completed with status "${currentStatus}". See details on ${ADFOX_DOCS_REF}`;
            if (hasError) {
                msg += ` using the info below:`;
            }
            if (state.withError && !state.isDisabled) {
                window.__ADFOX__.banners.push({
                    ...banner,
                    ...(hasError && { error }),
                });
                if (shouldLog) {
                    console.error(msg, '\nError:', error, '\nConfig:', currentConfig.value, '\nStates', state);
                }
            }
            else if (state.withStub && !state.isDisabled) {
                window.__ADFOX__.banners.push(banner);
                if (shouldLog) {
                    console.warn(msg, '\nConfig:', currentConfig.value, '\nStates', state);
                }
            }
            else if (shouldLog && hasData) {
                console.info(msg, '\nLoaded data:', data);
            }
            emit('complete', { data, error, id, state });
        }
        /**
         * Create Adfox banner
         */
        function createBanner() {
            setTimeout(() => {
                currentConfig.value.params = {
                    ...currentConfig.value.params,
                    ...((context?.app?.$page || context?.nuxtState?.data?.[0]?.$page || window || {}).puids || {}),
                    ...(currentConfig.value.shouldSyncSession && {
                        pr: window.__ADFOX__.pr,
                    }),
                };
                const { adUnit, bannerStates, adaptiveOptions } = currentConfig.value;
                const createMethod = bannerStates || adaptiveOptions ? 'createAdaptive' : 'create';
                if (adUnit) {
                    window.Ya.adfoxCode.hbCallbacks.push(() => {
                        window.Ya.headerBidding.pushAdUnits([
                            {
                                ...adUnit,
                                code: currentId.value,
                            },
                        ]);
                        window.yaContextCb?.push(() => window.Ya?.adfoxCode?.[createMethod](currentConfig.value));
                    });
                }
                else {
                    window.yaContextCb?.push(() => window.Ya?.adfoxCode?.[createMethod](currentConfig.value));
                }
            }, 50);
        }
        /**
         * Clear state
         */
        // function clearState() {
        //   for (const key in state) {
        //     if (Object.prototype.hasOwnProperty.call(state, key)) {
        //       state[key as AdfoxFetchStates] = false
        //     }
        //   }
        // }
        onBeforeUnmount(() => {
            intersectionObserver.value?.disconnect();
            adfoxEl.value?.removeEventListener('click', handleEmailSubscriptionClick);
            if (window.Ya && window.Ya.adfoxCode && typeof window.Ya.adfoxCode.destroy === 'function') {
                window.Ya.adfoxCode.destroy(currentId.value);
            }
        });
        onMounted(() => {
            if (adfoxLoader.isDisabled) {
                state.isDisabled = true;
                return onComplete({ state });
            }
            const handleError = () => {
                state.isUndefined = true;
                return onComplete({ error: { message: 'There is no Ya in window' }, state });
            };
            if (adfoxLoader.error) {
                return handleError();
            }
            watch(() => adfoxLoader.error, (error) => error && handleError());
            watch(shouldRender, (ready) => {
                if (!ready) {
                    return;
                }
                if (!props.config.containerId) {
                    const n = (Math.random() * 100000000).toFixed(0);
                    currentId.value = `adfox_${n}`;
                }
                if (!window.Ya) {
                    state.isUndefined = true;
                    return onComplete({ error: { message: 'There is no Ya in window' }, state });
                }
                if (!window.Ya.adfoxCode) {
                    state.isUndefined = true;
                    return onComplete({ error: { message: 'There is no adfoxCode in window.Ya' }, state });
                }
                createBanner();
            }, {
                immediate: true,
            });
        });
        return {
            ADFOX_DISABLED_MESSAGE,
            IS_DEBUG_MODE,
            IS_DEVELOPMENT_MODE,
            adfoxEl,
            css,
            currentConfig,
            currentData,
            currentError,
            currentId,
            debugComponent,
            isBannerCreated,
            state,
            shouldRender,
        };
    },
});
