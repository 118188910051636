// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yHKb7 .FWs1I:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:rgba(0,130,255,.14)}@media(any-hover:hover){.yHKb7 .FWs1I:hover{background-color:rgba(0,130,255,.14)}}.yHKb7 .FWs1I:not(:hover){background-color:transparent}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isActive": "yHKb7",
	"bookmark": "FWs1I"
};
module.exports = ___CSS_LOADER_EXPORT___;
