import { defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { parseAdfoxHTML } from '~/utils';
export default defineComponent({
    name: 'TheWaterfallItemAdfox',
    props: {
        config: {
            required: true,
            type: Object,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const rootEl = ref();
        const waterfallEntry = ref();
        const isFailed = ref(false);
        function onComplete({ error = {}, state }) {
            if (Object.keys(error).length || (state.isCompleted && !state.isRendered)) {
                isFailed.value = true;
                return;
            }
            if (!rootEl.value) {
                return;
            }
            const adfoxEl = rootEl.value.querySelector('[id^="adfox"]');
            if (!adfoxEl) {
                return;
            }
            // Parse HTMl in Adfox container
            waterfallEntry.value = parseAdfoxHTML(adfoxEl, 'waterfall');
        }
        return {
            css,
            isFailed,
            onComplete,
            rootEl,
            waterfallEntry,
        };
    },
});
