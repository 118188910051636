import { defineStore } from 'pinia';
// import type { DeepRequired } from 'ts-essentials'
// import type { Article } from '~/types'
import { SECTIONS } from '~/constants';
import { useBrandvoices } from '~/composables';
const PATH_PROPS = {
    // articles: [],
    title: '',
    // total: 0,
};
const PATHS = {
    archive: {
        ...PATH_PROPS,
        title: 'Архив',
    },
    rubrics: {
        ...PATH_PROPS,
        title: 'Рубрики',
    },
    ...Object.entries(SECTIONS).reduce((paths, [key, { title }]) => {
        paths[key] = {
            ...PATH_PROPS,
            title,
        };
        return paths;
    }, {}),
    podcasts: {
        ...PATH_PROPS,
        title: 'Подкасты',
    },
    special: {
        ...PATH_PROPS,
        title: 'Спецпроекты',
    },
    brandvoice: {
        ...PATH_PROPS,
        title: 'Блоги',
    },
    ratings: {
        ...PATH_PROPS,
        title: 'Рейтинги',
    },
    video: {
        ...PATH_PROPS,
        title: 'Видео',
    },
};
export const useArchive = defineStore('archive', {
    actions: {
        get(path, prop) {
            if (!path || !(path in this.$state)) {
                return console.error(`There is no ${path} path in archive state`);
            }
            if (!prop || !(prop in this.$state[path])) {
                return console.error(`There is no ${prop} prop in ${path} state`);
            }
            return this[path][prop];
        },
        hasPath(path) {
            return path && path in this.$state;
        },
        set(path, prop, payload) {
            if (!(path in this.$state)) {
                return console.error(`There is no ${path} path in archive state`);
            }
            if (!(prop in this.$state[path])) {
                return console.error(`There is no ${prop} prop in ${path} state`);
            }
            ;
            this[path][prop] = payload;
        },
    },
    state: () => {
        const brandvoices = useBrandvoices();
        return {
            ...PATHS,
            ...brandvoices.entries.reduce((paths, { id, name: title, url_alias: to }) => {
                if (!to) {
                    console.error(`Brandvoice "${title}" with id ${id} has broken url`);
                    return paths;
                }
                paths[to] = {
                    ...PATH_PROPS,
                    title: `${title} Блог`,
                };
                return paths;
            }, {}),
        };
    },
});
