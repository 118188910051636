/**
 * Create folded article body
 * @param body
 */
export function createFoldedArticleBody(body) {
    if (!body || !body.length) {
        return [];
    }
    const foldedBody = [];
    for (let i = 0, l = body.length; i < l; i++) {
        foldedBody.push(body[i]);
        if (body[i].type === 'paragraph') {
            break;
        }
    }
    return foldedBody;
}
