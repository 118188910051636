import { ref } from '@nuxtjs/composition-api';
const articles = ref([]);
const lastIndexes = ref(new Set());
const firstInstanceUid = ref(null);
const isFirstInstanceCompleted = ref(false);
const topArticles = ref([]);
const topArticlesLastIds = ref(new Set());
const trafficArticle = ref(null);
export function useRecommendationBanner() {
    return {
        get articles() {
            return articles.value;
        },
        set articles(value) {
            articles.value = value;
        },
        get firstInstanceUid() {
            return firstInstanceUid.value;
        },
        set firstInstanceUid(value) {
            firstInstanceUid.value = value;
        },
        get isFirstInstanceCompleted() {
            return isFirstInstanceCompleted.value;
        },
        set isFirstInstanceCompleted(value) {
            isFirstInstanceCompleted.value = value;
        },
        get lastIndexes() {
            return lastIndexes.value;
        },
        set lastIndexes(set) {
            lastIndexes.value = set;
        },
        get topArticles() {
            return topArticles.value;
        },
        set topArticles(value) {
            topArticles.value = value;
        },
        get topArticlesLastIds() {
            return topArticlesLastIds.value;
        },
        set topArticlesLastIds(set) {
            topArticlesLastIds.value = set;
        },
        get trafficArticle() {
            return trafficArticle.value;
        },
        set trafficArticle(article) {
            trafficArticle.value = article;
        },
        clear() {
            this.articles = [];
            this.topArticles = [];
            this.lastIndexes = new Set();
            this.topArticlesLastIds = new Set();
            this.firstInstanceUid = null;
            this.isFirstInstanceCompleted = false;
        },
    };
}
