// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".auE9z{display:flex;height:100%;flex-direction:column}.Sep6Z{display:flex;align-items:center;justify-content:space-between;padding:24px 16px}.PYqv7{font-family:\"Merriweather\",serif;font-size:22px;line-height:32px}.AckJL{color:#c3c3c3;cursor:pointer;transition:color .3s}.AckJL:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#fff}@media(any-hover:hover){.AckJL:hover{color:#fff}}.Ss3y6{display:flex;flex-direction:column;padding:0;margin:0;list-style:none}.-Bwm2:not(:last-child){margin-bottom:24px}._5L4wt{display:flex;align-items:center;padding:0 16px;color:#fff;cursor:pointer;font-size:18px;line-height:32px;transition:color .3s}@media screen and (min-width:768px){._5L4wt{font-size:16px;line-height:24px}}._5L4wt svg{margin-right:24px;color:#c3c3c3;transition:color .3s}._5L4wt:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#c3c3c3}._5L4wt:active svg{color:#fff}@media(any-hover:hover){._5L4wt:hover{color:#c3c3c3}._5L4wt:hover svg{color:#fff}}.auE9z hr{width:100%;height:1px;border:none;margin:24px 0;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-color:#2d2d2d;outline:none}.tnQfJ{display:flex;flex-direction:column}.tnQfJ button{margin:0 16px}.Uv26k{display:flex;flex:1;flex-direction:column;justify-content:flex-end}@media screen and (min-width:768px){.Uv26k{justify-content:flex-start}}.JAVIf{margin-bottom:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "auE9z",
	"header": "Sep6Z",
	"headerTitle": "PYqv7",
	"headerClose": "AckJL",
	"entries": "Ss3y6",
	"entry": "-Bwm2",
	"entryLink": "_5L4wt",
	"subscribe": "tnQfJ",
	"footer": "Uv26k",
	"logout": "JAVIf"
};
module.exports = ___CSS_LOADER_EXPORT___;
