export function addUtm(component, space, isMobile) {
    // if (!component?.to) {
    //   return component
    // }
    //
    // const term = component.to.startsWith('https://') ? new URL(component.to).host : getLastPath(component.to)
    // const utmMedium = isMobile ? 'mobile' : 'desktop'
    // const utmUrl = `${
    //   component.to.endsWith('/') ? '' : '/'
    // }?utm_source=forbes&utm_space=${space}&utm_medium=${utmMedium}&utm_term=${term}`
    //
    // if ((component.attrs.to ?? component.attrs.href).includes(utmUrl)) {
    //   return component
    // }
    //
    // if (component.component === 'NLink') {
    //   component.attrs.to += `${utmUrl}`
    // } else {
    //   component.attrs.href += `${utmUrl}`
    // }
    return component;
}
//
// function getLastPath(str: string) {
//   let arr = str.split('/')
//   arr = arr.filter((chunk) => chunk)
//   return arr[arr.length - 1]
// }
