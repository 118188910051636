import { defineComponent, onBeforeUnmount, onMounted, ref, useCssModule } from '@nuxtjs/composition-api';
import { useTask } from 'vue-concurrency';
import { CUSTOM_ITEMS, EXCLUDED_BRANDVOICES, MAX_ITEMS_COUNT, TRIGGER_INTERVAL_TIME } from '.';
import { useDuck } from '~/services';
export default defineComponent({
    name: 'TheCarousel',
    setup() {
        let triggerInterval = 0;
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Duck service.
         */
        const duck = useDuck();
        /**
         * Items.
         */
        const items = ref([]);
        /**
         * Current index.
         */
        const currentIndex = ref(0);
        function createInterval() {
            return window.setInterval(() => {
                if (currentIndex.value < items.value.length - 1) {
                    currentIndex.value++;
                }
                else {
                    currentIndex.value = 0;
                }
            }, TRIGGER_INTERVAL_TIME);
        }
        /**
         * Fetch items task.
         */
        const fetchItemsTask = useTask(function* () {
            window.clearInterval(triggerInterval);
            try {
                items.value = yield duck.pub.getPubListById('up-waterfall').then(({ data }) => {
                    return (data?.articles
                        ?.filter(({ data }) => {
                        const slug = data?.brandvoice?.[0]?.url?.replace('brandvoice/', '') || '';
                        return !EXCLUDED_BRANDVOICES.includes(slug);
                    })
                        ?.map(({ data }) => {
                        const { name, url } = data?.brandvoice?.[0] || {};
                        return {
                            ...data,
                            heading: {
                                title: name || '',
                                to: `/${url || 'brandvoice'}`,
                            },
                        };
                    }) || []);
                });
            }
            catch (error) {
                console.error(error);
            }
            finally {
                items.value = [...CUSTOM_ITEMS, ...items.value].slice(0, MAX_ITEMS_COUNT);
                triggerInterval = createInterval();
            }
        });
        function goBackward() {
            window.clearInterval(triggerInterval);
            currentIndex.value = currentIndex.value > 0 ? currentIndex.value - 1 : items.value.length - 1;
            triggerInterval = createInterval();
        }
        function goForward() {
            window.clearInterval(triggerInterval);
            currentIndex.value = currentIndex.value < items.value.length - 1 ? currentIndex.value + 1 : 0;
            triggerInterval = createInterval();
        }
        function isExternalLink(url) {
            return url.startsWith('http');
        }
        onBeforeUnmount(() => {
            window.clearInterval(triggerInterval);
        });
        onMounted(() => {
            fetchItemsTask.perform();
        });
        return {
            css,
            currentIndex,
            fetchItemsTask,
            isExternalLink,
            goBackward,
            goForward,
            items,
        };
    },
});
