import dayjs from 'dayjs'

import 'dayjs/locale/ru'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)

dayjs.locale('ru')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
