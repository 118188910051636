// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._41ro5{position:relative}.zDzOk{position:fixed;z-index:10;bottom:0;left:0;width:100%;height:calc(100vh - 48px);height:calc(var(--vh, 1vh)*100 - 48px);background:#181716;opacity:.5}.zDzOk.P\\+Z3q{z-index:1000001;height:100%}.Oa7f3{display:flex;min-width:var(--trigger-width);min-height:var(--trigger-height);align-items:center;justify-content:center;cursor:pointer;line-height:0}.jzFas{position:fixed;z-index:1000001;bottom:0;left:0;display:flex;overflow:hidden;width:100%;max-height:calc(100vh - 112px);max-height:calc(var(--vh, 1vh)*100 - 112px);flex-flow:column;justify-content:flex-end;background:#232323;border-radius:16px 16px 0 0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.jzFas a{color:#0082ff;cursor:pointer;text-decoration:none;transition:color .2s}.jzFas a:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#006ee1}@media(any-hover:hover){.jzFas a:hover{color:#006ee1}}.jzFas.MGjLy{width:calc(100% - 16px);margin:8px;border-radius:16px}.xWrbJ{display:flex;align-items:center;justify-content:space-between;padding:8px 8px 8px 16px}.PVVi0 .xWrbJ{padding-top:12px;padding-bottom:12px;border-bottom:1px solid #2d2d2d}.PJn2O{color:#989898;font-size:16px;line-height:24px}.lyYTS{width:32px;height:32px;color:#989898}.lyYTS>svg{transform:scale(.8333333333)}.VmRxP{width:100%;height:100%;overflow-x:hidden;overflow-y:auto}.VmRxP.o\\+O7h{padding:16px 16px 24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_41ro5",
	"blackout": "zDzOk",
	"withFullBlackout": "P+Z3q",
	"trigger": "Oa7f3",
	"tooltip": "jzFas",
	"withGap": "MGjLy",
	"header": "xWrbJ",
	"withTitle": "PVVi0",
	"title": "PJn2O",
	"reset": "lyYTS",
	"body": "VmRxP",
	"withBodyPadding": "o+O7h"
};
module.exports = ___CSS_LOADER_EXPORT___;
