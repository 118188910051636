import { computed, defineComponent, onBeforeUnmount, onMounted, ref, useContext, useCssModule, watch, } from '@nuxtjs/composition-api';
import { DEFAULT_OPTIONS } from '.';
export default defineComponent({
    name: 'Tooltip',
    props: {
        options: {
            default: () => ({}),
            type: Object,
        },
    },
    emits: ['toggle'],
    setup(props, { emit }) {
        /**
         * Composables.
         */
        const context = useContext();
        const css = useCssModule();
        const device = context.$device;
        /**
         * Current options
         */
        const currentOptions = computed(() => {
            return {
                ...DEFAULT_OPTIONS,
                ...props.options,
            };
        });
        const triggerEl = ref();
        const rootEl = ref();
        const tooltipRef = ref();
        const tooltipEl = computed(() => tooltipRef.value?.$el);
        const triggerRect = ref({
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
        });
        const isOpened = ref(false);
        const isOnTrigger = ref(false);
        const isOnTooltip = ref(false);
        const events = computed(() => {
            return {
                click: {
                    add: () => {
                        if (device.isDesktop) {
                            window.addEventListener('click', handleClickOutside);
                            window.addEventListener('keyup', handleKeyup);
                            triggerEl.value?.addEventListener('click', toggleTooltip);
                            return;
                        }
                        window.addEventListener('touchend', handleClickOutside);
                        triggerEl.value?.addEventListener('touchend', toggleTooltip);
                    },
                    remove: () => {
                        window.removeEventListener('click', handleClickOutside);
                        window.removeEventListener('keyup', handleKeyup);
                        window.removeEventListener('touchend', handleClickOutside);
                        triggerEl.value?.removeEventListener('click', toggleTooltip);
                        triggerEl.value?.removeEventListener('touchend', toggleTooltip);
                    },
                },
                hover: {
                    add: () => {
                        if (device.isDesktop) {
                            triggerEl.value?.addEventListener('mouseenter', handleMouseenter);
                            triggerEl.value?.addEventListener('mouseleave', handleMouseleave);
                            tooltipEl.value?.addEventListener('mouseenter', handleTooltipMouseenter);
                            tooltipEl.value?.addEventListener('mouseleave', handleTooltipMouseleave);
                            return;
                        }
                        window.addEventListener('touchend', handleClickOutside);
                        triggerEl.value?.addEventListener('touchend', toggleTooltip);
                    },
                    remove: () => {
                        triggerEl.value?.removeEventListener('mouseenter', handleMouseenter);
                        triggerEl.value?.removeEventListener('mouseleave', handleMouseleave);
                        tooltipEl.value?.removeEventListener('mouseenter', handleTooltipMouseenter);
                        tooltipEl.value?.removeEventListener('mouseleave', handleTooltipMouseleave);
                        window.removeEventListener('touchend', handleClickOutside);
                        triggerEl.value?.removeEventListener('touchend', toggleTooltip);
                    },
                },
            };
        });
        function handleBlackout() {
            isOpened.value = false;
        }
        function handleClickOutside({ target }) {
            const targetEl = target;
            if (!tooltipEl.value?.contains(targetEl)) {
                isOpened.value = false;
            }
        }
        function handleKeyup({ code = '' }) {
            if (code === 'Escape') {
                isOpened.value = false;
            }
        }
        function handleScroll() {
            isOpened.value = false;
        }
        function toggleTooltip(event) {
            event?.stopPropagation();
            isOpened.value = !isOpened.value;
        }
        function handleMouseenter() {
            isOnTrigger.value = true;
            isOpened.value = true;
        }
        async function handleMouseleave() {
            isOnTrigger.value = false;
            await new Promise((resolve) => setTimeout(resolve, 100));
            isOpened.value = isOnTooltip.value;
        }
        async function handleTooltipMouseenter() {
            isOnTooltip.value = true;
            isOpened.value = true;
        }
        async function handleTooltipMouseleave() {
            isOnTooltip.value = false;
            await new Promise((resolve) => setTimeout(resolve, 100));
            isOpened.value = isOnTrigger.value;
        }
        onBeforeUnmount(() => {
            for (const event of Object.values(events.value)) {
                event.remove();
            }
        });
        onMounted(async () => {
            // Wait for tooltipEl
            await new Promise((resolve) => setTimeout(resolve, 100));
            events.value[currentOptions.value.event].add();
            watch(isOpened, () => {
                emit('toggle', isOpened.value);
                if (isOpened.value) {
                    if (currentOptions.value.shouldCloseOnScroll) {
                        window.addEventListener('scroll', handleScroll);
                    }
                    if (currentOptions.value.shouldAppendToBody) {
                        window.requestAnimationFrame(() => {
                            const { bottom, height, left, right, top, width } = triggerEl.value?.getBoundingClientRect() || {};
                            triggerRect.value = {
                                bottom,
                                height,
                                left,
                                right,
                                top,
                                width,
                            };
                        });
                    }
                    return;
                }
                window.removeEventListener('scroll', handleScroll);
            });
        });
        return {
            css,
            currentOptions,
            triggerEl,
            triggerRect,
            handleBlackout,
            isOpened,
            isOnTrigger,
            isOnTooltip,
            rootEl,
            toggleTooltip,
            tooltipRef,
            tooltipEl,
        };
    },
});
