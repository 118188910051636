// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._75pTo{position:relative}.vzyUL{display:flex;overflow:hidden;min-height:274px;flex-flow:column;align-items:center;margin:24px -16px 0;background:#e2e2e2}@media screen and (min-width:768px){.vzyUL{min-height:224px;align-items:flex-start;padding-bottom:32px}}@media screen and (min-width:768px){._75pTo .w9uAd{width:100%}}._75pTo img{height:100%!important;-o-object-fit:cover;object-fit:cover}._75pTo [id^=adfox]{width:100%!important;height:100%;pointer-events:none}@media screen and (min-width:768px){._75pTo [id^=adfox]{pointer-events:auto}}._75pTo .Olm8p [id^=adfox]{pointer-events:auto}@media screen and (min-width:768px){._75pTo [id^=adfox]>*{max-width:240px;margin:0!important}}@media screen and (min-width:768px){._75pTo.\\+8QzD .w9uAd{width:240px;height:160px}}.z22Uc{color:#989898;font-size:12px;line-height:24px}@media screen and (min-width:768px){.z22Uc{padding-left:16px;line-height:32px}}.RXzTe{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theWaterfallAdfox": "_75pTo",
	"banner": "vzyUL",
	"adfox": "w9uAd",
	"shouldEnablePointerEvents": "Olm8p",
	"isInformer": "+8QzD",
	"title": "z22Uc",
	"loader": "RXzTe"
};
module.exports = ___CSS_LOADER_EXPORT___;
