export const RATINGS = {
    russia: {
        id: 382,
        title: 'Россия',
    },
    moscowRegion: {
        id: 383,
        title: 'Подмосковье',
    },
};
