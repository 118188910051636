// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xa50j{display:block;background-color:#f4e600}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "xa50j"
};
module.exports = ___CSS_LOADER_EXPORT___;
