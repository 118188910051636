import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, onServerPrefetch, reactive, ref, useContext, useCssModule, watch, } from '@nuxtjs/composition-api';
import { DESKTOP_MENU_BAR_LIMIT } from './';
import { MENUS_SLUGS_BY_BREAKPOINTS } from '~/constants';
import { useMenus, useModal, useUser } from '~/composables';
import { isFlutter, isLkEnabled, addUtm } from '~/utils';
export default defineComponent({
    name: 'TheHeader',
    setup() {
        const context = useContext();
        const css = useCssModule();
        const menus = useMenus();
        const modal = useModal();
        const user = useUser();
        const viewport = context.$viewport;
        const isMobile = viewport.isLessThan('desktopMedium');
        const isMounted = ref(false);
        const rootEl = ref();
        const rootStyle = ref();
        const scrollbarWidth = ref(0);
        const shouldRenderAds = computed(() => !isFlutter(context.$device.userAgent));
        /**
         * Menu bar items
         */
        const menuBar = computed(() => [
            menus.rubrics,
            ...(menus.main?.items
                ?.map((item) => {
                return addUtm(item, 'header', isMobile);
            })
                ?.slice(0, DESKTOP_MENU_BAR_LIMIT) || []),
        ]);
        /**
         * Menu items
         */
        const menu = computed(() => {
            return viewport.match('desktopMedium')
                ? menuBar.value
                : [...(menuBar.value || []), menus.projects, menus.events, menus.brandvoice];
        });
        /**
         * Under menu items
         */
        const menuUnder = computed(() => {
            return [...(menus?.under?.items?.map((item) => addUtm(item, 'under-header', isMobile)) || [])];
        });
        /**
         * Pullout menu
         */
        const menuPullout = computed(() => [menus.projects, menus.events, menus.brandvoice]);
        /**
         * Mobile pullout menu
         */
        const menuPulloutMobile = computed(() => [
            menus.rubrics,
            ...menus.pollout.items,
            menus.events,
            menus.brandvoice,
            menus.info,
            menus.social,
        ]);
        /**
         * Pullout.
         */
        const pullout = reactive({
            /**
             * Pullout data.
             */
            data: {
                brandvoice: null,
                menu: null,
                rubrics: null,
                projects: null,
                search: null,
                waterfall: null,
            },
            /**
             * Pullout Menu.
             */
            menu: ['brandvoice', 'events', 'rubrics', 'projects'],
            /**
             * Pullout visibility.
             */
            isVisible: false,
            /**
             * Pullout type.
             */
            type: null,
        });
        const isSearchVisible = ref(false);
        const isSubscribeBarVisible = computed(() => !pullout.isVisible && viewport.isLessThan('tablet'));
        const isLkMode = computed(() => isLkEnabled(process.server ? context.ssrContext?.req?.headers.cookie : document.cookie));
        /**
         * Returns true if current pullout type is the same as the argument one & is visible.
         */
        const isPulloutActive = computed(() => {
            return (type) => pullout.type === type && pullout.isVisible;
        });
        /**
         * Handles logo "click" event.
         */
        function handleLogoClick() {
            const { path } = context.route.value;
            if (path !== '/') {
                return;
            }
            window.scrollTo({ behavior: 'smooth', top: 0 });
        }
        /**
         * Handles window "click" & "touchstart" event.
         */
        function handleClickOutside({ target }) {
            if (!rootEl.value || !target || viewport.isLessThan('tablet')) {
                return;
            }
            if (rootEl.value?.contains(target)) {
                return;
            }
            pullout.isVisible = false;
            isSearchVisible.value = false;
        }
        /**
         * Handles window "keyup" event.
         */
        function handleKeyupEscape({ code }) {
            if (code && code === 'Escape') {
                pullout.isVisible = false;
                isSearchVisible.value = false;
            }
        }
        /**
         * Toggles pullout visibility.
         */
        function togglePullout(type) {
            if (pullout.type === type) {
                pullout.isVisible = !pullout.isVisible;
            }
            else {
                pullout.isVisible = true;
            }
            pullout.type = type || null;
        }
        /**
         * Toggles between main bar and search bar.
         */
        function toggleSearch() {
            isSearchVisible.value = !isSearchVisible.value;
            window.requestAnimationFrame(() => togglePullout('search'));
        }
        /**
         * Triggers search from menu on mobile.
         */
        function handleMobileSearchFocus() {
            isSearchVisible.value = true;
            if (pullout.type === 'search') {
                return;
            }
            window.requestAnimationFrame(() => togglePullout('search'));
        }
        /**
         * Triggers menu from search on mobile.
         */
        function handleMobileSearchClose() {
            isSearchVisible.value = false;
            window.requestAnimationFrame(() => togglePullout('menu'));
        }
        /**
         * Triggers viewport blackout.
         */
        function handleBlackout() {
            pullout.isVisible = false;
            isSearchVisible.value = false;
        }
        /**
         * Get platform-specific scroll bar width
         */
        function getScrollbarWidth() {
            const div = document.createElement('div');
            div.style.position = 'absolute';
            div.style.overflowY = 'scroll';
            div.style.width = '50px';
            div.style.height = '50px';
            div.style.visibility = 'hidden';
            document.body.appendChild(div);
            const scrollbarWidth = div.offsetWidth - div.clientWidth || 0;
            document.body.removeChild(div);
            return scrollbarWidth;
        }
        /**
         * Triggers on pullout watch listeners.
         */
        function handlePullout() {
            if (!pullout.isVisible) {
                return;
            }
            if (viewport.isGreaterThan('desktop')) {
                if (pullout.type === 'waterfall') {
                    togglePullout('waterfall');
                }
            }
            else if (pullout.type === 'rubrics') {
                // Switch to menu.
                pullout.type = 'menu';
            }
        }
        function handleTogglePullout(event) {
            const type = event.detail;
            togglePullout(type);
        }
        /**
         * Fetch menus
         */
        async function fetchMenus() {
            const slugs = MENUS_SLUGS_BY_BREAKPOINTS[viewport.breakpoint];
            await menus.fetchMenusBySlugs(slugs);
        }
        onBeforeUnmount(() => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('touchstart', handleClickOutside);
            window.removeEventListener('keyup', handleKeyupEscape);
            window.removeEventListener('togglePullout', handleTogglePullout);
        });
        onServerPrefetch(async () => {
            await fetchMenus();
        });
        onBeforeMount(async () => {
            await fetchMenus();
        });
        onMounted(async () => {
            window.addEventListener('click', handleClickOutside);
            window.addEventListener('touchstart', handleClickOutside);
            window.addEventListener('keyup', handleKeyupEscape);
            window.addEventListener('togglePullout', handleTogglePullout);
            /**
             * Get scrollbar
             */
            scrollbarWidth.value = getScrollbarWidth();
            // When the breakpoint updates, it should
            // fetch menus, close the pullout and update scrollbar width
            watch(() => viewport.breakpoint, async () => {
                await fetchMenus();
                handlePullout();
                scrollbarWidth.value = getScrollbarWidth();
            });
            // It should fix the scroll for mobile breakpoint.
            watch(() => pullout.isVisible, handlePullout);
            context.app?.router?.beforeEach((_to, _from, next) => {
                isSearchVisible.value = pullout.isVisible = false;
                next();
            });
            setTimeout(() => (isMounted.value = true));
        });
        return {
            css,
            handleBlackout,
            handleLogoClick,
            handleMobileSearchClose,
            handleMobileSearchFocus,
            isLkMode,
            isMounted,
            isPulloutActive,
            isSearchVisible,
            isSubscribeBarVisible,
            menu,
            menuBar,
            menuPullout,
            menuPulloutMobile,
            menus,
            modal,
            pullout,
            rootEl,
            rootStyle,
            scrollbarWidth,
            shouldRenderAds,
            togglePullout,
            toggleSearch,
            menuUnder,
            user,
            viewport,
        };
    },
});
