export function humanizeSeconds(seconds) {
    let result = '';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours) {
        result += `${hours} ч.  `;
    }
    if (minutes) {
        result += `${minutes} мин.`;
    }
    return result;
}
