import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ReadMore',
    props: {
        size: {
            default: 'default',
            type: String,
            validator(value) {
                return ['default', 'small'].includes(value);
            },
        },
        tag: {
            default: 'div',
            type: String,
        },
        to: {
            default: null,
            type: [String, null],
        },
        withChevron: {
            default: true,
            type: Boolean,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            css,
        };
    },
});
