import { computed, defineComponent, useContext, useCssModule, useRouter } from '@nuxtjs/composition-api';
import { MENU_PROFILE } from './';
import { useUser } from '~/composables';
import { isLkEnabled } from '~/utils';
export default defineComponent({
    name: 'TheMenuProfile',
    emits: ['close'],
    setup(_, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const router = useRouter();
        const context = useContext();
        const isLkMode = computed(() => isLkEnabled(process.server ? context.ssrContext?.req?.headers.cookie : document.cookie));
        const user = useUser();
        function onLogout() {
            router.push('/');
            user.logout();
            emit('close');
        }
        return {
            css,
            isLkMode,
            MENU_PROFILE,
            onLogout,
            user,
        };
    },
});
