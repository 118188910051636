import { defineComponent, onMounted, ref, useCssModule, watch } from '@nuxtjs/composition-api';
import { useTask } from 'vue-concurrency';
import { ARTICLES_TYPES as BASE_ARTICLES_TYPES, useSearch } from '~/composables';
import { useDuck } from '~/services';
const ARTICLES_TYPES = BASE_ARTICLES_TYPES.reduce((types, { title, value }, i) => {
    if (value === 'total') {
        return types;
    }
    types[value] = [i - 1, title];
    return types;
}, {});
export default defineComponent({
    name: 'TheSearch',
    props: {
        isPulloutSearchActive: {
            default: false,
            type: Boolean,
        },
    },
    emits: ['close'],
    setup(props, { refs, emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const search = useSearch();
        const currentValue = ref('');
        const advancedValue = ref('');
        const topicList = ref([]);
        const timeout = ref();
        const withResults = ref(false);
        const notFound = ref(false);
        const duck = useDuck();
        const popular = ref([]);
        let input;
        const isLoading = ref(true);
        function handleAdvancedSearch() {
            if (!advancedValue.value) {
                return;
            }
            search.set('query', advancedValue.value);
            search.set('fromHeader', true);
        }
        function handleInput(event) {
            const target = event.target;
            const { value } = target;
            clearTimeout(timeout.value);
            timeout.value = window.setTimeout(async () => {
                isLoading.value = true;
                currentValue.value = value;
                if (value.length) {
                    topicList.value = await searchTopicList.perform();
                    notFound.value = topicList.value.length === 0;
                    withResults.value = !notFound.value;
                }
                else {
                    topicList.value = await defaultTopicList.perform();
                    notFound.value = false;
                    withResults.value = false;
                }
                isLoading.value = false;
            }, 500);
        }
        function close() {
            emit('close');
        }
        function clear() {
            input = refs.input;
            input.value = '';
            advancedValue.value = currentValue.value;
            currentValue.value = '';
            withResults.value = false;
            isLoading.value = false;
            notFound.value = false;
            topicList.value = popular.value;
        }
        /**
         * The search should be cleared when:
         * 1) the close button is clicked
         * 2) the blackout is clicked
         */
        watch(() => props.isPulloutSearchActive, clear);
        const defaultTopicList = useTask(function* () {
            try {
                const { data = [] } = yield duck.pub.getPubPopular({
                    limit: 4,
                });
                const topic = {
                    results: data.map((item) => {
                        return {
                            description: item.title,
                            descriptionTo: `/${item.url}`,
                        };
                    }),
                    title: 'Актуальные темы',
                };
                popular.value = [topic];
                return popular.value;
            }
            catch (error) {
                console.error(error);
            }
        });
        const searchTopicList = useTask(function* () {
            try {
                const { data: { results = [] }, } = yield duck.pub.findArticles({
                    'search[term]': currentValue.value,
                });
                if (!results.length) {
                    return [];
                }
                const topics = Object.values(ARTICLES_TYPES).reduce((acc, [_, title]) => {
                    acc.push({
                        results: [],
                        title,
                    });
                    return acc;
                }, []);
                for (const result of results) {
                    const [index] = ARTICLES_TYPES[result.type] || ARTICLES_TYPES.news;
                    const topic = topics[index];
                    if (!topic) {
                        continue;
                    }
                    topic.results.push({
                        // title: result.title,
                        // titleTo: `/${result.url_alias}`,
                        description: result.title,
                        descriptionTo: `/${result.url_alias}`,
                    });
                }
                return topics;
            }
            catch (error) {
                console.error(error);
            }
        });
        onMounted(async () => {
            topicList.value = await defaultTopicList.perform();
            isLoading.value = false;
        });
        return {
            advancedValue,
            close,
            css,
            currentValue,
            handleAdvancedSearch,
            handleInput,
            isLoading,
            notFound,
            search,
            topicList,
            withResults,
        };
    },
});
