import { defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { parseAdfoxHTML } from '~/utils';
import { ADFOX_FETCH_STATES } from '~/components/Adfox';
import { SHOULD_DISABLE } from '~/plugins/adfox';
export default defineComponent({
    name: 'TheWaterfallAdfox',
    props: {
        config: {
            required: true,
            type: Object,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Adfox article-like data
         */
        const adfoxArticle = ref();
        /**
         * Banner element
         */
        const bannerEl = ref();
        /**
         * Adfox states
         */
        const currentStates = ref({ ...ADFOX_FETCH_STATES });
        /**
         * Informer indicator.
         */
        const isInformer = ref(false);
        /**
         * Should render.
         */
        const shouldRender = ref(!SHOULD_DISABLE);
        /**
         * Should enable pointer-events
         */
        const shouldEnablePointerEvents = ref(false);
        /**
         * Touch event timeout
         */
        const touchTimeout = ref(0);
        /**
         * Adfox onComplete
         */
        function onComplete({ data = {}, state }) {
            shouldRender.value = !(state.withError || state.withStub);
            if (!shouldRender.value) {
                return;
            }
            currentStates.value = state;
            if (!bannerEl.value) {
                return;
            }
            isInformer.value = data?.bundleName === 'banner.transfer';
            if (isInformer.value) {
                const adfoxEl = bannerEl.value.querySelector('[id^="adfox"]');
                if (adfoxEl && state.isRendered) {
                    // Parse HTMl in Adfox container
                    adfoxArticle.value = parseAdfoxHTML(adfoxEl);
                }
            }
            bannerEl.value.style.minHeight = 'auto';
            bannerEl.value.style.minHeight = bannerEl.value.clientHeight + 'px';
        }
        /**
         * Handles touchend event
         */
        function onTouchend() {
            clearTimeout(touchTimeout.value);
            shouldEnablePointerEvents.value = true;
            touchTimeout.value = window.setTimeout(() => {
                shouldEnablePointerEvents.value = false;
            }, 500);
        }
        return {
            adfoxArticle,
            bannerEl,
            css,
            currentStates,
            isInformer,
            onComplete,
            onTouchend,
            shouldEnablePointerEvents,
            shouldRender,
        };
    },
});
