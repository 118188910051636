import { defineNuxtMiddleware } from '@nuxtjs/composition-api';
import { useUser } from '~/composables';
export default defineNuxtMiddleware(({ redirect, route }) => {
    const user = useUser();
    const component = route.matched[0]?.components?.default;
    // Если у страницы нет кастомного поля auth, то на ней не нужно проверять авторизацию
    if (!component || !component.extendOptions.auth) {
        return;
    }
    if (!user.isAuthorized) {
        // Если юзер не авторизован, делаем редирект на главную,
        // открываем модалку авторизации и сохраняем роут,
        // с которого пришли, чтобы перенаправить обратно после авторизации
        return redirect(`/?modal=sign-in&next=${route.fullPath}`);
    }
});
