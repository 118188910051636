import { getUTCTime } from '~/utils';
export function createArticleSchema({ authors = [], body = [], changed = 0, gallery = [], photo = {}, sections = [], subtitle = '', tags = [], time = 0, title = '', url_alias: path = '', }) {
    const context = this;
    const componentInstance = this;
    const fullUrl = `${context.env.HOST}/${path || ''}`;
    const datePublished = getUTCTime.call(componentInstance, time);
    const dateModified = changed < time ? datePublished : getUTCTime.call(componentInstance, changed);
    return {
        '@context': 'https://schema.org',
        '@graph': [
            {
                ...(sections.length && {
                    '@type': 'BreadcrumbList',
                    itemListElement: createItemListElementSchema.call(context, sections),
                }),
            },
            {
                '@id': fullUrl,
                '@type': 'NewsArticle',
                about: tags.map(({ name }) => ({ '@type': 'CreativeWork', name })) || [],
                articleSection: sections?.map(({ name }) => name) || [],
                author: createAuthorsSchemas.call(context, authors),
                datePublished,
                dateModified,
                headline: title || '',
                image: createImageSchema.call(context, photo),
                text: createTextSchema({ body, gallery, subtitle }),
                url: fullUrl,
                mainEntityOfPage: {
                    '@id': fullUrl,
                    '@type': 'WebPage',
                },
                publisher: {
                    '@type': 'Organization',
                    logo: {
                        '@type': 'ImageObject',
                        url: `${context.env.HOST}/logo.svg`,
                        width: 64,
                        height: 16,
                    },
                    name: 'Forbes.ru',
                },
            },
        ],
    };
}
function createItemListElementSchema(sections) {
    if (!sections?.length) {
        return [];
    }
    return sections.map(({ name, url: path }) => ({
        '@type': 'ListItem',
        position: 1,
        item: {
            '@id': `${this.env.HOST}/${path}`,
            name,
        },
    }));
}
function createAuthorsSchemas(authors) {
    if (!authors || !authors.length) {
        return {
            '@type': 'Organization',
            name: 'Forbes',
        };
    }
    if (authors.length === 1) {
        return createAuthorSchema.call(this, authors[0]);
    }
    return authors.map((author) => createAuthorSchema.call(this, author));
}
function createAuthorSchema({ photo, title, subtitle, url_alias: path }) {
    return {
        '@type': 'Person',
        name: title,
        affiliation: 'Forbes',
        jobTitle: subtitle,
        url: `${this.env.HOST}/${path}`,
        image: (photo || {}).uri || '',
    };
}
function createImageSchema(photo = {}) {
    const { metadata = {}, uri = '' } = photo || {};
    const { width = 1200, height = 630 } = metadata || {};
    return {
        '@type': 'ImageObject',
        url: uri || `${this.env.HOST}/share-placeholder.png`,
        width,
        height,
    };
}
function createTextSchema({ body, gallery, subtitle }) {
    let text = subtitle ? subtitle + '. ' : '';
    if (body?.length) {
        text += getBodyText(body);
    }
    if (gallery?.length) {
        text += gallery.reduce((galleryText, { text: body }) => {
            if (!body) {
                return galleryText;
            }
            galleryText += getBodyText(body);
            return galleryText;
        }, '');
    }
    return text;
}
function getBodyText(body) {
    return body.reduce((bodyText, box) => {
        if (box?.data?.text) {
            bodyText += trimTags(box.data.text);
        }
        if (box?.type === 'list') {
            const items = box?.data?.items || [];
            if (!items.length) {
                return bodyText;
            }
            bodyText += items.reduce((listItemText, item) => {
                listItemText += trimTags(item);
                return listItemText;
            }, '');
        }
        return bodyText;
    }, '');
}
function trimTags(string) {
    if (!string) {
        return ' ';
    }
    return string.replace(/(<([^>]+)>)/gi, '') + ' ';
}
