const COMMENT_NODE_TYPE = 8;
/**
 * Parse Adfox innerHTML
 * @param adfoxEl
 * @param type
 */
export function parseAdfoxHTML(adfoxEl, type = 'article') {
    const fields = Array.from(adfoxEl.childNodes)
        .filter(({ nodeType }) => nodeType === COMMENT_NODE_TYPE)
        .map(({ textContent }) => textContent?.trim() || '') || [];
    if (!fields.length) {
        return { type: 'unknown' };
    }
    if (type === 'waterfall') {
        return createWaterfallItemData(fields);
    }
    else {
        return createArticleData(fields);
    }
}
/**
 * Create article-like data
 * @param fields
 */
function createArticleData(fields) {
    return fields.reduce((acc, field) => {
        if (field) {
            const { key, value } = getKeyAndValue(field);
            acc.id = parseInt((Math.random() * 100000000).toFixed(0), 10);
            switch (key) {
                case 'advertiser':
                    acc.ad = acc.ad || {};
                    acc.ad.title = value || '';
                    break;
                case 'advertiser-path':
                    acc.ad = acc.ad || {};
                    acc.ad.to = value || '';
                    break;
                case 'path':
                    acc.path = value || '';
                    acc.url_alias = value || '';
                    break;
                case 'rubric':
                    // acc.rubrics = [setCase(value) || 'Партнерский материал']
                    acc.rubrics = [setCase(value) || 'Спецпроект'];
                    break;
                case 'photo':
                    acc.photo_present = !!value;
                    acc.photo = {
                        ...acc.photo,
                        uri: value || '',
                    };
                    break;
                default:
                    if (value) {
                        acc[key] = value;
                    }
            }
        }
        return acc;
    }, {});
}
/**
 * Create waterfall-entry-like data
 * @param fields
 */
function createWaterfallItemData(fields) {
    return fields.reduce((acc, field) => {
        if (field) {
            const { key, value } = getKeyAndValue(field);
            switch (key) {
                case 'advertiser':
                    acc.ad = acc.ad || {};
                    acc.ad.title = value || '';
                    break;
                case 'advertiser-path':
                    acc.ad = acc.ad || {};
                    acc.ad.to = value || '';
                    break;
                case 'path':
                    acc.url = value || '';
                    break;
                default:
                    if (value) {
                        acc[key] = value;
                    }
            }
        }
        return acc;
    }, {});
}
/**
 * Get key and value from field
 * @param string
 */
function getKeyAndValue(string) {
    const chunks = string.split(': ');
    let key = '';
    let value = '';
    if (chunks[0]) {
        key = chunks[0].trim();
    }
    if (chunks.length > 1) {
        value = chunks
            .splice(1)
            .reduce((string, chunk, i, array) => {
            string += chunk;
            if (i !== array.length - 1) {
                string += ': ';
            }
            return string;
        }, '')
            .trim();
    }
    return {
        key,
        value,
    };
}
/**
 * Sets upper case to first letter and lower case to next ones in all words
 * @param value
 */
function setCase(value) {
    if (!value) {
        return '';
    }
    return value.split(' ').reduce((words, word) => {
        words += ' ' + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        return words.trim();
    }, '');
}
