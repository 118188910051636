import { DEFAULT_OPTIONS as CardListMobileOptions } from '~/components/CardListMobile';
/**
 * Returns a preset based on breakpoint.
 *
 * @param presets
 * @param columns
 */
export function useGrid(presets, columns) {
    return (breakpoint) => presets[columns[breakpoint] - 1] || [];
}
/**
 * Returns a chunks of articles based on the size.
 */
export function createGridChunks(articles, size) {
    let i = 0;
    const chunks = [];
    const n = articles.length;
    while (i < n) {
        chunks.push(articles.slice(i, (i += size)));
    }
    return chunks;
}
/**
 * Returns a number of articles in current grid.
 */
export function getArticlesSize(breakpoint, { columns, presets = [], }) {
    const index = (columns[breakpoint] || 3) - 1;
    return (presets[index] || []).reduce((length, card) => {
        if (card.type.includes('slider')) {
            return length;
        }
        if (card.type === 'list-mobile') {
            length += card?.options?.length || CardListMobileOptions.length;
            return length;
        }
        length++;
        return length;
    }, 0);
}
