import { computed, defineComponent, onBeforeUnmount, onMounted, ref, useContext, useCssModule, } from '@nuxtjs/composition-api';
import { RATINGS } from '~/pages/ratings/best-cities/data';
import { RBE_NAV_LINKS, SHARE_OPTIONS } from '~/components/RatingBestEmployers/RbeHeader/index';
export default defineComponent({
    name: 'RbeHeader',
    emits: ['handle-popup', 'handle-navigate'],
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Context
         */
        const context = useContext();
        /**
         * Viewport
         */
        const viewport = context.$viewport;
        const isVisible = ref(false);
        const rootRef = ref(null);
        const share = ref(null);
        const rootHeight = computed(() => rootRef.value?.offsetHeight || 40);
        const shareUrl = computed(() => {
            return location.href;
        });
        function visibleToggle() {
            isVisible.value = !isVisible.value;
        }
        function handleClickOutside({ target }) {
            if (!share.value?.contains(target)) {
                isVisible.value = false;
            }
        }
        function handleKeyup({ code = '' }) {
            if (code === 'Escape') {
                isVisible.value = false;
            }
        }
        function handleScroll() {
            isVisible.value = false;
        }
        function setShareUrl({ url }) {
            const vkImage = null;
            let link = `${url}${shareUrl.value}`;
            if (url.includes('vk.com') && vkImage) {
                link += `&image=${vkImage}`;
            }
            return link;
        }
        function scrollUp() {
            window.scroll(0, 0);
        }
        const currentLinks = computed(() => {
            return RBE_NAV_LINKS.map((link) => {
                link.isVisible = viewport.isGreaterThan('tablet') || link.to === 'methodology';
                return link;
            });
        });
        onBeforeUnmount(() => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keyup', handleKeyup);
            window.removeEventListener('scroll', handleScroll);
        });
        onMounted(() => {
            window.addEventListener('click', handleClickOutside);
            window.addEventListener('keyup', handleKeyup);
            window.addEventListener('scroll', handleScroll);
        });
        return {
            css,
            shareUrl,
            isVisible,
            share,
            rootRef,
            rootHeight,
            RATINGS,
            SHARE_OPTIONS,
            currentLinks,
            setShareUrl,
            visibleToggle,
            scrollUp,
        };
    },
});
