// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e8K93{font-size:13px;line-height:20px}.e8K93,.e8K93 a{color:#767676}.e8K93 a:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#767676}@media(any-hover:hover){.e8K93 a:hover{color:#767676}}.e8K93 a.kdRv5{color:#fff}.e8K93 a.kdRv5:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#fff}@media(any-hover:hover){.e8K93 a.kdRv5:hover{color:#fff}}.ThZoI{display:flex;align-items:center;grid-gap:4px;gap:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "e8K93",
	"tooltipBody": "kdRv5",
	"tooltip": "ThZoI"
};
module.exports = ___CSS_LOADER_EXPORT___;
