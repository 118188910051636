import { render, staticRenderFns } from "./PodcastPlayer.vue?vue&type=template&id=66e137d1&"
import script from "./PodcastPlayer.vue?vue&type=script&lang=ts&"
export * from "./PodcastPlayer.vue?vue&type=script&lang=ts&"
import style0 from "./PodcastPlayer.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/var/www/src/components/IconButton/IconButton.vue').default,BaseImage: require('/var/www/src/components/BaseImage/BaseImage.vue').default,Tooltip: require('/var/www/src/components/Tooltip/Tooltip.vue').default,ShareButton: require('/var/www/src/components/ShareButton/ShareButton.vue').default,Bookmark: require('/var/www/src/components/Bookmark/Bookmark.vue').default})
