import { defineStore } from 'pinia';
import Vue from 'vue';
export const ARTICLES_HISTORY_STORAGE_KEY = 'articlesHistory';
export const useArticlesHistory = defineStore('articlesHistory', {
    actions: {
        appendData(value) {
            Vue.set(this, 'data', value);
        },
        readFromHistory() {
            try {
                return JSON.parse(localStorage.getItem(ARTICLES_HISTORY_STORAGE_KEY) || '');
            }
            catch { }
            return {};
        },
        setData(slug) {
            Vue.set(this.data, slug, true);
            localStorage.setItem(ARTICLES_HISTORY_STORAGE_KEY, JSON.stringify(this.data));
        },
    },
    state: () => ({
        data: {},
    }),
});
