export const BAR_HEIGHT = 48;
export const UNDER_BAR_HEIGHT = 40;
export const HEADER_HEIGHTS = {
    desktop: BAR_HEIGHT + UNDER_BAR_HEIGHT,
    desktopMedium: BAR_HEIGHT,
    desktopWide: BAR_HEIGHT,
    mobile: BAR_HEIGHT + UNDER_BAR_HEIGHT,
    mobileMedium: BAR_HEIGHT + UNDER_BAR_HEIGHT,
    mobileWide: BAR_HEIGHT + UNDER_BAR_HEIGHT,
    tablet: BAR_HEIGHT + UNDER_BAR_HEIGHT,
};
