export function isElementInBounds(element, parent = null) {
    const { top, right, bottom, left } = element.getBoundingClientRect();
    if (parent) {
        const parentRect = parent.getBoundingClientRect();
        return !(top < parentRect.top || right > parentRect.right || bottom > parentRect.bottom || left < parentRect.left);
    }
    return (top >= 0 &&
        left >= 0 &&
        bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        right <= (window.innerWidth || document.documentElement.clientWidth));
}
