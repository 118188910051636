import { reactive, ref } from '@nuxtjs/composition-api';
const INITIAL_MODES_STATES = {
    images: true,
    waterfall: true,
    text: false,
};
const MODES = {
    images: reactive({
        isEnabled: INITIAL_MODES_STATES.images,
        label: 'Изображения',
    }),
    waterfall: reactive({
        isEnabled: INITIAL_MODES_STATES.waterfall,
        label: 'Лента материалов',
    }),
    text: reactive({
        isEnabled: INITIAL_MODES_STATES.text,
        label: 'Только текст материала',
    }),
};
const MODES_CSS_MAP = {
    images: 'hidden-images',
    waterfall: 'hidden-waterfall',
    text: 'text-only',
};
const FONT_SIZE = reactive({
    increment: 4,
    max: 12,
    min: 4,
    step: 4,
});
const isEnabled = ref(false);
const isBarVisible = ref(false);
export function useA11y() {
    return {
        get modesCSSMap() {
            return MODES_CSS_MAP;
        },
        get fontSize() {
            return FONT_SIZE;
        },
        get isEnabled() {
            return isEnabled.value;
        },
        set isEnabled(value) {
            isEnabled.value = value;
        },
        get isBarVisible() {
            return isBarVisible.value;
        },
        set isBarVisible(value) {
            isBarVisible.value = value;
        },
        get modes() {
            return MODES;
        },
        reset() {
            isEnabled.value = false;
            isBarVisible.value = false;
            FONT_SIZE.increment = FONT_SIZE.min;
            for (const modeKey in MODES) {
                MODES[modeKey].isEnabled = INITIAL_MODES_STATES[modeKey];
            }
            delete document.documentElement.dataset.a11y;
            document.documentElement.style.removeProperty('--font-size-increment');
        },
    };
}
