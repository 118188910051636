import { defineStore } from 'pinia';
import { useDuck } from '~/services';
const duck = useDuck();
export const useBrandvoices = defineStore('brandvoices', {
    actions: {
        async fetch() {
            if (this.$state.entries.length) {
                return;
            }
            try {
                const response = await duck.pub.getPubBrandvoices();
                const entries = response.data;
                if (!entries.length) {
                    return;
                }
                this.$state.entries = entries;
            }
            catch (error) {
                console.error(error);
            }
        },
        setData(entries) {
            this.$state.entries = entries;
        },
    },
    getters: {
        archiveEntries(state) {
            return state.entries.reduce((items, { name: title, url_alias: to }) => {
                if (!to) {
                    return items;
                }
                to = `/archive/${to}`;
                items.push({
                    title,
                    to,
                });
                return items;
            }, []);
        },
        nodeEntries(state) {
            return {
                brandvoice: state.entries.map(({ id }) => id).join(','),
                ...state.entries.reduce((paths, { id, url_alias: to }) => {
                    if (!to) {
                        return paths;
                    }
                    paths[to] = id?.toString();
                    return paths;
                }, {}),
            };
        },
    },
    state: () => ({
        entries: [],
    }),
});
