import { computed, defineComponent, onBeforeUnmount, onMounted, ref, useContext, useCssModule, } from '@nuxtjs/composition-api';
import { useA11y } from '~/composables';
import { sendAnalytics } from '~/utils';
export default defineComponent({
    name: 'ShareButton',
    inheritAttrs: false,
    methods: { sendAnalytics },
    props: {
        theme: {
            default: 'light',
            type: String,
            validator(value) {
                return ['a11y', 'light', 'dark'].includes(value);
            },
        },
        tooltipJustify: {
            default: 'center',
            type: String,
            validator(value) {
                return ['start', 'center', 'end'].includes(value);
            },
        },
        tooltipPosition: {
            default: 'bottom',
            type: String,
            validator(value) {
                return ['top', 'bottom'].includes(value);
            },
        },
        url: {
            default: null,
            type: String,
        },
        vkImage: {
            default: null,
            type: String,
        },
        withCopyLink: {
            default: false,
            type: Boolean,
        },
        isPlayerRoot: {
            default: false,
            type: Boolean,
        },
    },
    setup(props, { refs }) {
        /**
         * Accessibility
         */
        const a11y = useA11y();
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Context.
         */
        const context = useContext();
        const ym = context.$yandexMetrika;
        const isVisible = ref(false);
        const items = [
            // {
            //   url: '//www.facebook.com/sharer/sharer.php?u=',
            //   title: 'Facebook',
            //   hint: 'Поделиться в Facebook',
            // },
            {
                url: '//t.me/share/url?url=',
                title: 'Telegram',
                hint: 'Поделиться в Telegram',
            },
            {
                url: '//vk.com/share.php?url=',
                title: 'Вконтакте',
                hint: 'Поделиться во Вконтакте',
            },
            {
                url: '//connect.ok.ru/offer?url=',
                title: 'Одноклассники',
                hint: 'Поделиться в Одноклассниках',
            },
            {
                url: '//api.whatsapp.com/send?text=',
                title: 'WhatsApp',
                hint: 'Поделиться в WhatsApp',
            },
            {
                url: '//twitter.com/intent/tweet?url=',
                title: 'Twitter',
                hint: 'Поделиться в Twitter',
            },
        ];
        const currentUrl = computed(() => {
            if (props.url) {
                return props.url;
            }
            return location.href;
        });
        let root;
        function handleClick() {
            isVisible.value = !isVisible.value;
        }
        function handleClickOutside({ target }) {
            root = refs.root;
            if (!root.contains(target)) {
                isVisible.value = false;
            }
        }
        function handleKeyup({ code = '' }) {
            if (code === 'Escape') {
                isVisible.value = false;
            }
        }
        function handleScroll() {
            isVisible.value = false;
        }
        function setShareUrl({ url }) {
            let link = `${url}${currentUrl.value}`;
            if (url.includes('vk.com') && props.vkImage) {
                link += `&image=${props.vkImage}`;
            }
            return link;
        }
        function sendMetrics() {
            if (!props.isPlayerRoot) {
                return;
            }
            ym.reachGoal('click_share');
        }
        onBeforeUnmount(() => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keyup', handleKeyup);
            window.removeEventListener('scroll', handleScroll);
        });
        onMounted(() => {
            window.addEventListener('click', handleClickOutside);
            window.addEventListener('keyup', handleKeyup);
            window.addEventListener('scroll', handleScroll);
        });
        return {
            a11y,
            css,
            items,
            isVisible,
            currentUrl,
            handleClick,
            setShareUrl,
            sendMetrics,
        };
    },
});
