import { computed, defineComponent } from '@nuxtjs/composition-api';
import { DEFAULT_TOOLTIP } from './';
import { useA11y } from '~/composables';
export default defineComponent({
    name: 'RestartButton',
    inheritAttrs: false,
    props: {
        article: {
            default: '',
            type: String,
        },
        theme: {
            default: 'light',
            type: String,
            validator: (value) => {
                return ['a11y', 'light', 'dark', 'transparent', 'blue'].includes(value);
            },
        },
        tooltip: {
            default: false,
            type: [Boolean, Object],
        },
    },
    emits: ['handle-click'],
    setup(props) {
        const a11y = useA11y();
        const currentText = 'Прослушать сначала';
        const currentTooltip = computed(() => {
            return {
                ...DEFAULT_TOOLTIP,
                size: a11y.isEnabled ? 'small-plus' : 'small',
                ...(typeof props.tooltip === 'object' && props.tooltip),
            };
        });
        const iconName = computed(() => {
            let name = 'restart';
            if (a11y.isEnabled) {
                name += '-a11y';
            }
            return name;
        });
        return {
            a11y,
            currentText,
            currentTooltip,
            iconName,
        };
    },
});
