/**
 * Returns a typed object or string on error.
 */
export function parseJSON(data) {
    try {
        return JSON.parse(data);
    }
    catch {
        return {};
    }
}
