import { defineStore } from 'pinia';
export const IMAGE_FORMATS_DUMMIES = {
    avif: `data:image/avif;base64,AAAAFGZ0eXBhdmlmAAAAAG1pZjEAAACgbWV0YQAAAAAAAAAOcGl0bQAAAAAAAQAAAB5pbG9jAAAAAEQAAAEAAQAAAAEAAAC8AAAAGwAAACNpaW5mAAAAAAABAAAAFWluZmUCAAAAAAEAAGF2MDEAAAAARWlwcnAAAAAoaXBjbwAAABRpc3BlAAAAAAAAAAQAAAAEAAAADGF2MUOBAAAAAAAAFWlwbWEAAAAAAAAAAQABAgECAAAAI21kYXQSAAoIP8R8hAQ0BUAyDWeeUy0JG+QAACANEkA=`,
    webp: 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=',
};
export const useImageFormat = defineStore('imageFormat', {
    actions: {
        _initialize(context) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const self = this;
            for (const key in self.$state) {
                const value = self.$state[key];
                if (value) {
                    continue;
                }
                if (process.server) {
                    if (!context) {
                        continue;
                    }
                    const input = context.req?.headers?.accept || '';
                    if (!input) {
                        continue;
                    }
                    self.$state[key] = input.includes(`image/${key}`);
                }
                if (!self.$state[key] && process.client) {
                    ;
                    (async function () {
                        await new Promise((resolve) => {
                            const image = new Image();
                            image.src = IMAGE_FORMATS_DUMMIES[key];
                            image.onerror = () => {
                                self.$state[key] = false;
                                resolve(self.$state[key]);
                            };
                            image.onload = () => {
                                self.$state[key] = Boolean(image.height && image.width);
                                resolve(self.$state[key]);
                            };
                        });
                    })();
                }
            }
        },
        isSupported(format) {
            return this.$state[format];
        },
    },
    state: () => ({
        avif: false,
        webp: false,
    }),
});
