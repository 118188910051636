import { FOOTER_INFO } from '~/constants';
/**
 * Should disable ad in article
 * @param article
 */
export function shouldDisableArticleAd(article) {
    const { disable_ads: isDisabled, isBrandvoice, isPartner, isPodcast, url_alias: url } = article;
    for (const entry of FOOTER_INFO) {
        if (entry.to.includes(String(url))) {
            return true;
        }
    }
    return isDisabled || isBrandvoice || isPartner || isPodcast;
}
