import { createRef } from '@nuxtjs/composition-api';
export function useAsyncRef(instance, asyncKey) {
    return createRef({
        get() {
            return instance?.data?.[asyncKey];
        },
        set(newValue) {
            if (!instance || !instance.data) {
                return;
            }
            instance.data[asyncKey] = newValue;
        },
    });
}
