import { defineNuxtPlugin, onGlobalSetup, onMounted } from '@nuxtjs/composition-api';
import { isLightHouse } from '~/utils';
import { useStack } from '~/composables';
import { IS_DEVELOPMENT_MODE, IS_PRODUCTION_MODE } from '~/constants';
/**
 * Debug mode
 * In network: https://top-fwz1.mail.ru/counter
 */
const IS_DEBUG_MODE = false;
/**
 * Mail.ru id
 */
const MAIL_RU_ID = 3081474;
/**
 * Plugin name
 */
const PLUGIN_NAME = 'mailRu';
export default defineNuxtPlugin(({ app, env, nuxtState, route }, inject) => {
    let isReady = false;
    const stack = useStack();
    inject(PLUGIN_NAME, (options = {}) => {
        const callback = () => {
            const shouldHit = !(options.excluded || []).includes(PLUGIN_NAME);
            if (!shouldHit) {
                return;
            }
            let { referer = '', title = '', url = '' } = options || {};
            referer = referer || document.referrer;
            title = title || document.title;
            url = url || document.URL;
            window._tmr.pageView({ id: MAIL_RU_ID, type: 'pageView', referer, title, url });
            if (IS_DEBUG_MODE && IS_DEVELOPMENT_MODE) {
                console.info(`[Send ${PLUGIN_NAME}]`, { referer, title, url });
            }
        };
        if (!isReady) {
            return stack.push(callback);
        }
        callback();
    });
    onGlobalSetup(() => {
        onMounted(() => {
            app[`$${PLUGIN_NAME}`]({
                title: document.title,
                url: env.HOST + route.fullPath,
            });
        });
    });
    app?.router?.onReady(() => {
        app?.router?.afterEach((to, from) => {
            app[`$${PLUGIN_NAME}`]({
                title: (app.$page || nuxtState.data[0].$page || document).title,
                referer: from.fullPath,
                url: to.fullPath,
            });
        });
    });
    if (IS_DEBUG_MODE || IS_PRODUCTION_MODE) {
        return window.addEventListener('load', async () => {
            if (isLightHouse(navigator.userAgent)) {
                return;
            }
            try {
                await initMailRu();
                isReady = true;
                stack.execute();
            }
            catch {
                stack.close();
            }
        });
    }
    console.info(`%c${PLUGIN_NAME} is disabled in development mode. Switch to debug mode to enable.`, 'color: DarkGoldenRod;');
});
/**
 * Initialize mail.ru script
 */
function initMailRu() {
    return new Promise((resolve, reject) => {
        /* eslint-disable */
        var _tmr = window._tmr || (window._tmr = []);
        _tmr.push({ id: MAIL_RU_ID, type: "pageView", start: (new Date()).getTime() });
        (function (d, w, id) {
            if (d.getElementById(id))
                return;
            var ts = d.createElement("script");
            ts.type = "text/javascript";
            ts.async = true;
            ts.defer = true;
            ts.id = id;
            ts.src = "https://top-fwz1.mail.ru/js/code.js";
            ts.onload = resolve;
            ts.onerror = reject;
            var f = function () { var s = d.getElementsByTagName("script")[0]; s?.parentNode?.insertBefore(ts, s); };
            if (w.opera == "[object Opera]") {
                d.addEventListener("DOMContentLoaded", f, false);
            }
            else {
                f();
            }
        })(document, window, "tmr-code");
        /* eslint-enable */
    });
}
