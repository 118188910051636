// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CwVsX{display:block}._84tRl{display:flex;align-items:center;justify-content:center;margin-right:8px;color:#767676}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CwVsX",
	"icon": "_84tRl"
};
module.exports = ___CSS_LOADER_EXPORT___;
