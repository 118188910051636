// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p6bA6{max-height:calc(100vh - 48px);max-height:calc(var(--vh, 1vh)*100 - 48px);flex-flow:column}.B2R0n,.p6bA6{display:flex;height:100%}.B2R0n{justify-content:flex-end;padding:24px 38px 0 40px;-webkit-overflow-scrolling:touch;-ms-overflow-style:none;overflow-x:hidden;overflow-y:auto;-ms-scroll-chaining:none;overscroll-behavior:contain;scrollbar-color:#989898 transparent;scrollbar-width:thin}.B2R0n::-webkit-scrollbar{width:11px}.B2R0n::-webkit-scrollbar-track{margin:2px 0}.B2R0n::-webkit-scrollbar-thumb{border-right:2px solid transparent;border-left:3px solid transparent;background-clip:content-box;background-color:#989898;border-radius:4px}.B2R0n::-webkit-scrollbar-thumb:window-inactive{background-color:hsla(0,0%,59.6%,.07)}.uqSQg{position:relative;display:flex;min-width:240px;height:100%;flex-flow:column;padding-left:40px;margin-bottom:40px}.uqSQg:nth-child(2){min-width:300px}.uqSQg:after,.uqSQg:before{position:absolute;top:0;width:1px;height:100%;background-color:#2d2d2d;content:\"\"}.uqSQg:before{left:-1px}.uqSQg:after{right:0}.iSyHh{margin:0 0 8px;color:#fff;font-size:16px;font-weight:600;line-height:24px}._47qXw{padding:0;margin:0;list-style:none}.uqSQg:first-child:before{content:none}.Kng96{padding:8px 0}.Kng96:last-child{padding-bottom:0}.-exym{position:relative;z-index:1;display:block;padding-right:14px;border-right:1px solid transparent;color:#989898;font-size:14px;-webkit-hyphens:none;-ms-hyphens:none;hyphens:none;line-height:24px;text-decoration:none;transition:color .2s,border-color .2s}.-exym:active{-webkit-tap-highlight-color:rgba(0,0,0,0);border-color:#f4e600;color:#fff}@media(any-hover:hover){.-exym:hover{border-color:#f4e600;color:#fff}}.p6bA6 .vS5Wc{margin-left:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theMenu": "p6bA6",
	"inner": "B2R0n",
	"column": "uqSQg",
	"columnTitle": "iSyHh",
	"menu": "_47qXw",
	"menuItem": "Kng96",
	"menuLink": "-exym",
	"freshMagazine": "vS5Wc"
};
module.exports = ___CSS_LOADER_EXPORT___;
