import { render, staticRenderFns } from "./TheWaterfallAdfox.vue?vue&type=template&id=218a34c4&"
import script from "./TheWaterfallAdfox.vue?vue&type=script&lang=ts&"
export * from "./TheWaterfallAdfox.vue?vue&type=script&lang=ts&"
import style0 from "./TheWaterfallAdfox.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MicroLoader: require('/var/www/src/components/MicroLoader/MicroLoader.vue').default,Informer: require('/var/www/src/components/Informer/Informer.vue').default,Adfox: require('/var/www/src/components/Adfox/Adfox.vue').default})
