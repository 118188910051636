import { RATINGS_TAG, VIDEO_LISTS } from '~/constants';
import { highlightWords, parseArticleErid } from '~/utils';
import { useSearch } from '~/composables';
export function formatArticle(article) {
    if (!article) {
        console.error('There is no article');
        return {};
    }
    const search = useSearch();
    article.erid = '';
    article.isGallery = Boolean(article.gallery && article.gallery.length > 0);
    article.isFranchise = article.type === 'franchise';
    article.isEventClub = article.type === 'event';
    article.isPodcast = article.serial !== undefined;
    article.isPressRelease = Boolean(article?.sections?.find(({ url }) => url === 'novosti-kompaniy'));
    article.isRating = Boolean(article.rating ||
        (article.ratings || []).length > 0 ||
        (article.tags || []).some(({ id = '' }) => id === RATINGS_TAG.id));
    article.withVideo =
        typeof article?.video === 'string' ||
            (article.tags || []).some(({ url }) => Object.values(VIDEO_LISTS).includes(`/${url}`));
    if (article.sections) {
        const sectionTypes = {
            brandvoice: 'isBrandvoice',
            forbeslife: 'isForbesLife',
            'forbes-woman': 'isForbesWoman',
            'partner-article': 'isPartner',
        };
        for (const section of article.sections) {
            const type = sectionTypes[section.url];
            if (type) {
                article[type] = true;
            }
        }
    }
    // Highlight search words
    if (search.mark) {
        article.title = highlightWords(article.title, search.mark);
        if (article?.subtitle) {
            article.subtitle = highlightWords(article.subtitle, search.mark);
        }
        if (article.isGallery) {
            article?.gallery?.map((item) => {
                if (item?.caption) {
                    item.caption = highlightWords(item.caption, search.mark);
                }
                return item?.text?.map((box) => {
                    if (search.mark) {
                        if (box?.data?.text) {
                            box.data.text = highlightWords(box.data.text, search.mark);
                        }
                    }
                    return box;
                });
            });
        }
    }
    if (!article.body || !article.body.length) {
        return article;
    }
    // Handle article body
    article.body = article.body.map((box) => {
        const { type = '', data = {} } = box || {};
        // Inject article with gallery in pseudo-gallery box
        if (type === 'paragraph' && data?.text?.includes('<!--interest-->')) {
            box = {
                ...box,
                data: {
                    article,
                    type: 'token',
                },
                type: 'article',
            };
        }
        // Highlight search words
        if (article.isFirst && search.mark && data?.text) {
            data.text = highlightWords(data.text, search.mark);
        }
        // Parse article erid in body
        if (type === 'listOfValues' && data?.id?.trim() === 'admark' && data?.items?.length) {
            article.erid = parseArticleErid(data.items);
        }
        return box;
    }, []);
    if (article.erid || !article.body_after || !article.body_after.length) {
        return article;
    }
    for (const { type = '', data = {} } of article.body_after) {
        // Parse article erid in body_after
        if (type === 'listOfValues' && data?.id?.trim() === 'admark' && data?.items?.length) {
            article.erid = parseArticleErid(data.items);
            break;
        }
    }
    return article;
}
