import { defineComponent, useContext, useCssModule } from '@nuxtjs/composition-api';
import { addUtm } from '~/utils';
export default defineComponent({
    name: 'TheMenu',
    props: {
        menus: {
            default: () => [],
            type: Array,
        },
    },
    setup(props) {
        /**
         * Composables
         */
        const css = useCssModule();
        const context = useContext();
        const viewport = context.$viewport;
        const isMobile = viewport.isLessThan('desktopMedium');
        /**
         * The menu items
         */
        const menu = props.menus.map((menu) => {
            return {
                ...menu,
                items: menu.items?.map((item) => addUtm(item, 'burger', isMobile)) || [],
            };
        });
        return {
            css,
            menu,
        };
    },
});
