import { computed, defineComponent, onMounted, ref, useCssModule, watch } from '@nuxtjs/composition-api';
import { DEFAULT_PADDINGS } from '~/components/Tooltip';
export default defineComponent({
    name: 'TooltipBody',
    props: {
        triggerRect: {
            default: null,
            type: Object,
        },
        isOpened: {
            default: false,
            type: Boolean,
        },
        options: {
            default: () => ({}),
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const rootEl = ref();
        const height = ref(0);
        const tooltipRect = ref({
            bottom: 0,
            height: 0,
            left: 0,
            right: 0,
            top: 0,
            width: 0,
        });
        const cornerIcon = computed(() => {
            return ['top', 'bottom'].includes(props.options.position || 'bottom') ? 'corner-top' : 'corner-right';
        });
        const appendTobodyStyles = ref({});
        const styles = computed(() => {
            const { height, size, width, zIndex, whiteSpace, visibility, fontSize, lineHeight } = props.options;
            const { padding: propsPadding } = props.options;
            let padding = DEFAULT_PADDINGS[size];
            if (propsPadding && propsPadding !== padding) {
                padding = propsPadding;
            }
            return {
                height,
                padding,
                width,
                zIndex,
                whiteSpace,
                visibility,
                fontSize,
                lineHeight,
            };
        });
        onMounted(() => {
            watch(() => props.isOpened, (isOpened) => {
                if (isOpened) {
                    window.requestAnimationFrame(async () => {
                        const { bottom, height, left, right, top, width } = rootEl.value?.getBoundingClientRect() || {};
                        tooltipRect.value = {
                            bottom,
                            height,
                            left,
                            right,
                            top,
                            width,
                        };
                        if (props.options.shouldAppendToBody) {
                            await new Promise((resolve) => setTimeout(resolve, 100));
                            const { bottom: elBottom, height: elHeight, left: elLeft, right: elRight, 
                            // top: elTop,
                            width: elWidth, } = props.triggerRect;
                            const { justify, position } = props.options;
                            const gap = 'var(--gap, 10px)';
                            const shift = (axis = 'x') => {
                                if (axis === 'x') {
                                    return `(${elWidth}px - 16px) / 2`;
                                }
                                if (axis === 'y') {
                                    return `(${elHeight}px - 16px) / 2`;
                                }
                                throw new Error(`[TooltipBody] Unkown argument. Possible arguments are 'x', 'y'`);
                            };
                            let left = '0';
                            let top = '0';
                            // Calc position and justify
                            // TODO Добавить позиционирование для остальных сторон
                            if (position === 'bottom') {
                                top = `calc(${elBottom}px + ${gap} - ${shift('y')})`;
                                switch (justify) {
                                    case 'start':
                                        left = `calc(${elLeft}px + ${shift()} - ${gap})`;
                                        break;
                                    case 'center':
                                        left = `calc(${elLeft}px + ${elWidth}px / 2)`;
                                        break;
                                    case 'end':
                                        left = `calc(${elRight}px - ${width}px)`;
                                        break;
                                }
                            }
                            appendTobodyStyles.value = {
                                position: 'fixed',
                                width: tooltipRect.value.width + 'px',
                                height: tooltipRect.value.height + 'px',
                                left,
                                top,
                            };
                        }
                    });
                }
            });
        });
        return {
            css,
            cornerIcon,
            height,
            rootEl,
            styles,
            tooltipRect,
            appendTobodyStyles,
        };
    },
});
