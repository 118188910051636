export const FOOTER_INFO = [
    {
        title: 'Контактная информация',
        to: '/contacts',
    },
    {
        title: 'Правила обработки',
        to: 'https://cdn.forbes.ru/themes/forbes/Personal_Data_Security.pdf',
    },
    {
        title: 'Реклама в журнале',
        to: '/reklama-v-zhurnale',
    },
    {
        title: 'Реклама на сайте',
        to: '/reklama-na-saite',
    },
    {
        title: 'Условия перепечатки',
        to: '/article/39217-usloviya-perepechatki',
    },
    {
        title: 'Архив',
        to: '/archive',
    },
    {
        title: 'Вакансии в Forbes Russia',
        to: 'https://hh.ru/employer/6310?hhtmFrom=vacancy_search_list',
    },
    {
        title: 'Сканер иноагентов, причастных к экстремизму и терроризму и организаций, признанных нежелательными',
        to: '/scanner/oauth',
    },
];
export const FOOTER_SOCIALS = [
    {
        title: 'Telegram',
        to: 'https://telegram.me/forbesrussia',
    },
    // {
    //   title: 'Instagram',
    //   to: 'https://www.instagram.com/forbes.russia/',
    // },
    {
        title: 'ВКонтакте',
        to: 'https://vk.com/forbes',
    },
    // {
    //   title: 'Flipboard',
    //   to: 'https://flipboard.com/@ForbesRussia',
    // },
    {
        title: 'YouTube',
        to: 'https://www.youtube.com/channel/UCr2LSro_9yZvng8HR2eDExA',
    },
    // {
    //   title: 'Facebook',
    //   to: 'https://www.facebook.com/forbesrussia',
    // },
];
export const FOOTER_FZ_RULES = [
    {
        title: 'СМИ2',
        to: 'https://cdn.forbes.ru/forbes-static/new/2024/03/smi-65e6f3da7e407.docx',
        icon: 'download',
    },
    {
        title: 'SPARROW',
        to: 'https://cdn.forbes.ru/forbes-static/new/2024/03/sparrow-65e6f3dbf08ef.docx',
        icon: 'download',
    },
    {
        title: 'INFOX',
        to: 'https://cis.infox.sg/p/recommendation.html',
        icon: 'away',
    },
];
