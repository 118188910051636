import { ref } from '@nuxtjs/composition-api';
const isFixed = ref(false);
const isLocked = ref(false);
const lastScroll = ref(0);
export function useBodyLocker() {
    return {
        lock(shouldFix = false) {
            return setTimeout(() => {
                if (shouldFix) {
                    const scrollTop = window.scrollY;
                    const { style: bodyStyle } = document.body;
                    bodyStyle.left = '0px';
                    bodyStyle.position = 'fixed';
                    bodyStyle.top = `-${scrollTop}px`;
                    bodyStyle.width = '100%';
                    lastScroll.value = scrollTop;
                }
                document.body.style.overflow = 'hidden';
                isLocked.value = true;
                isFixed.value = shouldFix;
            });
        },
        unlock() {
            return setTimeout(() => {
                // Remove custom styles.
                document.body.removeAttribute('style');
                if (isFixed.value) {
                    // Update the value if scrolled.
                    if (window.scrollY) {
                        lastScroll.value = window.scrollY;
                    }
                    // Scroll to last scroll position.
                    window.scrollTo({ top: lastScroll.value });
                }
                isFixed.value = isLocked.value = false;
            });
        },
        get isFixed() {
            return isFixed.value;
        },
        get isLocked() {
            return isLocked.value;
        },
        get lastScroll() {
            return lastScroll.value;
        },
    };
}
