// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/ratings/rbe-error-desktop.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LqKhj{position:relative;height:100%;min-height:100vh;min-height:calc(var(--vh, 1vh)*100);background-color:#181716}.LqKhj:after,.LqKhj:before{position:absolute;bottom:0;left:0;width:160px;height:160px;background-image:radial-gradient(circle 160px at left,#232323 50%,transparent 0);background-size:80px 160px;content:\"\"}.LqKhj:after{left:160px;width:80px;transform:rotate(180deg)}@media screen and (min-width:768px){.LqKhj{background-image:url(\"data:image/svg+xml;charset=utf-8,%3Csvg width='400' height='160' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 160c44.183 0 80-35.817 80-80S44.183 0 0 0v160zM80 160c44.183 0 80-35.817 80-80S124.183 0 80 0v160z' fill='%23232323'/%3E%3Ccircle cx='240' cy='80' r='80' fill='%23232323'/%3E%3Cpath d='M400 0c-44.183 0-80 35.817-80 80s35.817 80 80 80V0z' fill='%23232323'/%3E%3C/svg%3E\");background-position:0 100%;background-repeat:no-repeat}.LqKhj:after,.LqKhj:before{content:unset}}.LqKhj.s-QZ-{background-color:#fff;background-image:none;background-position:0 100%;background-repeat:no-repeat}@media screen and (min-width:768px){.LqKhj.s-QZ-{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}}.LqKhj.s-QZ-:after,.LqKhj.s-QZ-:before{content:unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "LqKhj",
	"bestEmployers": "s-QZ-"
};
module.exports = ___CSS_LOADER_EXPORT___;
