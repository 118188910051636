import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'BaseButton',
    props: {
        icon: {
            default: null,
            type: String,
        },
        isRowRevers: {
            default: false,
            type: Boolean,
        },
        isDisabled: {
            default: false,
            type: Boolean,
        },
        radius: {
            default: 'small',
            type: String,
            validator: (value) => {
                return ['small', 'medium', 'large', 'none'].includes(value);
            },
        },
        size: {
            default: 'big',
            type: String,
            validator: (value) => {
                return ['small', 'medium', 'big', 'large'].includes(value);
            },
        },
        theme: {
            default: 'dark',
            type: String,
            validator: (value) => {
                return [
                    'a11y',
                    'blue',
                    'dark',
                    'dark-alternate',
                    'darker-alternate',
                    'light',
                    'transparent',
                    'transparent-alternate',
                    'yellow',
                ].includes(value);
            },
        },
        tag: {
            default: 'button',
            type: String,
        },
        to: {
            default: null,
            type: String,
        },
    },
    emits: ['click'],
    setup(_, { emit }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        function handleClick(event) {
            emit('click', event);
        }
        return {
            css,
            handleClick,
        };
    },
});
