// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ljLTa{position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.yRY\\+c{position:absolute;z-index:2;padding:8px 0;margin-right:-6px;background-color:#2d2d2d;border-radius:4px;list-style:none}.yRY\\+c:after{position:absolute;width:0;height:0;border:6px solid transparent;margin-left:-6px;content:\"\";pointer-events:none}.yRY\\+c.-Oz1r{bottom:calc(100% + 8px);margin-bottom:2px}.yRY\\+c.-Oz1r:after{top:100%;border-top-color:#2d2d2d}.yRY\\+c.H0FkD{top:calc(100% + 8px);margin-top:2px}.yRY\\+c.H0FkD:after{bottom:100%;border-bottom-color:#2d2d2d}.yRY\\+c.sdlvg{left:0}.yRY\\+c.sdlvg:after{left:16px}.yRY\\+c.F\\+-wK{left:50%;transform:translate(-50%)}.yRY\\+c.F\\+-wK:after{left:50%}.yRY\\+c.utS9H{right:0}.yRY\\+c.utS9H:after{right:16px}.AkyeK{display:block;padding:8px 16px;color:#fff;cursor:pointer;font-size:14px;line-height:24px;text-decoration:none;transition:color .2s;white-space:nowrap}.AkyeK:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#232323}@media(any-hover:hover){.AkyeK:hover{background-color:#232323}}[data-a11y] .AkyeK{font-size:16px;line-height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareButton": "ljLTa",
	"items": "yRY+c",
	"top": "-Oz1r",
	"bottom": "H0FkD",
	"start": "sdlvg",
	"center": "F+-wK",
	"end": "utS9H",
	"link": "AkyeK"
};
module.exports = ___CSS_LOADER_EXPORT___;
