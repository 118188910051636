// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l2zYL{position:relative;background:#f4f4f4}.u-4wr{position:absolute;z-index:1000001;top:8px;right:8px;color:#181716}.PY6ZN{position:relative;width:100%;height:calc(100vh - 48px);height:calc(var(--vh, 1vh)*100 - 48px);-webkit-overflow-scrolling:touch;overflow-y:auto}.DhnZc{padding:0;margin:0;color:#2d2d2d;list-style:none}.Em83q{margin:-16px 0 16px}.ePWMW{height:188px}._6uC0a{display:block;padding:0 16px;margin:0 0 24px;color:#2d2d2d;font-size:16px;line-height:22px}._6uC0a a{width:100%;color:inherit}._6uC0a a[aria-current=page]{color:#0082ff}.iO\\+UC{position:sticky;z-index:1000000;top:0;padding:12px 16px;margin:0 0 16px;background-color:#fff;color:#2d2d2d;font-family:\"Merriweather\",serif;font-size:18px;font-weight:700;line-height:24px}.SMx\\+C{display:flex;align-items:center;margin-bottom:4px;color:#767676;font-size:13px;line-height:16px}._0Df1H:not(:first-child):before{display:inline-block;margin:0 8px;color:#767676;content:\"·\"}a._0Df1H{color:#0082ff;cursor:pointer;text-decoration:none;transition:color .2s}a._0Df1H:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#006ee1}@media(any-hover:hover){a._0Df1H:hover{color:#006ee1}}a._0Df1H.hkjoY{color:#ffa00a}a._0Df1H.tW-Hj{color:#f02d37}.sJHNl{display:flex;align-items:center;justify-content:center;margin:0 0 48px;color:#767676}.eYb7B>:first-child{width:50%;height:16px}.eYb7B>:last-child{height:60px;margin-top:4px}.iO\\+UC .eYb7B>:first-child{display:none}.iO\\+UC .eYb7B>:last-child{width:50%;height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "l2zYL",
	"close": "u-4wr",
	"container": "PY6ZN",
	"items": "DhnZc",
	"itemTop": "Em83q",
	"carousel": "ePWMW",
	"item": "_6uC0a",
	"date": "iO+UC",
	"entries": "SMx+C",
	"entry": "_0Df1H",
	"isSpecial": "hkjoY",
	"isPartner": "tW-Hj",
	"intersectionObserver": "sJHNl",
	"itemLoader": "eYb7B"
};
module.exports = ___CSS_LOADER_EXPORT___;
