// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (min-width:768px){.zorCY{padding:0 0 16px;margin:0 40px}}.zorCY+.zorCY{padding-top:24px}@media screen and (min-width:768px){.zorCY+.zorCY{padding-top:0;border-top:1px solid #2d2d2d}}.jsauq{padding:16px 16px 8px;border-bottom:1px solid #2d2d2d;margin:0;color:#989898;font-size:15px;font-weight:400;line-height:22px}@media screen and (min-width:768px){.jsauq{padding:24px 0 8px;border-bottom:0}}._9DkVs{padding:0 16px;margin:0;list-style:none}@media screen and (min-width:768px){._9DkVs{padding:0}}.Lc4aw{margin-top:24px}@media screen and (min-width:768px){.Lc4aw{margin-top:0}}.fNMy7{display:block;margin-bottom:8px;font-size:13px;line-height:20px;color:#0082ff;cursor:pointer;text-decoration:none;transition:color .2s}.fNMy7:active{-webkit-tap-highlight-color:rgba(0,0,0,0);color:#006ee1}@media(any-hover:hover){.fNMy7:hover{color:#006ee1}}.YriRp{display:block;color:#fff;font-size:16px;line-height:24px;text-decoration:none}@media screen and (min-width:768px){.YriRp{padding:8px 16px;border-radius:4px;cursor:pointer;font-size:15px;line-height:22px;transition:background-color .2s}.YriRp:active{-webkit-tap-highlight-color:rgba(0,0,0,0);background-color:#2d2d2d}}@media screen and (min-width:768px)and (any-hover:hover){.YriRp:hover{background-color:#2d2d2d}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchTopic": "zorCY",
	"topicTitle": "jsauq",
	"results": "_9DkVs",
	"result": "Lc4aw",
	"title": "fNMy7",
	"description": "YriRp"
};
module.exports = ___CSS_LOADER_EXPORT___;
