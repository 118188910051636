import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import Vue from 'vue';
export default defineNuxtPlugin(() => {
    const options = Vue.options || {};
    const { NuxtLink } = options.components;
    options.components.NLink = Vue.extend({
        props: NuxtLink.options.props,
        render(h) {
            const props = this.$options.props || {};
            const to = this.to;
            if (typeof to === 'string' && to.startsWith('/http')) {
                return h('a', {
                    attrs: {
                        href: to.slice(1),
                        rel: 'noopener noreferer nofollow',
                        target: '_blank',
                    },
                }, this.$slots.default);
            }
            return h(NuxtLink, {
                props: Object.keys(props).reduce((acc, key) => {
                    acc[key] = this[key];
                    return acc;
                }, {}),
            }, this.$slots.default);
        },
    });
});
