import { defineStore } from 'pinia';
const STATE = {
    articles: [],
    articleType: 'total',
    articlesCounts: {
        news: 0,
        photogallery: 0,
        podcasts: 0,
        profile: 0,
        rating: 0,
        story: 0,
        total: 0,
        video: 0,
    },
    dateFormat: 'DD.MM.YYYY',
    dateRange: [],
    dateRangeEntry: {
        value: 'all',
        title: 'Все время',
    },
    error: '',
    fromHeader: false,
    hasSearched: false,
    isLoading: false,
    limit: 8,
    mark: '',
    notFound: false,
    completedQuery: '',
    query: '',
    sortType: 'ascending',
    total: 0,
};
export const useSearch = defineStore('search', {
    actions: {
        clear() {
            this.$state.articles = [];
            this.$state.articleType = STATE.articleType;
            this.$state.articlesCounts = STATE.articlesCounts;
            this.$state.error = STATE.error;
            this.$state.fromHeader = STATE.fromHeader;
            this.$state.hasSearched = STATE.hasSearched;
            this.$state.notFound = STATE.notFound;
            this.$state.completedQuery = STATE.completedQuery;
            this.$state.query = STATE.query;
            this.$state.sortType = STATE.sortType;
            this.$state.total = STATE.total;
        },
        clearAll() {
            this.clear();
            this.$state.dateRange = [];
            this.$state.dateRangeEntry = { ...STATE.dateRangeEntry };
        },
        get(stateName) {
            return this[stateName];
        },
        set(stateName, payload) {
            ;
            this[stateName] = payload;
        },
    },
    getters: {
        backendSortType(state) {
            const frontendToBackendMap = {
                ascending: 'date_asc',
                descending: 'date_desc',
            };
            return frontendToBackendMap[state.sortType] || '';
        },
    },
    state: () => ({
        ...STATE,
    }),
});
export const DROPDOWN_YEAR_ITEMS = [
    {
        value: 'all',
        title: 'Все время',
    },
    {
        value: 'day',
        title: 'За день',
    },
    {
        value: 'week',
        title: 'За неделю',
    },
    {
        value: 'month',
        title: 'За месяц',
    },
    {
        value: 'year',
        title: 'За год',
    },
    {
        value: 'range',
        title: 'Выбрать период...',
    },
];
export const ARTICLES_TYPES = [
    { title: 'Все', value: 'total' },
    { title: 'Новости', value: 'news' },
    { title: 'Статьи', value: 'story' },
    { title: 'Фотогалереи', value: 'photogallery' },
    { title: 'Видео', value: 'video' },
    { title: 'Профили', value: 'profile' },
    { title: 'Рейтинги', value: 'rating' },
    { title: 'Подкасты', value: 'podcasts' },
];
