// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W4Bkr{display:block;width:100%}.wfGLL,.eU-Fm{position:relative;overflow:hidden;border-radius:4px}.wfGLL:after,.eU-Fm:after{position:absolute;top:0;left:0;width:100%;height:100%;animation:lM7-M 1.5s ease-out infinite;border-radius:inherit;content:\"\"}.wfGLL{width:60%;height:20px}.eU-Fm{width:100%;height:44px;margin-top:16px}.Uwf5J .wfGLL,.Uwf5J .eU-Fm{background-color:#2d2d2d}.Uwf5J .wfGLL:after,.Uwf5J .eU-Fm:after{background-image:linear-gradient(90deg,transparent 0,#767676 50%,transparent)}.kBsd7 .wfGLL,.kBsd7 .eU-Fm{background-color:#e2e2e2}.kBsd7 .wfGLL:after,.kBsd7 .eU-Fm:after{background-image:linear-gradient(90deg,transparent 0,#fff 50%,transparent)}@keyframes lM7-M{0%{transform:translate(-100%)}to{transform:translate(100%)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "W4Bkr",
	"one": "wfGLL",
	"two": "eU-Fm",
	"lines-loader-component": "lM7-M",
	"dark": "Uwf5J",
	"light": "kBsd7"
};
module.exports = ___CSS_LOADER_EXPORT___;
