import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'SearchTopic',
    props: {
        topic: {
            required: true,
            type: Object,
        },
    },
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            css,
        };
    },
});
