import { defineNuxtPlugin, onGlobalSetup, onMounted, onUpdated, useMeta } from '@nuxtjs/composition-api';
/**
 * Enables/disables mourning mode
 */
const IS_ENABLED = false;
export default defineNuxtPlugin(() => {
    onGlobalSetup(() => {
        if (!IS_ENABLED) {
            return;
        }
        const meta = useMeta();
        meta.style.value.push({
            cssText: `
a,
aside,
button,
h1,
h2,
h3,
h4,
h5,
h6,
iframe
input,
ol,
p,
span,
svg,
ul,
[itemtype="https://schema.org/ImageObject"] {
  filter: grayscale(1);
}`,
        });
        onUpdated(() => {
            setMourningModeForImages();
        });
        onMounted(() => {
            setMourningModeForImages();
        });
    });
});
function setMourningModeForImages() {
    const allImages = document.querySelectorAll('img:not([style*="filter"])');
    for (const el of Array.from(allImages)) {
        if (!el.closest('[data-grayscale]')) {
            ;
            el.style.setProperty('filter', 'grayscale(1)');
        }
    }
}
