import { computed, defineComponent, ref, useContext, useCssModule, useRoute } from '@nuxtjs/composition-api';
import { createHead, isFlutter } from '~/utils';
const PATH_TO_THEME_MAP = {
    '/ratings/best-employers': 'bestEmployers',
};
export default defineComponent({
    name: 'PageError',
    layout: 'empty',
    setup(_, { root }) {
        const context = useContext();
        const css = useCssModule();
        const route = useRoute();
        const isOffline = computed(() => root.isOffline || isFlutter(context.$device.userAgent));
        const isReloading = ref(false);
        const head = computed(() => {
            if (isOffline.value) {
                return {
                    description: 'Пожалуйста, проверьте ваше подключение к Интернету и перезагрузите страницу',
                    title: 'Нет подключения к интернету. Ошибка ERR_INTERNET_DISCONNECTED',
                };
            }
            return {
                description: 'Страница на сайте не найдена: ошибка 404. Воспользуйтесь поиском или посмотрите другие материалы на Forbes.ru.',
                title: 'Страница не найдена. Ошибка 404',
            };
        });
        const theme = computed(() => {
            for (const path in PATH_TO_THEME_MAP) {
                if (route.value.path.startsWith(path)) {
                    return PATH_TO_THEME_MAP[path];
                }
            }
            return 'default';
        });
        // watch(
        //   () => root.isOnline,
        //   (isOnline) => {
        //     if (isOnline) {
        //       isReloading.value = true
        //       window.location.reload()
        //     }
        //   },
        // )
        return {
            css,
            head,
            isReloading,
            isOffline,
            theme,
        };
    },
    head() {
        return createHead(this.head);
    },
});
