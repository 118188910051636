import { defineNuxtPlugin, onGlobalSetup, onMounted } from '@nuxtjs/composition-api';
import { isLightHouse } from '~/utils';
import { useStack } from '~/composables';
import { IS_DEVELOPMENT_MODE, IS_PRODUCTION_MODE } from '~/constants';
/**
 * Debug mode
 * In network: https://mc.yandex.ru/watch
 */
const IS_DEBUG_MODE = false;
/**
 * Plugin name
 */
const PLUGIN_NAME = 'yandexMetrika';
/**
 * Yandex Metrika id
 */
const YANDEX_METRIKA_ID = '433635';
/**
 * Yandex Metrika options
 * @see {@link https://yandex.ru/support/metrica/code/counter-initialize.html}
 */
const YANDEX_METRIKA_OPTIONS = {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
};
export default defineNuxtPlugin(({ app, env, nuxtState, route }, inject) => {
    let isReady = false;
    const stack = useStack();
    const pageHit = (options = {}) => {
        const callback = () => {
            const shouldHit = !(options.excluded || []).includes(PLUGIN_NAME);
            if (!shouldHit) {
                return;
            }
            let { referer = '', title = '', url = '' } = options || {};
            referer = referer || document.referrer;
            title = title || document.title;
            url = url || document.URL;
            window.ym(YANDEX_METRIKA_ID, 'hit', url, {
                referer,
                title,
            });
            if (IS_DEBUG_MODE && IS_DEVELOPMENT_MODE) {
                console.info(`[Send ${PLUGIN_NAME}]`, { referer, title, url });
            }
        };
        if (!isReady) {
            return stack.push(callback);
        }
        callback();
    };
    const reachGoal = (goal) => {
        if (!window.ym)
            return;
        window.ym(YANDEX_METRIKA_ID, 'reachGoal', goal);
        if (IS_DEBUG_MODE && IS_DEVELOPMENT_MODE) {
            console.info(`[Send ${PLUGIN_NAME} reachGoal]`, goal);
        }
    };
    inject(PLUGIN_NAME, {
        pageHit,
        reachGoal,
    });
    onGlobalSetup(() => {
        onMounted(() => {
            app[`$${PLUGIN_NAME}`].pageHit({
                title: document.title,
                url: env.HOST + route.fullPath,
            });
        });
    });
    app?.router?.onReady(() => {
        app?.router?.afterEach((to, from) => {
            app[`$${PLUGIN_NAME}`].pageHit({
                title: (app.$page || nuxtState.data[0].$page || document).title,
                referer: from.fullPath,
                url: to.fullPath,
            });
        });
    });
    if (IS_DEBUG_MODE || IS_PRODUCTION_MODE) {
        return window.addEventListener('load', async () => {
            if (isLightHouse(navigator.userAgent)) {
                return;
            }
            try {
                await initMetrika();
                try {
                    await initExperiments();
                }
                catch { }
                isReady = true;
                stack.execute();
            }
            catch {
                stack.close();
            }
        });
    }
    console.info(`%c${PLUGIN_NAME} is disabled in development mode. Switch to debug mode to enable.`, 'color: DarkGoldenRod;');
});
/**
 * Initialize vendor script
 */
function initMetrika() {
    return new Promise((resolve, reject) => {
        /* eslint-disable */
        (function (m, e, t, r, i, k, a) {
            // @ts-ignore
            m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); };
            // @ts-ignore
            m[i].l = 1 * new Date();
            for (var j = 0; j < document.scripts.length; j++) {
                if (document.scripts[j].src === r) {
                    return;
                }
            }
            // @ts-ignore
            k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, k.onload = resolve, k.onerror = reject, a.parentNode.insertBefore(k, a);
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
        window.ym(YANDEX_METRIKA_ID, 'init', YANDEX_METRIKA_OPTIONS);
        /* eslint-enable */
    });
}
/**
 * Initialize vendor script
 * https://yandex.ru/support/varioqub/connect.html
 */
function initExperiments() {
    return new Promise((resolve, reject) => {
        /* eslint-disable */
        (function (e, x, pe, r, i, me, nt) {
            // @ts-ignore
            e[i] = e[i] || function () { (e[i].a = e[i].a || []).push(arguments); },
                // @ts-ignore
                me = x.createElement(pe), me.async = 1, me.defer = 1, me.src = r, me.onload = resolve, me.onerror = reject, nt = x.getElementsByTagName(pe)[0], nt.parentNode.insertBefore(me, nt);
        })(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');
        window.ymab(`metrika.${YANDEX_METRIKA_ID}`, 'init' /*, {clientFeatures}, {callback}*/);
        // https://yandex.ru/support/varioqub/create.html#create__edit
        window.ymab(`metrika.${YANDEX_METRIKA_ID}`, 'setConfig', { enableVisual: true, enableJS: true });
        /* eslint-enable */
    });
}
