import { ref } from '@nuxtjs/composition-api';
import { pluralize } from '~/utils';
/**
 * Error messages
 */
export const ERROR_MESSAGES = {
    cardExpiryDate: 'Некорректная дата',
    email: 'Неверный формат почты',
    maxLength: (value) => `Максимальная длина ${pluralize(value, 'знак', 'знака', 'знаков', true)}`,
    minLength: (value) => `Минимальная длина ${pluralize(value, 'знак', 'знака', 'знаков', true)}`,
    phoneLength: (value) => `Номер должен состоять из ${pluralize(value, 'цифры', 'цифр', 'цифр', true)} (без кода страны)`,
    match: 'Значение должно совпадать',
    required: 'Обязательно для заполнения',
};
/**
 * Validators
 */
export const VALIDATORS = {
    cardExpiryDate({ value: date }) {
        const month = Number(date.substring(0, 2));
        const year = Number(date.substring(7, 5));
        const currentYear = Number(String(new Date().getFullYear()).substring(4, 2));
        return month <= 12 && year >= currentYear;
    },
    email({ value }) {
        const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return REGEX_EMAIL.test(String(value.trim()).toLowerCase());
    },
    match(ctx) {
        const matchField = ctx.fields?.find((f) => f.name === ctx.validator);
        if (!matchField) {
            return true;
        }
        return String(matchField.value).trim() === String(ctx.value).trim();
    },
    maxLength({ validator: maxLength, value }) {
        return String(value.trim()).length <= maxLength;
    },
    minLength({ validator: minLength, value }) {
        return String(value.trim()).length >= minLength;
    },
    phoneLength({ validator: length, value }) {
        const fieldLength = String(value.trim().replace(/\D/g, '')).length - 1;
        return fieldLength === length;
    },
    required({ value }) {
        return ['false', 'true'].includes(value) ? value === 'true' : Boolean(value.trim());
    },
};
/**
 * Form composable
 * @param formFields
 */
export function useForm(formFields) {
    const fields = ref(JSON.parse(JSON.stringify(formFields)));
    return {
        get hasErrors() {
            return Boolean(fields.value.find((field) => field.error));
        },
        get fields() {
            return fields.value;
        },
        set fields(value) {
            fields.value = value;
        },
        clearErrors(event) {
            if (!fields.value.length) {
                return;
            }
            const { name } = event.target;
            for (const field of fields.value) {
                if (field.name === name) {
                    field.error = '';
                }
            }
        },
        validate() {
            if (!fields.value.length) {
                return;
            }
            for (let i = 0, length = fields.value.length; i < length; i++) {
                const { checked, validators, value } = fields.value[i];
                if (!validators?.length) {
                    continue;
                }
                const fieldValue = String(typeof checked === 'boolean' ? checked : value);
                for (const validator of validators) {
                    const isPassed = VALIDATORS[validator.name]({
                        field: fields.value[i],
                        fields: fields.value,
                        validator: validator.value,
                        value: fieldValue,
                    });
                    if (!isPassed) {
                        fields.value[i].error = (validator?.message ? validator.message : '');
                        break;
                    }
                }
            }
        },
    };
}
export const BASE_FORM_FIELDS = {
    /**
     * Base name field
     */
    name: {
        error: '',
        label: 'Ваше имя',
        name: 'name',
        type: 'text',
        value: '',
        validators: [
            {
                name: 'required',
                message: ERROR_MESSAGES.required,
            },
            {
                name: 'minLength',
                value: 2,
                message: ERROR_MESSAGES.minLength(2),
            },
            {
                name: 'maxLength',
                value: 255,
                message: ERROR_MESSAGES.maxLength(255),
            },
        ],
    },
    /**
     * Base email field
     */
    email: {
        error: '',
        inputmode: 'email',
        label: 'Email',
        name: 'email',
        type: 'text',
        value: '',
        validators: [
            {
                name: 'required',
                message: ERROR_MESSAGES.required,
            },
            {
                name: 'email',
                message: ERROR_MESSAGES.email,
            },
            {
                name: 'maxLength',
                value: 255,
                message: ERROR_MESSAGES.maxLength(255),
            },
        ],
    },
    /**
     * Base phone field
     */
    phone: {
        error: '',
        inputmode: 'tel',
        label: 'Номер телефона',
        name: 'phone',
        type: 'text',
        mask: '+7 (###) ### ## ##',
        value: '',
        validators: [
            {
                name: 'required',
                message: ERROR_MESSAGES.required,
            },
            {
                name: 'phoneLength',
                value: 10,
                message: ERROR_MESSAGES.phoneLength(10),
            },
        ],
    },
};
