export const TRIGGER_INTERVAL_TIME = 8e3;
export const CUSTOM_ITEMS = [
// {
//   heading: {
//     title: '20 Идей',
//     to: '/brandvoice/twenty-ideas',
//   },
//
//   title: `Успех и процветание: 20 идей по реализации лучших мировых практик в России`,
//   url_alias: 'brandvoice/twenty-ideas',
// },
];
export const EXCLUDED_BRANDVOICES = ['twenty-ideas'];
export const MAX_ITEMS_COUNT = 5;
