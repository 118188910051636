// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".biL6h{position:relative;width:100%;height:100%}.AQqII{position:absolute;top:50%;left:50%;width:16px;height:16px;transform:translate3d(-50%,-50%,0)}.AQqII:after,.AQqII:before{position:absolute;width:inherit;height:1px;background-color:#fff;content:\"\";transition:background-color .2s,margin .2s .3s,transform .2s}.AQqII:before{top:4px}.AQqII:after{bottom:4px}.dQzSc .AQqII:after,.dQzSc .AQqII:before{transition:background-color .2s,margin .2s,transform .2s .3s}.dQzSc .AQqII:before{margin-top:3px;transform:rotate(45deg)}.dQzSc .AQqII:after{margin-bottom:4px;transform:rotate(-45deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"burger": "biL6h",
	"icon": "AQqII",
	"isToggled": "dQzSc"
};
module.exports = ___CSS_LOADER_EXPORT___;
