import { defineNuxtPlugin } from '@nuxtjs/composition-api';
import { isLightHouse } from '~/utils';
import { useStack } from '~/composables';
import { IS_DEVELOPMENT_MODE, IS_PRODUCTION_MODE } from '~/constants';
/**
 * Debug mode
 * In network: https://counter.yadro.ru/hit
 */
const IS_DEBUG_MODE = false;
/**
 * Plugin name
 */
const PLUGIN_NAME = 'liveInternet';
export default defineNuxtPlugin(({ app, env, nuxtState }, inject) => {
    let isReady = false;
    const stack = useStack();
    inject(PLUGIN_NAME, (options = {}) => {
        const callback = () => {
            const shouldHit = !(options.excluded || []).includes(PLUGIN_NAME);
            if (!shouldHit) {
                return;
            }
            new Image().src = createPixelSrc(options);
        };
        if (!isReady) {
            return stack.push(callback);
        }
        callback();
    });
    app?.router?.onReady(() => {
        app?.router?.afterEach((to, from) => {
            app[`$${PLUGIN_NAME}`]({
                referer: env.HOST + from.fullPath,
                title: (app.$page || nuxtState.data[0].$page || document).title,
                url: env.HOST + to.fullPath,
            });
        });
    });
    if (IS_DEBUG_MODE || IS_PRODUCTION_MODE) {
        return window.addEventListener('load', async () => {
            if (isLightHouse(navigator.userAgent)) {
                return;
            }
            try {
                await init();
                isReady = true;
                stack.execute();
            }
            catch {
                stack.close();
            }
        });
    }
    console.info(`%c${PLUGIN_NAME} is disabled in development mode. Switch to debug mode to enable.`, 'color: DarkGoldenRod;');
});
/**
 * Initialize pixel
 */
function init() {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = createPixelSrc();
        image.onerror = reject;
        image.onload = resolve;
    });
}
/**
 * Create pixel source
 * @param options
 */
function createPixelSrc(options) {
    let { referer = '', title = '', url = '' } = options || {};
    referer = referer || document.referrer;
    title = title || document.title;
    url = url || document.URL;
    if (IS_DEBUG_MODE && IS_DEVELOPMENT_MODE) {
        console.info(`[Send ${PLUGIN_NAME}]`, { referer, title, url });
    }
    return ('https://counter.yadro.ru/hit?r' +
        escape(referer) +
        (typeof screen === 'undefined'
            ? ''
            : ';s' + screen.width + '*' + screen.height + '*' + (screen.colorDepth ? screen.colorDepth : screen.pixelDepth)) +
        ';u' +
        escape(url) +
        ';h' +
        escape(title.substring(0, 150)) +
        ';' +
        Math.random());
}
