export function createHead({ canonical, description, image, imageVK, ogDescription, ogTitle, title, url }, { shouldUseTitleTemplate = true } = {}) {
    const currentDescription = description ||
        `${title ? title + ' – ' : ''}Forbes Russia – одно из наиболее авторитетных и известных финансово-экономических изданий в мире`;
    const currentOgTitle = ogTitle || title;
    const currentOgDescription = ogDescription || currentDescription;
    const head = {};
    head.link = [];
    head.meta = [];
    if (canonical) {
        head.link.push({ hid: 'canonical', rel: 'canonical', href: canonical });
    }
    if (currentDescription) {
        head.meta.push({ hid: 'description', name: 'description', content: currentDescription });
    }
    if (currentOgDescription) {
        head.meta.push({ hid: 'og:description', property: 'og:description', content: currentOgDescription });
    }
    if (currentOgTitle) {
        head.meta.push({ hid: 'og:title', property: 'og:title', content: currentOgTitle });
    }
    if (image) {
        head.meta.push({ hid: 'vk:image', property: 'vk:image', content: imageVK || image });
        head.meta.push({ hid: 'og:image', property: 'og:image', content: image });
    }
    if (title) {
        title = shouldUseTitleTemplate ? title + ' | Forbes.ru' : title;
        head.title = title;
    }
    if (url) {
        head.meta.push({ hid: 'og:url', property: 'og:url', content: url });
    }
    return head;
}
