import VueRouter from 'vue-router';
const CACHED_URLS = {
    'webcache.googleusercontent.com': '/search',
    'yandexwebcache.net': '/yandbtm',
};
function isCachedURL(fullUrl = '') {
    const url = new URL(fullUrl);
    const cached = CACHED_URLS[url.hostname];
    if (!cached) {
        return false;
    }
    return cached === url.pathname;
}
export function createRouter(ssrContext, createDefaultRouter, routerOptions, config) {
    const defaultRouter = createDefaultRouter(ssrContext, config);
    const options = routerOptions || defaultRouter.options;
    const isCache = process.client && isCachedURL(window.location.href);
    const currentOptions = {
        ...options,
        mode: isCache ? 'abstract' : options.mode,
    };
    const router = new VueRouter(currentOptions);
    router.push = defaultRouter.push;
    router.resolve = defaultRouter.resolve;
    if (!isCache) {
        return router;
    }
    const originalRouterResolve = router.resolve.bind(router);
    let fullPath = '/';
    if (window.__NUXT__) {
        fullPath = window.__NUXT__?.state?.route;
    }
    router.resolve = () => {
        router.resolve = originalRouterResolve;
        return originalRouterResolve(fullPath);
    };
    return router;
}
