import { onBeforeUnmount, computed, defineComponent, onMounted, reactive, ref, useContext, useCssModule, watch, } from '@nuxtjs/composition-api';
import { useAudio } from '~/composables';
import { EDUCATIONS_PODCASTS } from '~/constants';
import { EVENT_SEEK_STEPS } from '~/components/PodcastPlayer/index';
export default defineComponent({
    name: 'PodcastPlayer',
    props: {
        eventDetail: {
            default: () => ({}),
            type: Object,
            required: true,
        },
        volume: {
            default: () => ({}),
            type: Object,
        },
        audioVolume: {
            default: 75,
            type: Number,
        },
    },
    emits: ['close', 'toggle-volume', 'volume-input', 'focus-player'],
    setup(props, { emit }) {
        /**
         * CSS Composables.
         */
        const context = useContext();
        const css = useCssModule();
        /**
         * Context variables.
         */
        const env = context.env;
        /**
         * Audio.
         */
        const root = ref();
        const updateTicking = ref();
        const currentVolume = ref(props.volume);
        const audioParam = computed(() => props.eventDetail);
        const audio = computed(() => useAudio(audioParam.value.file.uri || ''));
        const seek = reactive({
            beforeWidth: '0%',
            ticking: 0,
        });
        audio.value.volume = currentVolume.value.current;
        watch(() => props.audioVolume, (newValue) => {
            audio.value.volume = newValue;
        });
        watch(() => props.eventDetail, () => {
            emit('focus-player');
        });
        const isEducation = computed(() => {
            return EDUCATIONS_PODCASTS.includes(audioParam.value?.serial?.url?.replace('podcasts/', '') || '');
        });
        const shareUrl = computed(() => {
            const path = audioParam.value.url || audioParam.value.path;
            return env.HOST + path;
        });
        audio.value.on('update', () => {
            window.cancelAnimationFrame(updateTicking.value);
            updateTicking.value = window.requestAnimationFrame(() => {
                seek.beforeWidth = `${(audio.value.currentTime / audio.value.duration) * 100}%`;
            });
        });
        function close() {
            if (audio.value.isPlaying) {
                audio.value.playPause();
            }
            emit('close');
        }
        function handleSeekInput(inputData) {
            window.cancelAnimationFrame(seek.ticking);
            seek.ticking = window.requestAnimationFrame(() => {
                const value = typeof inputData === 'number' ? inputData : inputData?.target?.value;
                audio.value.currentTime = parseInt(value, 10);
                seek.beforeWidth = `${(audio.value.currentTime / audio.value.duration) * 100}%`;
            });
        }
        function handleVolumeInput(event) {
            emit('volume-input', event);
        }
        function toggleVolume() {
            emit('toggle-volume');
        }
        function playPause() {
            audio.value.playPause();
        }
        function handleEventSeek(event) {
            const seekStep = EVENT_SEEK_STEPS[event.type] || 0;
            const seek = audio.value.currentTime + seekStep;
            handleSeekInput(seek);
        }
        const podcastEvents = new Map([
            ['playerEnter', playPause],
            ['playerEsc', close],
            ['playerLeft', handleEventSeek],
            ['playerRight', handleEventSeek],
        ]);
        onMounted(() => {
            podcastEvents.forEach((fn, event) => {
                window.addEventListener(event, fn);
            });
        });
        onBeforeUnmount(() => {
            podcastEvents.forEach((fn, event) => {
                window.removeEventListener(event, fn);
            });
        });
        return {
            audio,
            audioParam,
            close,
            css,
            currentVolume,
            handleSeekInput,
            handleVolumeInput,
            playPause,
            isEducation,
            root,
            seek,
            shareUrl,
            toggleVolume,
            updateTicking,
        };
    },
});
