import { render, staticRenderFns } from "./ThePlayer.vue?vue&type=template&id=de203726&"
import script from "./ThePlayer.vue?vue&type=script&lang=ts&"
export * from "./ThePlayer.vue?vue&type=script&lang=ts&"
import style0 from "./ThePlayer.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PodcastPlayerMobile: require('/var/www/src/components/PodcastPlayerMobile/PodcastPlayerMobile.vue').default,PodcastPlayer: require('/var/www/src/components/PodcastPlayer/PodcastPlayer.vue').default,SpeechPlayer: require('/var/www/src/components/SpeechPlayer/SpeechPlayer.vue').default})
